import React from 'react';
import {FormattedMessage} from "react-intl";
import {axiosInstance} from "../Axios/Axios";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        axiosInstance.post("/ui-error", {
            error: {
                message:error.message,
                name: error.name,
                string: error.toString()
            },
            stackTrace: errorInfo.componentStack
        });
    }

    render() {
        if (! (this.state.hasError)) {
            return this.props.children;
        }

        return (
            <div className={"container"}>
                <div className="columns mt-2">
                    <div className="column">
                        <div className="notification is-warning">
                            <FormattedMessage id={"error.ui_rendering"} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorBoundary;
