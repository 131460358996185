import React from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';

function ModalConfirmation(props) {

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }/>
                </header>

                <section className="modal-card-body">
                    <div>
                        {props.message}
                    </div>
                </section>

                <footer className="modal-card-foot">
                    {props.optionButtons.map( (buttonElement, index) => (
                        <button key={index} className={`button ${buttonElement.buttonClass}`} onClick={ buttonElement.buttonMethod }>{buttonElement.label}</button>
                    ))}
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalConfirmation);
