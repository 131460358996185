// ** React Imports
import React from "react";

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Icon Imports
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import * as userTypes from "../../store/reducers/auth/userTypes";

// ** Actions Import
import * as actionTypes from "../../store/Actions";

// ** Components Imports
import CustomerRatesTable from "./CustomerRatesTable";

const CustomerRatesEnabledTable = ({ rates, customer_id }) => {
    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const userType = useSelector(state => state.auth.user ? state.auth.user.type : "invitado");

    const onClick = event => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: actionTypes.SHOW_MODAL,
            payload: {
                modalType: "createRate",
                title: intl.formatMessage({ id: "customer.createRate" }),
                extraData: { customer_id: customer_id }
            }
        })
    };

    return (
        <div className="table-container">
            <div className="button-container">
                {userType === userTypes.IDISC_ADMIN && (
                    <div className="is-flex-desktop is-justify-content-space-between">
                        <button className="button is-outlined is-primary" id="downloadExcelFile" onClick={onClick}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <span className="ml-3">
                                {intl.formatMessage({ id: "rate.create" })}
                            </span>
                        </button>
                    </div>
                )}
            </div>
            <CustomerRatesTable rates={rates} customer_id={customer_id}/>
        </div>
    );
}

export default CustomerRatesEnabledTable;
