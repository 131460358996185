import { axiosInstance } from "../../../hoc/Axios/Axios";
import * as actions from "../../Actions";

export const getBonuses = data => dispatch => axiosInstance
    .get("bonuses", { params : data })
    .then(response => {
        dispatch({ type: actions.GET_BONUSES, payload: response.data.data });
    })
    .catch(() => null)

export const createBonus = data => () => axiosInstance
    .post("bonuses", data)
    .then(() => null)
    .catch(() => null)
