import * as actionTypes from "../../Actions";

const initialState = {
    loading: false,
    register: false,
    error: null,
    errorStatus: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case actionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                register: action.payload
            }
        case actionTypes.REGISTER_ERROR:
            return {
                ...state,
                error: action.payload?.data ?? null,
                errorStatus: action.payload?.status ?? null
            }
        default:
            return state;
    }
}

export default reducer;
