import { axiosInstance } from "../../../hoc/Axios/Axios";
import * as actions from "../../Actions";

export const createOtherConcept = data => dispatch => axiosInstance
    .post("other-concepts", data)
    .then(() => {
        dispatch({ type: actions.CREATE_OTHER_CONCEPT, payload: true });
    })
    .catch(() => null)

export const updateOtherConcept = (id, data) => dispatch => axiosInstance
    .put(`other-concepts/${id}`, data)
    .then(() => {
        dispatch({ type: actions.UPDATE_OTHER_CONCEPT, payload: true });
    })
    .catch(() => null)

export const deleteOtherConcept = id => dispatch => axiosInstance
    .delete(`other-concepts/${id}`)
    .then(() => {
        dispatch({ type: actions.DELETE_OTHER_CONCEPT, payload: true });
    })
    .catch(() => null)
