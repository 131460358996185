// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// ** Store Imports
import { getTasks } from "../../store/reducers/task/taskActions"
import { getCurrentUserDepartments } from "../../store/reducers/auth/authActions";

// ** Custom Components
import Datatable from "../../components/Datatable/Datatable";
import TaskStateTag from "../../components/Task/TaskStateTag";
import Tooltip from "../../components/UI/Tooltip";

// ** Actions Import
import * as actions from "../../store/Actions";

// ** Task States
import * as taskStates from "../../store/reducers/task/taskStates";

import * as userTypes from "../../store/reducers/auth/userTypes";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";
import { useRound } from "../../hooks/useRound";

export default function Task() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const tasks = useSelector(state => state.task.tasks);
    const success = useSelector(state => state.task.success);
    const error = useSelector(state => state.task.error);
    const userType = useSelector(state => state.auth.user?.type);
    const userDepartments = useSelector(state => state.auth.currentUserDepartments);
    const hasBeenDeleted = useSelector(state => state.task.hasBeenDeleted);

    //a reduced object of a customer, only with id and name
    const simpleCustomers = useSelector(state => state.customer_assing.simpleCustomers);

    const [stateFilter, setStateFilter] = useState(null);
    const [clientFilter, setClientFilter] = useState(null);
    const locale = useSelector(state => state.locale.locale);
    const { dateFormat } = useDate();
    const price = usePrice();
    const round = useRound();

    useEffect(() => {
        dispatch(getTasks(false));
        dispatch(getCurrentUserDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: "task.success.delete"}));
            dispatch({type: "TOGGLE_MODAL"});
            dispatch({type: actions.CLEAR_TASK_SUCESS});
            history.push({pathname: "/tasks", exact: true});
        } else if (error) {
            toast.success(intl.formatMessage({id: "task.error.delete"}));
            dispatch({type: actions.CLEAR_TASK_ERROR});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, error]);

    useEffect(() => {
        if (hasBeenDeleted) {
            dispatch({type: actions.HIDE_MODAL})
            toast.success(intl.formatMessage({id: "task.deleted_task"}))
            dispatch({type: actions.RESET_DELETED_TASK_STATUS})
            history.push("/tasks");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenDeleted])

    function createTask(e) {
        e.preventDefault();
        history.push({pathname: "/tasks/create", exact: true});
    }

    const editTask = (taskID) => {
        history.push({pathname: "/tasks/" + taskID, exact: true});
    }

    const searchTasks = () => {
        let data = [];
        data["state"] = stateFilter;
        data["client"] = clientFilter;
        dispatch(getTasks(false, data));
    }

    const calculateTotalOtherConcepts = task => {
        let total = 0;
        task.other_concepts.forEach(concept => total += round(concept.quantity * concept.price, 2));
        return total;
    };

    const BalanceState = ({task}) => {
        const total = round((task.taxed_price ?? 0) + (task.other_prices ?? 0) + (task.other_prices_taxes ?? 0), 2);
        const payments = round(task.pay_task_histories.reduce((previous, current) => previous + current.amount, 0), 2);
        if (round(total - (payments > 0 ? payments : total), 2) > 0) return (
            <Tooltip
                id="tooltip.outstanding_balance"
                element={<span className="tag is-danger ml-1">€</span>}
                title={intl.formatMessage({id: "task.outstanding_balance"})}
            />
        );
        else return null;
    }

    let TaskTable = () => {
        let column5 = {
            sortable: true
        }
        if (userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) {
            column5 = {
                ...column5,
                name: intl.formatMessage({id: "general.customer"}),
                selector: row => row.user?.customer?.name,
                sortable: true
            }
        } else {
            column5 = {
                ...column5,
                name: intl.formatMessage({id: "task.department"}),
                selector: row => row.department?.title,
                sortable: true
            }
        }
        const columns = [
            {
                name: intl.formatMessage({id: "task.id"}),
                selector: 'id',
                sortable: true,
                maxWidth: "100px",
                minWidth: "100px"
            },
            {
                name: intl.formatMessage({id: "task.name"}),
                selector: 'description',
                sortable: true,
                grow: 2
            },
            {
                name: intl.formatMessage({id: "general.creation_date"}),
                selector: row => dateFormat(row.created_at, locale.name),
                sortable: true,
                grow: 2
            },
            {
                name: intl.formatMessage({id: "task.state"}),
                sortable: true,
                cell: row => (
                    <>
                        <TaskStateTag task={row}/>
                        {row.current_task_state?.state === taskStates.DONE ? (
                            <>
                                {row.pay_task_histories?.length > 0 && (
                                    <BalanceState task={row}/>
                                )}
                            </>
                        ) : (userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) ? (
                            <>
                                {!!row.custom_quote && !row.source_files_validated && (
                                    <Tooltip
                                        id="tooltip.source_files_validation_requested"
                                        element={<span className="tag is-danger ml-1">?</span>}
                                        title={intl.formatMessage({id: "task.source_files_validation_requested"})}
                                    />
                                )}
                            </>
                        ) : !!row.changes && (
                            <Tooltip
                                id="tooltip.changes"
                                element={<span className="tag is-danger ml-1">?</span>}
                                title={intl.formatMessage({id: "task.changes"})}
                            />
                        )}
                    </>
                )
            },
            {
                ...column5
            },
            {
                name: intl.formatMessage({id: "task.requested_by"}),
                selector: row => (
                    row?.user != null ? (row?.user?.name + " " + row?.user?.lastname ?? intl.formatMessage({id: "general.guest"})) : intl.formatMessage({id: "general.guest"})
                ),
                sortable: true,
                grow: 1.5
            },
            {
                name: intl.formatMessage({id: "invoice.amount_taxes_not_included"}),
                selector: row => `${price(row.base_price + calculateTotalOtherConcepts(row), locale.name)} €`,
                right: true,
                grow: 1.5
            }
        ];
        return (
            <Datatable
                columns={columns}
                data={tasks}
                onRowClicked={row => editTask(row.id)}/>
        );
    }

    const filter = () => (
        <section className="custom-box">
            <h4 className="title is-5 has-text-primary">
                <FormattedMessage id="general.filter"/>
            </h4>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="task.state"/>
                        </label>
                        <div className="select is-fullwidth">
                            <select
                                name="taskStates"
                                onChange={event => setStateFilter(event.target.value ?? null)}
                                value={stateFilter ?? 0}
                            >
                                <option key="all" value={0}>
                                    {intl.formatMessage({id: "general.all"})}
                                </option>
                                {taskStates.FILTER_TASK_STATES_VALUES.map(item => (
                                    <option key={item} value={item}>
                                        {intl.formatMessage({id: "task.state." + item})}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {userType === userTypes.IDISC_ADMIN && (
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="general.customer"/>
                            </label>
                            <div className="select is-fullwidth">
                                <select
                                    name="taskClient"
                                    onChange={event => setClientFilter(event.target.value ?? null)}
                                    value={clientFilter ?? 0}
                                >
                                    <option key="all" value={0}>
                                        {intl.formatMessage({id: "general.all"})}
                                    </option>
                                    {simpleCustomers.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                )}
                <div className="is-flex column is-align-items-flex-end">
                    <button className="button is-info floatingButton" id="taskFilterButton" onClick={searchTasks}>
                        <FormattedMessage id="general.search"/>
                    </button>
                </div>
            </div>
        </section>
    );

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.tasks"/>
            </h4>
            {!(userDepartments?.length > 0) && (
                <>
                    {userType === userTypes.CLIENT_ADMIN ? (
                        <div className="notification is-warning is-light">
                            <FormattedMessage id="task.user_welcome_tutorial"/>
                            <ol>
                                <li>
                                    <Link className="navbar-item" to="/departments">
                                        <FormattedMessage id="task.user_welcome_list_departments"/>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="navbar-item" to="/departments">
                                        <FormattedMessage id="task.user_welcome_list_users"/>
                                    </Link>
                                </li>
                            </ol>
                        </div>
                    ) : userType === userTypes.CLIENT_USER && (
                        <div className="notification is-warning is-light">
                            <FormattedMessage id="task.user_with_no_departments"/>
                        </div>
                    )}
                </>
            )}
            {filter()}
            {(userType === userTypes.CLIENT_USER || userType === userTypes.CLIENT_ADMIN) && userDepartments?.length > 0 && (
                <>
                    <div className="is-flex is-justify-content-flex-end">
                        <button className="button is-outlined is-primary" onClick={createTask}>
                            <FormattedMessage id="task.add_task"/>
                        </button>
                    </div>
                    <hr/>
                </>
            )}
            <div className="table-container">
                <TaskTable/>
            </div>
        </div>
    );
}
