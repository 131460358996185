// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";

const Glossaries = () => {
    return (
        <div className="box is-desktop">
            <h4 className="title has-text-primary is-4">
                <FormattedMessage id="menu.glossaries"/>
            </h4>
            <p>
                <FormattedMessage id="glossaries.message"/>
            </p>
            <p className="mt-3">
                <FormattedMessage id="glossaries.message_2"/>
            </p>
        </div>
    );
}

export default Glossaries;
