import {FormattedMessage} from "react-intl";
import React from "react";

export default function OnlyReadInput(props) {
    return (
        <div className="field">
            <label className="label"><FormattedMessage id={props.label}/></label>
            <div className="control">
                <p>{props.fieldValue}</p>
            </div>
        </div>
    )
}
