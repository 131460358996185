import React from "react";
import ReactTooltip from "react-tooltip";

export default function Tooltip(props) {
    function getTitle() {
        if (props.title) return (<p><b>{props.title}</b></p>);
    }

    function getDescription() {
        if (props.description) return (<span>{props.description}</span>);
    }

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a data-for={props.id} data-tip>
                {props.element}
            </a>
            <ReactTooltip id={props.id} className="tooltip" effect='solid'>
                {getTitle()}
                {getDescription()}
            </ReactTooltip>
        </>
    );
}
