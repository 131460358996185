// ** React Imports
import React, { useEffect } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { getCustomer } from "../../store/reducers/customers/customersActions";
import * as userTypes from "../../store/reducers/auth/userTypes"

// ** Custom Components
import OnlyReadInput from "../../components/Form/OnlyReadInput";

// ** Hooks
import { usePrice } from "../../hooks/usePrice";

const UserCompany = () => {
    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const user = useSelector(state => state.auth.user);
    const customer = useSelector(state => state.customer_assing.customer);
    const locale = useSelector(state => state.locale.locale);
    const price = usePrice();

    useEffect(() => {
        if (user.customer_id) dispatch(getCustomer(user.customer_id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="box is-desktop">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.my_company"/>
            </h4>
            <div className="p-2 is-desktop">
                <div className="columns is-desktop">
                    <div className="column">
                        <OnlyReadInput label="general.name" fieldValue={customer?.name}/>
                    </div>
                </div>
                <div className="columns is-desktop">
                    {customer?.address && (
                        <div className="column">
                            <OnlyReadInput label="general.address" fieldValue={customer.address}/>
                        </div>
                    )}
                    {customer?.address_2 && (
                        <div className="column">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="general.address"/> 2
                                </label>
                                <div className="control">
                                    <p>
                                        {customer.address_2}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="columns is-desktop">
                    {customer?.town && (
                        <div className="column">
                            <OnlyReadInput label="general.town" fieldValue={customer.town}/>
                        </div>
                    )}
                    {customer?.province && (
                        <div className="column">
                            <OnlyReadInput label="general.province" fieldValue={customer.province}/>
                        </div>
                    )}
                </div>
                <div className="columns is-desktop">
                    <div className="column">
                        {customer?.country?.code && (
                            <OnlyReadInput
                                label="general.country"
                                fieldValue={intl.formatMessage({id: `country.${customer.country.code}`})}
                            />
                        )}
                    </div>
                    {customer?.postal_code && (
                        <div className="column">
                            <OnlyReadInput label="general.postal_code" fieldValue={customer.postal_code}/>
                        </div>
                    )}
                </div>
                <div className="columns is-desktop">
                    {customer?.tin && (
                        <div className="column">
                            <OnlyReadInput label="general.tin" fieldValue={customer.tin}/>
                        </div>
                    )}
                </div>
                {(customer?.success_representative || customer?.success_representative_email) && (
                    <div className="columns">
                        <div className="column">
                            <OnlyReadInput
                                label="customer.my_success_representative"
                                fieldValue={<>
                                    {customer.success_representative}
                                    {customer.success_representative && customer.success_representative_email && (
                                        <br/>
                                    )}
                                    {customer.success_representative_email && (
                                        <a href={`mailto:${customer.success_representative_email}`}>
                                            {customer.success_representative_email}
                                        </a>
                                    )}
                                </>}
                            />
                        </div>
                    </div>
                )}
                {user.type === userTypes.CLIENT_ADMIN && (
                    <div className="columns is-desktop">
                        <div className="column">
                            <OnlyReadInput
                                label="customer.available_balance"
                                fieldValue={`${price(customer?.balance ?? 0, locale.name)} €`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserCompany;
