// ** React Imports
import React, { useState } from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

// ** Store Imports
import { uploadExcel } from "../../store/reducers/customers/customersActions";

const ImportRates = () => {
    // ** State
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    // ** Hooks
    const dispatch = useDispatch();

    const onSubmit = event => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        dispatch(uploadExcel(0, formData));
    }

    return (
        <div className="is-desktop">
            <form onSubmit={onSubmit}>
                <div className="file">
                    <label className="file-label">
                        <input
                            accept=".xlsx"
                            className="file-input"
                            name="excel"
                            onChange={event => setFile(event.target.files[0] ?? null)}
                            type="file"
                        />
                        <span className="file-cta">
                            <FormattedMessage id="customer.choose_a_file"/>
                        </span>
                        {file && (
                            <span className="file-name">
                                {file.name}
                            </span>
                        )}
                    </label>
                </div>
                <button
                    className={`button is-info floatingButton${loading ? " is-loading" : ""}`}
                    disabled={loading}
                    type="submit"
                >
                    <FormattedMessage id="general.upload"/>
                </button>
            </form>
        </div>
    );
};

export default ImportRates;
