import React from 'react';
import { useDispatch } from 'react-redux'
import ModalHOC from '../../hoc/Modal/ModalHOC';
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import * as actionType from '../../store/Actions';

function ModalConnectionError(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const logoutHandler = () => {
        history.push("/login");
        dispatch({type: actionType.LOGOUT});
    }

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={logoutHandler}/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{intl.formatMessage({id: "error.session_expired_title"})}</p>
                    <button className="delete" aria-label="close" onClick={ logoutHandler }/>
                </header>

                <section className="modal-card-body">
                    <div>
                        {intl.formatMessage({id: "error.session_expired"})}
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className="button is-info" onClick={logoutHandler}>{intl.formatMessage({id: "login"})}</button>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalConnectionError);
