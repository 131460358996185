import React, {useEffect, useState} from 'react';
import {useHistory,Link} from "react-router-dom";
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector, connect} from 'react-redux'
import * as actionTypes from '../../store/Actions';
import {login} from '../../store/reducers/auth/authActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {faKey} from '@fortawesome/free-solid-svg-icons'
import {toast} from "react-toastify";

let Login = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
    const badCredentials = useSelector(state => state.auth.badCredentials);
    const error = useSelector(state => state.auth.error);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (isLoggedIn) {
            dispatch({type: actionTypes.HIDE_MODAL});
            toast.success(intl.formatMessage({id: "message.welcome"}));
            history.push("/");
        } else if (error !== null) {
            dispatch({type: actionTypes.HIDE_MODAL});
            switch (error) {
                case "Unauthorized":
                    showAlert();
                    dispatch({type: "RESET_AUTH_ERROR"})
                    break;
                default:
            }
        } else {
            dispatch({type: actionTypes.HIDE_MODAL});
        }
    });

    useEffect(() => {
        if(badCredentials){
            dispatch({type: actionTypes.HIDE_MODAL});
            toast.error(intl.formatMessage({id: "error.bad_credentials"}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badCredentials])

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch({type: actionTypes.RESET_BAD_CREDENTIALS});
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: "loading",
            }
        })
        const user = {
            email: email,
            password: password
        }
        dispatch(login(user));
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changePassword = (e) => {
        setPassword(e.target.value);
    }

    const disableLoginButton = () => {
        if (email.trim() === "" || password.trim() === "") {
            return true;
        } else {
            return false;
        }
    }

    const showAlert = () => {
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "error.wrong_credentials_title"}),
                modalType: "alert",
                message: intl.formatMessage({id: "error.wrong_credentials"}),
                acceptButton: intl.formatMessage({id: "general.accept"}),
                acceptButtonClass: "is-danger"
            }
        })
    }

    return (
        <section className="hero is-fullheight" style={{backgroundImage: `url("${require("../../assets/idisc-xpress.jpg")}` }}>
            <div className="hero-body">

                <div className="container" style={{opacity: 1.2}}>
                    <div className="column is-4 is-offset-4">
                        <div className="box">
                            <h3 className="title has-text-primary has-text-centered">
                                <FormattedMessage id="login"/>
                            </h3>
                            {/* <hr></hr> */}
                            <form onSubmit={(e) => {
                                onSubmit(e)
                            }}>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input className="input" type="email" id="email"
                                               placeholder={intl.formatMessage({id: "placeholder.email"})}
                                               value={email}
                                               onChange={(e) => {
                                                   changeEmail(e)
                                               }}
                                               autoFocus=""/>
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                             icon={faUser} color="primary"/>
                                        </span>
                                    </div>
                                </div>

                                <div className="field mt-4">
                                    <div className="control has-icons-left">
                                        <input className="input" type="password" id="password"
                                               placeholder={intl.formatMessage({id: "placeholder.password"})}
                                               value={password}
                                               onChange={(e) => {
                                                   changePassword(e)
                                               }}/>
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                             icon={faKey} color="primary"/>
                                        </span>
                                    </div>
                                    {badCredentials && <p className="help is-danger"><FormattedMessage id="error.bad_credentials"/></p>}
                                </div>

                                <button id="login_submit" type="submit" className="button is-block is-fullwidth is-link"
                                        disabled={disableLoginButton()}>
                                    <FormattedMessage id="login"/>
                                    <i className="fa fa-sign-in" aria-hidden="true"/>
                                </button>
                                <hr/>
                                <div className="columns">
                                    <div className="column has-text-centered">
                                        <Link className="has-text-primary" to="/login/reset">
                                            <FormattedMessage id="user.forgot_password"/>
                                        </Link>
                                    </div>
                                </div>
                                {/*<div className="columns">
                                    <div className="column has-text-centered">
                                        <a href={process.env.REACT_APP_GUEST_URL}>
                                            <FormattedMessage id="user.continue_as_guest"/>
                                        </a>
                                    </div>
                                </div>*/}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (token) => dispatch({type: actionTypes.LOGIN, token: token})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
