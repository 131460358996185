import * as actionTypes from '../../Actions';

const initialState = {
    isAuthenticated: false,
    token: null,
    expiresIn: null,
    user: null,
    error: null,
    badCredentials: false,
    currentUserDepartments: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BAD_CREDENTIALS:
            return {
                ...state,
                badCredentials: true
            }
        case actionTypes.RESET_BAD_CREDENTIALS:
            return {
                ...state,
                badCredentials: false
            }
        case actionTypes.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.access_token,
                expiresIn: action.payload.expires_in,
                user: action.payload.user,
            };
        case actionTypes.LOGIN_ERROR:
            return {
                ...state,
                error: action.payload.data.error,
            };
        case actionTypes.RESET_AUTH_ERROR:
            return {
                ...state,
                error: null,
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                expiresIn: null,
                user: null,
            };
        case actionTypes.SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        case actionTypes.SET_USER_DEPARTMENTS:
            return {
                ...state,
                user: {
                    ...state.user,
                    departments: action.payload.data.departments
                }
            }
        case actionTypes.SET_CURRENT_USER_DEPARTMENTS:
            return {
                ...state,
                currentUserDepartments: action.payload
            }
        case actionTypes.SET_USER_LOCALE:
            if (state.user !== null) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        language: action.payload
                    }
                }
            }
            break;
        default:
            return state;
    }
};

export default reducer;
