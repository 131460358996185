// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

// ** Store Imports
import { getCountries } from "../../store/reducers/customers/customersActions";
import { getRatesSourceLanguages, getRatesTargetLanguages } from "../../store/reducers/rate/rateActions";
import { store } from "../../store/reducers/quote/quoteActions";

// ** Actions Import
import * as actions from "../../store/Actions";

// ** File Types
import { FILE_TYPES } from "../../utils/enums/FileTypes";

const Budget = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <BudgetPage/>
        </GoogleReCaptchaProvider>
    );
};

const BudgetPage = () => {
    // ** States
    const [company, setCompany] = useState("");
    const [country, setCountry] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [source, setSource] = useState("");
    const [targets, setTargets] = useState([]);
    const [files, setFiles] = useState([]);
    const [comments, setComments] = useState("");
    const [privacy, setPrivacy] = useState(false);

    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const countries = useSelector(state => state.customer_assing.countries);
    const sourceLanguages = useSelector(state => state.rate.sourceLanguages);
    const targetLanguages = useSelector(state => state.rate.targetLanguages);
    const locale = useSelector(state => state.locale.locale);
    const loading = useSelector(state => state.quote.loading);
    const register = useSelector(state => state.quote.register);
    const error = useSelector(state => state.quote.error);
    const errorStatus = useSelector(state => state.quote.errorStatus);
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const privacyPolicy = {
        en: "https://www.idisc.com/en/privacy-policy",
        es: "https://www.idisc.com/es/politica-privacidad",
        ca: "https://www.idisc.com/ca/politica-privacitat"
    };

    useEffect(() => {
        dispatch({ type: actions.REGISTER_ERROR, payload: null });
        dispatch(getCountries());
        dispatch(getRatesSourceLanguages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (register) {
            dispatch({ type: actions.REGISTER_SUCCESS, payload: false });
            history.push("/quote/registered");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [register]);

    const onChange = id => {
        setSource(id);
        setTargets([]);
        dispatch({ type: actions.GET_RATES_TARGET_LANGUAGES, payload: [] });
        dispatch({ type: actions.RESET_CAN_USER_REVIEW_ORIGINAL });
        dispatch(getRatesTargetLanguages(id));
    }

    const onSubmit = async event => {
        event.preventDefault();
        dispatch({ type: actions.REGISTER_ERROR, payload: null });

        const form = new FormData();
        form.append("company_name", company);
        form.append("country", country);
        form.append("first_name", name);
        form.append("last_name", lastName);
        form.append("email", email);
        form.append("phone_number", phone);
        form.append("source_language", sourceLanguages.find(language => language.source_lang === source)?.source_language.iso_code ?? '');
        targets.map(target => form.append("target_languages[]", targetLanguages.find(language => language.target_lang === target.value).target_language.iso_code));
        Array.from(files).map(file => form.append("file[]", file));
        form.append("review_originals", 0);
        form.append("comments", comments);
        form.append("privacy_policy", privacy ? 1 : 0);
        form.append("lang", locale.name);
        form.append("recaptcha_token", await executeRecaptcha('register'));

        dispatch(store(form));
    }

    return (
        <div style={{ paddingBottom: "80px", paddingTop: "160px" }}>
            <section
                className="hero"
                style={{
                    backgroundImage: `url(${require("../../assets/bg-idisc-header-contacto.png")}`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <div className="pt-6 pb-5 px-4">
                                    <h1 className="title is-1 has-text-white has-text-weight-bold mt-6 mb-5 pb-5">
                                        <FormattedMessage id="quote.need_a_quotation" />
                                    </h1>
                                    <h4 className="title is-4 has-text-white mb-5 pb-5">
                                        <FormattedMessage id="quote.page_description" />
                                    </h4>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box p-6" style={{ borderRadius: "22px" }}>
                                    <h3 className="title mb-4 has-text-black has-text-weight-bold">
                                        <FormattedMessage id="quote" />:
                                    </h3>
                                    <hr className="has-background-grey-light m-0 mb-6" style={{ height: "1px" }} />
                                    <form onSubmit={onSubmit}>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.company_name"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input custom-input is-radiusless"
                                                    maxLength={40}
                                                    onChange={event => setCompany(event.target.value)}
                                                />
                                            </div>
                                            {error?.errors?.company_name && error?.errors?.company_name[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.company_name[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.country"/>*
                                            </label>
                                            <div className="control">
                                                <div className="select custom-select is-fullwidth">
                                                    <select
                                                        className="is-radiusless"
                                                        onChange={event => setCountry(event.target.value)}
                                                    >
                                                        <option className='is-hidden'/>
                                                        {countries?.map(country => (
                                                            <option
                                                                key={country.id}
                                                                value={country.id}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: `country.${country.code}`
                                                                })}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {error?.errors?.country && error?.errors?.country[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.country[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="columns mb-4">
                                            <div className="column pb-0">
                                                <div className="field">
                                                    <label className="label has-text-black has-text-weight-normal">
                                                        <FormattedMessage id="quote.first_name"/>*
                                                    </label>
                                                    <div className="control">
                                                        <input
                                                            className="input custom-input is-radiusless"
                                                            maxLength={25}
                                                            onChange={event => setName(event.target.value)}
                                                        />
                                                    </div>
                                                    {error?.errors?.first_name && error?.errors?.first_name[0] && (
                                                        <p className="help is-danger is-size-6 pb-3">
                                                            {error.errors.first_name[0]}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="column pb-0">
                                                <div className="field">
                                                    <label className="label has-text-black has-text-weight-normal">
                                                        <FormattedMessage id="quote.last_name"/>*
                                                    </label>
                                                    <div className="control">
                                                        <input
                                                            className="input custom-input is-radiusless"
                                                            maxLength={25}
                                                            onChange={event => setLastName(event.target.value)}
                                                        />
                                                    </div>
                                                    {error?.errors?.last_name && error?.errors?.last_name[0] && (
                                                        <p className="help is-danger is-size-6 pb-3">
                                                            {error.errors.last_name[0]}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="columns mb-4">
                                            <div className="column pb-0">
                                                <div className="field">
                                                    <label className="label has-text-black has-text-weight-normal">
                                                        <FormattedMessage id="quote.email"/>*
                                                    </label>
                                                    <div className="control">
                                                        <input
                                                            className="input custom-input is-radiusless"
                                                            maxLength={255}
                                                            onChange={event => setEmail(event.target.value)}
                                                            type="email"
                                                        />
                                                    </div>
                                                    {error?.errors?.email && error?.errors?.email[0] && (
                                                        <p className="help is-danger is-size-6 pb-3">
                                                            {error.errors.email[0]}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="column pb-0">
                                                <div className="field">
                                                    <label className="label has-text-black has-text-weight-normal">
                                                        <FormattedMessage id="quote.phone_number"/>*
                                                    </label>
                                                    <div className="control">
                                                        <input
                                                            className="input custom-input is-radiusless"
                                                            maxLength={16}
                                                            onChange={event => setPhone(event.target.value)}
                                                        />
                                                    </div>
                                                    {error?.errors?.phone_number && error?.errors?.phone_number[0] && (
                                                        <p className="help is-danger is-size-6 pb-3">
                                                            {error.errors.phone_number[0]}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.source_language"/>*
                                            </label>
                                            <div className="control">
                                                <div className="select custom-select is-fullwidth">
                                                    <select
                                                        className="is-radiusless"
                                                        onChange={event => onChange(event.target.value)}
                                                    >
                                                        <option className='is-hidden'/>
                                                        {sourceLanguages?.map(language => (
                                                            <option
                                                                key={language.source_lang}
                                                                value={language.source_lang}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: `language.${language.source_language.iso_code}`
                                                                })}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {error?.errors?.source_language && error?.errors?.source_language[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.source_language[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.target_languages"/>*
                                            </label>
                                            <div className="control">
                                                <div className="select is-multiple custom-select is-fullwidth">
                                                    <Select
                                                        classNamePrefix="select"
                                                        isMulti
                                                        onChange={setTargets}
                                                        options={targetLanguages?.map(language => ({
                                                            label: intl.formatMessage({
                                                                id: `language.${language.target_language.iso_code}`
                                                            }),
                                                            value: language.target_lang
                                                        }))}
                                                        placeholder=""
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                borderRadius: 0,
                                                                minHeight: "2.5rem",
                                                            }),
                                                            dropdownIndicator: base => ({...base, color: "#5e696a"}),
                                                            indicatorSeparator: base => ({...base, display: "none"}),
                                                        }}
                                                        value={targets}
                                                    />
                                                </div>
                                            </div>
                                            {error?.errors?.target_languages && error?.errors?.target_languages[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.target_languages[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.attach_file"/>*
                                                <br/>
                                                (<FormattedMessage id="quote.attach_file.description"/>)
                                            </label>
                                            <div className="control">
                                                <div className="file custom-file has-name is-fullwidth">
                                                    <label className="file-label py-5">
                                                        <input
                                                            accept={FILE_TYPES.join(", ")}
                                                            className="file-input"
                                                            multiple
                                                            name="resume"
                                                            onChange={event => setFiles(event.target.files)}
                                                            type="file"
                                                        />
                                                        <span className="file-cta has-text-black px-5">
                                                            <span className="file-label">
                                                                <FormattedMessage id="quote.select_files"/>
                                                            </span>
                                                        </span>
                                                        <span className="file-name has-text-black px-5">
                                                            {!files || files.length < 1 ? (
                                                                <FormattedMessage id="quote.no_files_selected"/>
                                                            ) : files.length === 1 ? (
                                                                files[0].name
                                                            ) : (
                                                                intl.formatMessage(
                                                                    {id: "quote.files"},
                                                                    {files: files.length}
                                                                )
                                                            )}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            {error?.errors?.files && error?.errors?.files[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.files[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="field mb-4">
                                            <label className="label has-text-black has-text-weight-normal">
                                                <FormattedMessage id="quote.comments"/>
                                            </label>
                                            <div className="control">
                                                <textarea
                                                    className="textarea custom-textarea is-radiusless"
                                                    maxLength={255}
                                                    onChange={event => setComments(event.target.value)}
                                                    rows="2"
                                                />
                                            </div>
                                            {error?.errors?.comments && error?.errors?.comments[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.comments[0]}
                                                </p>
                                            )}
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <label className="checkbox custom-checkbox is-size-7 ml-3 py-2">
                                                    <input
                                                        className="mr-1"
                                                        onChange={event => setPrivacy(event.target.checked)}
                                                        type="checkbox"
                                                    />
                                                    <FormattedMessage id="quote.accept_terms"/>
                                                    <a
                                                        className="has-text-black ml-1"
                                                        href={privacyPolicy[locale.name]}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        <FormattedMessage id="quote.privacy_policy"/>
                                                    </a>*
                                                </label>
                                            </div>
                                            {error?.errors?.privacy_policy && error?.errors?.privacy_policy[0] && (
                                                <p className="help is-danger is-size-6 pb-3">
                                                    {error.errors.privacy_policy[0]}
                                                </p>
                                            )}
                                        </div>
                                        {error?.errors?.recaptcha_token && error?.errors?.recaptcha_token[0] && (
                                            <p className="help is-danger is-size-6 pb-3">
                                                {error.errors.recaptcha_token[0]}
                                            </p>
                                        )}
                                        <div className="field">
                                            <div className="control">
                                                <button
                                                    className={`button custom-button has-text-white is-uppercase mt-6 px-6${loading ? " is-loading" : ""}`}
                                                    disabled={loading}
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="quote.submit"/>
                                                </button>
                                            </div>
                                            {errorStatus && errorStatus !== 422 && (
                                                <p className="help is-danger is-size-6">
                                                    <FormattedMessage id="quote.error"/>
                                                </p>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Budget;
