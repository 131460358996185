import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {
    getTask,
    updateTaskStatusAsIncourse,
    updateTaskStatusAsDone,
    getPaymentGateway,
    updateTaskStatusAsApproved, updateTaskStatusAsDraft, updateTaskStatusAsTemporal
} from "../../store/reducers/task/taskActions";
import * as TaskState from "../../utils/enums/TaskState";
import {toast} from "react-toastify";
import * as actions from '../../store/Actions';
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import * as Actions from "../../store/Actions";
import parse from "html-react-parser";
import {getPaymentScheme} from "../../store/reducers/user/userActions";
import * as UserTypes from "../../store/reducers/auth/userTypes";

const EditTaskStatus = (props) => {
    const id = props.match.params.id;

    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const task = useSelector(state => state.task.task);
    const success = useSelector(state => state.task.success);
    const error = useSelector(state => state.task.error);
    const paymentScheme = useSelector(state => state.user.payment_scheme);
    const paymentForm = useSelector(state => state.task.paymentForm);
    const hasBeenReactivated = useSelector(state => state.task.hasBeenReactivated);

    useEffect(() => {
        dispatch(getTask(id));
        dispatch(getPaymentScheme());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (task) {
            if (
                task.current_task_state?.state === TaskState.APPROVED ||
                task.current_task_state?.state === TaskState.IN_COURSE ||
                task.current_task_state?.state === TaskState.DONE
            ) dispatch(getPaymentScheme());
            else history.push({pathname: "/tasks", exact: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    useEffect(() => {
        if (success || hasBeenReactivated) {
            toast.success(intl.formatMessage({id: "task.success.updateStatus"}));
            dispatch({type: actions.CLEAR_TASK_SUCESS});
            dispatch({type: actions.RESET_REACTIVATED_TASK_STATUS});
            history.push({pathname: "/tasks", exact: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, hasBeenReactivated]);

    useEffect(() => {
        if (error) {
            toast.success(intl.formatMessage({id: "task.error.updateStatus"}));
            dispatch({type: actions.CLEAR_TASK_ERROR});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        dispatch({type: Actions.HIDE_MODAL});
        if (paymentForm !== null) {
            let element = document.getElementById("payment_form");
            if (element !== null) element.submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentForm])

    const updateStatus = () => {
        let formData = new FormData();
        switch (document.querySelector("#new_task_status").value){
            case TaskState.TEMPORAL:
                dispatch(updateTaskStatusAsTemporal(id));
                break;
            case TaskState.DRAFT:
                dispatch(updateTaskStatusAsDraft(id));
                break;
            case TaskState.APPROVED:
                dispatch(updateTaskStatusAsApproved(id,formData));
                break;
            case TaskState.IN_COURSE:
                dispatch(updateTaskStatusAsIncourse(id,formData));
                break;
            case TaskState.DONE:
                dispatch(updateTaskStatusAsDone(id,formData));
                break;
            default:
        }
    }

    const updateStatusToApproved = () => {
        let formData = new FormData();
        formData.append("taskId", task.id);
        dispatch(getPaymentGateway(formData));
        dispatch({type: Actions.SHOW_MODAL, payload: {modalType: "loading"}})
    }

    const paymentHtmlForm = () => {
        if (paymentForm !== null){
            return parse(paymentForm);
        }
    }

    const continueButton = () => {
        let currentTaskState = task?.current_task_state?.state;

        if (currentTaskState === TaskState.DRAFT && paymentScheme === "ON_DEMAND") {
            return (
                <>
                    <div className="notification is-primary is-light">
                        {intl.formatMessage({id: "task.message.pay_to_change_state"})}
                    </div>
                    <button
                        id="updateStatus"
                        className="button is-primary"
                        onClick={(e) => {
                            updateStatusToApproved(e);
                        }}>
                        {intl.formatMessage({id: "task.pay"})}
                    </button>
                    {paymentHtmlForm()}
                </>)
        } else {
            return (
                <button
                    id="updateStatus"
                    className="button is-primary"
                    onClick={(e) => {
                        updateStatus(e);
                    }}>
                    {intl.formatMessage({id: "general.update"})}
                </button>)
        }
    }

    const Options = () => {
        let currentTaskState = task?.current_task_state?.state;
        let userType = task?.user?.type ?? UserTypes.invitado;
        let options;

        if (userType !== UserTypes.invitado) {
            options = [
                <option
                    value={TaskState.TEMPORAL}
                    selected={currentTaskState === TaskState.TEMPORAL}>{intl.formatMessage({id: "task.state." + TaskState.TEMPORAL})}
                </option>,
                <option
                    value={TaskState.DRAFT}
                    selected={currentTaskState === TaskState.TEMPORAL}>{intl.formatMessage({id: "task.state." + TaskState.DRAFT})}
                </option>,
                <option
                    value={TaskState.APPROVED}
                    selected={currentTaskState === TaskState.DRAFT}>{intl.formatMessage({id: "task.state." + TaskState.APPROVED})}
                </option>,
                <option
                    value={TaskState.IN_COURSE}
                    selected={currentTaskState === TaskState.APPROVED}>{intl.formatMessage({id: "task.state." + TaskState.IN_COURSE})}
                </option>,
                <option
                    value={TaskState.DONE}
                    selected={currentTaskState === TaskState.IN_COURSE}>{intl.formatMessage({id: "task.state." + TaskState.DONE})}
                </option>
            ];
        } else {
            options = [
                    <option
                        value={TaskState.TEMPORAL}
                        selected={currentTaskState === TaskState.TEMPORAL}>{intl.formatMessage({id: "task.state." + TaskState.TEMPORAL})}
                    </option>,
                    <option
                        value={TaskState.DRAFT}
                        selected={currentTaskState === TaskState.TEMPORAL}>{intl.formatMessage({id: "task.state." + TaskState.DRAFT})}
                    </option>
                ];
        }

        options.forEach(function (v, i) {
            if (v.props.value === currentTaskState) {
                options.splice(i, 1);
            }
        });

        return options;
    }



    return (
        <div className="box is-desktop">
            <div className="columns is-desktop">
                <Breadcrumbs
                    title={intl.formatMessage({id: "task.id"}) + ": " + id}
                    destinationTitle={intl.formatMessage({id: "menu.tasks"})}
                    destination={["/tasks","/tasks/"+id]}
                    labels={[intl.formatMessage({id: "menu.tasks"}),intl.formatMessage({id: "submenu.task"})]}
                />
            </div>

            <hr/>
            <div className="columns is-mobile">
                <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                    <ul>
                        <li className="is-active title is-4 has-text-primary"><FormattedMessage id="task.edit_status"/>
                        </li>
                    </ul>

                </nav>
            </div>
            <hr/>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="control">
                        <label htmlFor="current_task_status">{intl.formatMessage({id: "task.current_status"})}:</label>
                        <input className="input" id="current_task_status" name="current_task_status"
                               value={task?.current_task_state?.state && intl.formatMessage({id: "task.state." + task?.current_task_state?.state})}
                               readOnly={true}/>
                    </div>
                </div>
                <div className="column">
                    <div className="control">
                        <label htmlFor="new_task_status">{intl.formatMessage({id: "task.new_status"})}:</label>
                        <select id="new_task_status" name="new_task_status" className="input">
                            <Options/>
                        </select>
                    </div>
                </div>
            </div>
            <div className="columns is-desktop">
                <div className="column">
                    {continueButton()}
                </div>
            </div>
        </div>
    );
}
export default connect()(EditTaskStatus);
