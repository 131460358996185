// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import facebook from '../assets/img/facebook.png';
import youtube from '../assets/img/youtube.png';
import twitter from '../assets/img/twitter.png';
import linkedin from '../assets/img/linkedin.png';

export default function Footer() {
    // ** Hook
    const locale = useSelector(state => state.locale.locale);
    const location = useLocation();

    const barcelona = {
        en: "en/barcelona-translation-agency",
        es: "es/agencia-traduccion-barcelona",
        ca: "ca/agencia-traduccio-barcelona"
    }
    const xalapa = {
        en: "en/xalapa-translation-agency",
        es: "es/agencia-traduccion-xalapa",
        ca: "ca/agencia-traduccio-xalapa"
    }
    const portoAlegre = {
        en: "en/porto-alegre-translation-agency",
        es: "es/agencia-traduccion-porto-alegre",
        ca: "ca/agencia-traduccio-porto-alegre"
    }
    const miami = {
        en: "en/miami-translation-agency",
        es: "es/agencia-traduccion-miami",
        ca: "ca/agencia-traduccio-miami"
    }

    const legalDisclaimer = {
        en: "en/logal-notice",
        es: "es/aviso-legal",
        ca: "ca/avis-legal"
    }
    const cookiePolicy = {
        en: "en/cookie-policy",
        es: "es/politica-de-cookies",
        ca: "ca/politica-de-galetes"
    }
    const privacy = {
        en: "en/privacy-policy",
        es: "es/politica-privacidad",
        ca: "ca/politica-privacitat"
    }
    const accessibility = {
        en: "en/accessibility",
        es: "es/accesibilidad",
        ca: "ca/accessibilitat"
    }
    const securityPolicy = {
        en: "Security-Policy-eng.pdf",
        es: "politica_de_seguridad_firmada_ES.pdf",
        ca: "Politica_de_seguretat_cat.pdf"
    }

    return (
        <>
            {(location.pathname === "/quote" || location.pathname === "/quote/registered") ? (
                <footer className="footer has-background-black p-0">
                    <div className="pt-4 px-4">
                        <div className="container">
                            <hr className="has-background-grey" style={{height: "1px"}}/>
                            <a href={`https://www.idisc.com/${locale.name}`}>
                                <img src="/logo-dark.svg" alt="Logo" height={75}/>
                            </a>
                            <hr className="has-background-grey" style={{height: "1px"}}/>
                            <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between">
                                <div className="is-align-items-center is-flex is-flex-wrap-wrap mb-4 pl-2 pr-5">
                                    <span className="mr-2 has-text-grey-lighter">
                                        <FormattedMessage id="footer.share_page"/>
                                    </span>
                                    <a
                                        className="ml-2 mr-4"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https://www.idisc.com/${locale.name}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="facebook" src={facebook}/>
                                    </a>
                                    <a
                                        className="ml-2 mr-4"
                                        href={`https://twitter.com/intent/tweet?text=Contacta%20amb%20nosaltres%20https://www.idisc.com/${locale.name}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="twitter" src={twitter}/>
                                    </a>
                                    <a
                                        className="ml-2 mr-4"
                                        href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.idisc.com/${locale.name}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="linkedin" src={linkedin}/>
                                    </a>
                                </div>
                                <div className="is-align-items-center is-flex is-flex-wrap-wrap mb-4 pl-2">
                                    <span>
                                        <FormattedMessage id="footer.follow_us"/>
                                    </span>
                                    <a
                                        className="ml-2 mr-4"
                                        href="https://www.facebook.com/idiscit"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="facebook" src={facebook}/>
                                    </a>
                                    <a
                                        className="ml-2 mr-4"
                                        href="https://twitter.com/iDISCit"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="twitter" src={twitter}/>
                                    </a>
                                    <a
                                        className="ml-2 mr-4"
                                        href="https://www.linkedin.com/company/idiscit"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="linkedin" src={linkedin}/>
                                    </a>
                                    <a
                                        className="ml-2 mr-4"
                                        href="https://www.youtube.com/user/iDISCtv"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img alt="youtube" src={youtube}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4" style={{ backgroundColor: "#AD2A1E" }}>
                        <div className="container is-flex is-flex-wrap-wrap is-justify-content-space-between">
                            <div className="pr-3 py-3">
                                {`©${new Date().getFullYear()} iDISC Information Technologies, S.L. | All Rights Reserved`}
                            </div>
                            <div className="pl-3 py-3">
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${barcelona[locale.name]}`}
                                >
                                    Barcelona
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${xalapa[locale.name]}`}
                                >
                                    Xalapa
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${portoAlegre[locale.name]}`}
                                >
                                    Porto Alegre
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${miami[locale.name]}`}
                                >
                                    Miami
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="has-background-white px-4">
                        <div className="container pb-5 pt-3">
                            <a
                                className="has-text-black mr-5"
                                href={`https://www.idisc.com/${legalDisclaimer[locale.name]}`}
                            >
                                <FormattedMessage id="footer.legal_disclaimer"/>
                            </a>
                            <a
                                className="has-text-black mr-5"
                                href={`https://www.idisc.com/${cookiePolicy[locale.name]}`}
                            >
                                <FormattedMessage id="footer.cookie_policy"/>
                            </a>
                            <a
                                className="has-text-black mr-5"
                                href={`https://www.idisc.com/${privacy[locale.name]}`}
                            >
                                <FormattedMessage id="footer.privacy"/>
                            </a>
                            <a
                                className="has-text-black mr-5"
                                href={`https://www.idisc.com/${accessibility[locale.name]}`}
                            >
                                <FormattedMessage id="footer.accessibility"/>
                            </a>
                            <a
                                className="has-text-black mr-5"
                                href={`https://info.idisc.com/hubfs/Certificaciones/${securityPolicy[locale.name]}`}
                            >
                                <FormattedMessage id="footer.security_policy"/>
                            </a>
                        </div>
                    </div>
                </footer>
            ) : (
                <footer className="footer custom-footer">
                    <div className="columns">
                        <div className="column footer-logo">
                            <a href={`https://www.idisc.com/${locale.name}`} rel="noopener noreferrer" target="_blank">
                                <img src="/logo-dark.svg" alt="Logo" height={75}/>
                            </a>
                        </div>
                        <div className="column footer-text footer-cities">
                            <div className="is-flex is-justify-content-center">
                                {`©${new Date().getFullYear()} iDISC Information Technologies, S.L. | All Rights Reserved`}
                            </div>
                        </div>
                        <div className="column footer-text footer-cities">
                            <div className="is-flex is-justify-content-end">
                                <a
                                    className="has-text-white"
                                    href={`https://www.idisc.com/${barcelona[locale.name]}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Barcelona
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${xalapa[locale.name]}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Xalapa
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${portoAlegre[locale.name]}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Porto Alegre
                                </a>
                                <a
                                    className="has-text-white ml-5"
                                    href={`https://www.idisc.com/${miami[locale.name]}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Miami
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            )}
        </>
    );
}
