import * as actionTypes from '../../../Actions';

const initialState = {
    password: null,
    confirmpassword: null,
    isConfirmed: false,
    isTokenValid:null,
    error: null,
};

const reducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case actionTypes.CHANGE_NEW_PASSWORD:
            return {
                ...state,
                password: action.payload
            };
        case actionTypes.CHANGE_CONFIRM_NEW_PASSWORD:
            return {
                ...state,
                confirmpassword: action.payload,
            };
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isConfirmed: true
            };
        case actionTypes.RESET_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case actionTypes.VALID_TOKEN:
            return {
                ...state,
                isTokenValid: true
            };
        case actionTypes.INVALID_TOKEN:
            return {
                ...state,
                error: action.payload,
                isTokenValid: false
            };
        case actionTypes.RESET_TOKEN_VALID:
            return {
                ...state,
                isTokenValid: action.payload.data
            };
        case actionTypes.RESET_ERRORS:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
