import React from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';
import LottieAnimation from '../Lottie';
import loader from '../../assets/verify-files.json'
import {useIntl} from "react-intl";

function ModalLoading(props) {
    const intl = useIntl();

    return (
        <div id="modal" className="modal">
            <div className="modal-background"/>
            <LottieAnimation lotti={loader} height={300} width={300}/>
            <div className="modal-content">
                <div className="has-text-white has-text-centered has-text-weight-bold">
                    {intl.formatMessage({id: "task.loading_files"})}
                </div>
            </div>
        </div>
    );
}

export default ModalHOC(ModalLoading);
