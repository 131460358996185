export const useDate = () => {
    const dateFormat = (date, locale) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, "0");
        const month = (d.getMonth() + 1).toString().padStart(2, "0");
        const year = d.getFullYear();
        const formats = {
            en: `${month}-${day}-${year}`,
            es: `${day}-${month}-${year}`
        }

        return formats[locale] || formats.es;
    }

    const dateTimeFormat = (date, locale) => {
        const d = new Date(date);
        const hours = d.getHours().toString().padStart(2, "0");
        const minutes = d.getMinutes().toString().padStart(2, "0");
        const seconds = d.getSeconds().toString().padStart(2, "0");

        return `${dateFormat(date, locale)} ${hours}:${minutes}:${seconds}`;
    }

    const addDaysToDateFormat = (date, locale, days = 0) => dateFormat(checkValidDays(date, days), locale);

    const latestDueDate = (totalWord = 0, translationTypeId = null) =>
        checkValidDays(new Date(), translationTypeId !== 4 ? Math.ceil(totalWord / 1500) + 2 + 1 : 1);

    const checkValidDays = (date, days) => {
        const d = new Date(date);
        if (days >= 0) {
            let day = 1;
            while (day <= days) {
                d.setDate(d.getDate() + 1);
                if (d.getDay() !== 0 && d.getDay() !== 6) day++;
            }
        } else {
            let day = -1;
            while (day >= days) {
                d.setDate(d.getDate() - 1);
                if (d.getDay() !== 0 && d.getDay() !== 6) day--;
            }
        }

        return d
    }

    return {
        dateFormat,
        dateTimeFormat,
        addDaysToDateFormat,
        latestDueDate
    }
}
