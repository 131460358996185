import * as actionTypes from '../../Actions';
import {SET_CUSTOMER_DISCOUNT, SET_SIMPLE_CUSTOMERS} from "../../Actions";

const initialState = {
    customers: [],
    simpleCustomers: [],
    customer:null,
    languages: [],
    IDCPP_code:"",
    memoqProjectId: "",
    tableValues:[],
    users:[],
    message:null,
    error:null,
    paginationInfo: null,
    rates:[],
    generalRates:[],
    countries: [],
    applyDiscount: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "RETRIVE_CUSTOMERS_SUCCESS":
            return {
                ...state,
                customers: action.payload,
                message:{type:"RETRIVE_CUSTOMERS_SUCCESS"},
                paginationInfo: action.paginationInfo
            }
        case SET_SIMPLE_CUSTOMERS:
            return {
                ...state,
                simpleCustomers: action.payload,
            }
        case SET_CUSTOMER_DISCOUNT:
            return {
                ...state,
                applyDiscount: action.payload,
            }
        case "RETRIVE_CUSTOMER_SUCCESS":
            return {
                ...state,
                customer: action.payload.data,
                message:{type:"RETRIVE_CUSTOMER_SUCCESS"}
            }
        case "RETRIVE_CUSTOMER_ERROR":
            return {
                ...state,
                error:{type:"RETRIVE_CUSTOMER_ERROR"}
            }

        case "RETRIVE_CUSTOMER_USERS_SUCCESS":
            return {
                ...state,
                users: action.payload.data.users,
                message:{type:"RETRIVE_CUSTOMER_USERS_SUCCESS"}
            }
        case "RETRIVE_LANGUAGES_SUCCESS":
            return {
                ...state,
                languages: action.payload,
                message:{type:"RETRIVE_LANGUAGES_SUCCESS"}
            }
        case "RETRIVE_LANGUAGES_ERROR":
            return {
                ...state,
                error: {type:"RETRIVE_LANGUAGES_ERROR"}
            }
        case "SET_IDCPP_code":
            return {
                ...state,
                IDCPP_code: action.payload.IDCPP_code
            }
        case "RETRIVE_MEMOQ_SUCCESS":
            return {
                ...state,
                tableValues: action.payload,
                message:{type:"RETRIVE_MEMOQ_SUCCESS"}
            }
        case "RETRIVE_MEMOQ_ERROR":
            return {
                ...state,
                error: {type:"RETRIVE_MEMOQ_ERROR"}
            }
        case "SET_MEMOQ_PROJECTS":
            return {
                ...state,
                tableValues: action.payload
            }
        case "RETRIVE_IDCP_SUCCESS":
            let code = null;
            if (action.payload.data.idcp_project !== null){
                code = action.payload.data.idcp_project.project_id;
            }
            return {
                ...state,
                IDCPP_code: code,
                message:{type:"RETRIVE_IDCP_SUCCESS"}
            }
        case "RETRIVE_IDCP_ERROR":
            return {
                ...state,
                error: {type:"RETRIVE_IDCP_ERROR"}
            }
        case "RETRIVE_MEMOQ_PROJECT_ID_SUCCESS":
            return {
                ...state,
                memoqProjectId: action.payload ?? "",
            }
        case "RETRIVE_MEMOQ_PROJECT_ID_ERROR":
            return {
                ...state,
                error: {type: "RETRIVE_MEMOQ_PROJECT_ID_ERROR"}
            }
        case "CLEAR_ERROR":
            return {
                ...state,
                error: null
            }
        case "CLEAR_SUCCESS_MESSAGE":
            return {
                ...state,
                message: null
            }
        case "CLEAR_ASSIGN_DATA":
            return {
                ...state,
                customer:null,
                languages: [],
                IDCPP_code:"",
                tableValues:[],
                message:null,
                users: [],
                error:null,
                rates: []
            }
        case "ASSIGN_PROJECTS_SUCCESS":
            return {
                ...state,
                message: {type:"ASSIGN_PROJECTS_SUCCESS"}
            }
        case "ASSIGN_PROJECTS_ERROR":
            return {
                ...state,
                error: {type:"ASSIGN_PROJECTS_ERROR"}
            }
        case "CHANGE_CUSTOMER":
            return {
                ...state,
                customer: action.payload
            }
        case "UPDATE_CUSTOMER_SUCCESS":
            return {
                ...state,
                message: {type: "UPDATE_CUSTOMER_SUCCESS"}
            }
        case "SAVE_CUSTOMER_SUCCESS":
            return {
                ...state,
                message: {type: "SAVE_CUSTOMER_SUCCESS"}
            }
        case "SAVE_CUSTOMER_ERROR":
            return {
                ...state,
                error: action.payload
            }
        case "DELETE_CUSTOMER_SUCCESS":
            return {
                ...state,
                message: {type: "DELETE_CUSTOMER_SUCCESS"}
            }
        case "DELETE_CUSTOMER_ERROR":
            return {
                ...state,
                error: action.payload
            }

        case "UPLOAD_EXCEL_SUCCESS":
            return {
                ...state,
                message: {type: "UPLOAD_EXCEL_SUCCESS"}
            }
        case "UPLOAD_EXCEL_ERROR":
            return {
                ...state,
                error: {type:"UPLOAD_EXCEL_ERROR"}
            }
        case "GET_RATE_SUCCESS":
            return {
                ...state,
                rates: action.payload.data
            }
        case "GET_RATE_ENABLE_SUCCESS":
            return {
                ...state,
                generalRates: action.payload.data
            }
        case actionTypes.GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case actionTypes.DELETE_RATE_SUCCESS:
            return {
                ...state,
                message: {type: actionTypes.DELETE_RATE_SUCCESS}
            }
        case actionTypes.DELETE_ENABLE_RATE_SUCCESS:
            return {
                ...state,
                message: {type: actionTypes.DELETE_ENABLE_RATE_SUCCESS}
            }
        case "UPDATE_RATE":
            return {
                ...state,
                message: {type: "UPDATE_RATE"}
            }
        case "CREATE_RATE":
            return {
                ...state,
                message: {type: "CREATE_RATE"}
            }
        default:
            return state;
    }
}

export default reducer;
