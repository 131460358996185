import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';

export const getRates = (data) => {
    return (dispatch, getState) =>{
        return axiosInstance.get("/rates",{
            params:data
        })
            .then(res =>{
                dispatch({ type: actions.GET_RATES, payload: res.data.data})
            }).catch(() => null)
    }
}

export const getCanUserReviewFileAccordingLanguage = (sourceLanguage) => {
    return (dispatch) =>{
        return axiosInstance.get("/rates/review-originals/" + sourceLanguage)
            .then(res =>{
                dispatch({ type: actions.CAN_USER_REVIEW_ORIGINAL, payload: res.data.data})
            }).catch(() => null)
    }
}

export const getRatesSourceLanguages = () => dispatch => axiosInstance
    .get("/rates/languages/sources")
    .then(response => {
        dispatch({ type: actions.GET_RATES_SOURCE_LANGUAGES, payload: response.data.data })
    })
    .catch(() => null)

export const getRatesTargetLanguages = sourceId => dispatch => axiosInstance
    .get(`/rates/languages/${sourceId}/targets`)
    .then(response => {
        dispatch({ type: actions.GET_RATES_TARGET_LANGUAGES, payload: response.data.data })
    })
    .catch(() => null)

export const createSingleRate = (rate) => {
    return (dispatch) =>{
        return axiosInstance.post("/rate/store",rate)
            .then(res =>{
                dispatch({ type: "CREATE_RATE", payload: res.data.data})
            }).catch(() => null)
    }
}

export const updateSingleRate = (idRate,rate) => {
    return (dispatch) =>{
        return axiosInstance.put("/rate/" + idRate,rate)
            .then(res =>{
                dispatch({ type: "UPDATE_RATE", payload: res.data.data})
            }).catch(() => null)
    }
}

export const enableRateByCustomer = (idRate,customer_id) => {
    return async(dispatch) =>{
        try {
            await axiosInstance.post("/customers/" + customer_id + "/rate/" + idRate);
            dispatch({type: "ENABLE_RATE_SUCCESS"});
            let enablesRatesResponse = await axiosInstance.get("/customers/" + customer_id + "/enable/rate");
            dispatch({type: "GET_RATE_ENABLE_SUCCESS", payload: enablesRatesResponse.data});
            let RatesResponse = await axiosInstance.get("/rates", {
                params: {"customer_id": customer_id}
            });
            dispatch({type: actions.GET_RATES, payload: RatesResponse.data.data});
        } catch (error) {
            dispatch({type: "GET_RATE_ENABLE_ERROR", payload: error});
        }
    }
}
