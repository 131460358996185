// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInfoTranslationTypes = props => {
    // ** Hooks
    const locale = useSelector(state => state.locale.locale);

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    {props.extraData.translationTypes?.map(type => (
                        <div className="content" key={type.id}>
                            <h1 className="is-size-5">{type[`name_${locale.name}`]}</h1>
                            <p className="has-text-weight-bold">{type[`description_${locale.name}`]}</p>
                            <p>{type[`description_2_${locale.name}`]}</p>
                        </div>
                    ))}
                    <div className="content has-text-right">
                        <a
                            href="https://www.idisc.com/es/xpress/tipos-de-traduccion"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <FormattedMessage id="general.info_more_info"/>...
                        </a>
                    </div>
                </section>
            </div>
        </div>
);
}

export default ModalHOC(ModalInfoTranslationTypes);
