import React from 'react';
import {useIntl} from "react-intl";
import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInactiveUser = (props) => {
    const intl = useIntl();

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{intl.formatMessage({id: "error.inactive_user"})}</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }/>
                </header>

                <section className="modal-card-body">
                    <div>
                        {intl.formatMessage({id: "error.inactive_user_message"})}
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className="button is-danger" onClick={props.closeModal}>{intl.formatMessage({id: "general.accept"})}</button>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalInactiveUser);
