import * as actionTypes from '../../Actions';

const initialState = {
    departments: [],
    selectedDepartment: null,
    departmentHasBeenDeleted: false,
    departmentHasBeenUpdated: false,
    departmentHasBeenCreated: false,
    usersOnDepartment: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload
            }
        case actionTypes.CREATE_DEPARTMENT:
            return {
                ...state,
                departmentHasBeenCreated: true
            }
        case actionTypes.RESET_CREATED_DEPARTMENT:
            return {
                ...state,
                departmentHasBeenCreated: false
            }
        case actionTypes.GET_DEPARTMENT:
            return {
                ...state,
                selectedDepartment: action.payload
            }
        case actionTypes.DELETE_DEPARTMENT:
            if (action.payload.message.indexOf("Error")>-1){
                return {
                    ...state,
                    departmentHasBeenDeleted: false,
                    usersOnDepartment:action.payload.data.users
                }
            } else {
                return {
                    ...state,
                    departmentHasBeenDeleted: true
                }
            }
        case actionTypes.RESET_DELETED_DEPARTMENT:
            return {
                ...state,
                departmentHasBeenDeleted: false,
                usersOnDepartment:null
            }
        case actionTypes.UPDATE_DEPARTMENT:
            return {
                ...state,
                departmentHasBeenUpdated: true
            }
        case actionTypes.RESET_UPDATED_DEPARTMENT:
            return {
                ...state,
                departmentHasBeenUpdated: false
            }
        default:
            return state;
    }
}

export default reducer;
