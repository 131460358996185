import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {FormattedMessage, useIntl} from 'react-intl';
import {useForm} from "react-hook-form";
import {createDepartment} from '../../store/reducers/department/departmentActions';
import * as actions from '../../store/Actions';
import {toast} from "react-toastify";
import {Link, useHistory} from 'react-router-dom';

export default function CreateDepartment() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [isSending, setIsSending] = useState(false);
    const departmentHasBeenCreated = useSelector(state => state.department.departmentHasBeenCreated);
    const history = useHistory();
    const {register, handleSubmit, errors} = useForm();


    const onSubmit = (data) => {
        setIsSending(true);
        dispatch(createDepartment(data));
    }

    useEffect(() => {
        if (departmentHasBeenCreated) {
            setIsSending(false);
            toast.success(intl.formatMessage({id: "general.element_saved"}));
            dispatch({type: actions.RESET_CREATED_DEPARTMENT});
            history.push("/departments")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentHasBeenCreated]);

    let alert = null;


    return (
        <>
            <div className="box">

                <div className="columns is-mobile">
                    <nav className="column is-11 breadcrumb has-arrow-separator is-medium" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link href="#" to="/departments" className="title is-4">
                                    <FormattedMessage id="menu.departments" />
                                </Link>
                            </li>
                            <li className="is-active title is-4">
                                <a aria-current="page" className="has-text-primary" href="/#">
                                    <FormattedMessage id="department.add"/>
                                </a>
                            </li>
                        </ul>

                    </nav>
                </div>

                <hr/>

                {alert}

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="field">
                        <label className={"label"}>
                            <FormattedMessage id={"department.title"}/> *
                        </label>
                        <div className="control">
                            <input className={"input has-icons-right"} name="title" id="title"
                                   ref={register({required: true})}/>
                            {errors.title && <p className="help is-danger"><FormattedMessage id="general.field_required"/></p>}
                        </div>
                    </div>

                    <button className={"button is-link " + (isSending && "is-loading")} disabled={isSending} ><FormattedMessage id="general.save"/>
                    </button>

                </form>

            </div>
        </>
)
}
