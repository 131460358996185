import React from 'react';
import * as TaskState from "../../utils/enums/TaskState";
import {useIntl} from "react-intl";

const TaskStateTag = props => {
    const intl = useIntl();
    const cssBystate = state => {
        let classString = "tag ";
        switch (state) {
            case TaskState.TEMPORAL:
                classString += "is-link is-light";
                break;
            case TaskState.DRAFT:
                classString += "is-danger";
                break;
            case TaskState.CANCELLED:
                classString += "is-danger";
                break;
            case TaskState.APPROVED:
                classString += "is-primary";
                break;
            case TaskState.IN_COURSE:
                classString += "is-warning";
                break;
            case TaskState.DONE:
                classString += "is-success";
                break;
            default:
                classString = "tag";
                break;
        }

        return classString;
    }

    return (
        <span className={cssBystate(props.task.current_task_state?.state)} style={{ pointerEvents: "none" }}>
            {props.task.current_task_state?.state &&
                intl.formatMessage({id: "task.state." + props.task.current_task_state?.state})
            }
        </span>
    )
}

export default TaskStateTag;
