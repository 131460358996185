import axios from 'axios';
import * as actions from "../../Actions";

export const store = params => dispatch => {
    dispatch({ type: actions.REGISTER_LOADING, payload: true });

    return axios
        .post(`${process.env.REACT_APP_API_URL}/register`, params)
        .then(() => {
            dispatch({ type: actions.REGISTER_SUCCESS, payload: true });
        })
        .catch(error => {
            dispatch({ type: actions.REGISTER_ERROR, payload: error.response });
        })
        .finally(() => {
            dispatch({ type: actions.REGISTER_LOADING, payload: false });
        });
}
