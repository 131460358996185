import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, useIntl } from 'react-intl';
import { getDepartments } from '../../store/reducers/department/departmentActions';
import {Link, useHistory} from 'react-router-dom';
import DepartmentHOC from "../../hoc/Department/DepartmentHOC";
import Datatable from "../../components/Datatable/Datatable";
import * as userTypes from "../../store/reducers/auth/userTypes";

const Departments = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const departments = useSelector(state => state.department.departments);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");

    useEffect(() => {
        dispatch(getDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: intl.formatMessage({id: "general.name"}),
            selector: "title",
            sortable: true
        },
        ...(userType === userTypes.CLIENT_ADMIN ? [
            {
                name: "",
                maxWidth: "100px",
                selector: row => (
                    <button
                        className="button is-small is-danger"
                        title={intl.formatMessage({id: "general.delete"})}
                        onClick={() => onDeleteClick(row.id)}
                    >
                        <span className="icon">
                            <FontAwesomeIcon icon={faTrash}/>
                        </span>
                    </button>
                )
            }
        ] : [])
    ];

    const onDeleteClick = (id) => {
        props.deleteHandler(id)
    }

    const onRowClicked = id => history.push(`/departments/${id}${userType === userTypes.CLIENT_ADMIN ? "/edit" : ""}`);

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.departments"/>
            </h4>
            <div className="level">
                <div className="level-left"/>
                <div className="level-right">
                    <div className="level-item">
                        <Link className="button is-outlined is-primary" to="/departments/create">
                            <FormattedMessage id="department.add"/>
                        </Link>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="table-container">
                <Datatable columns={columns} data={departments} onRowClicked={row => onRowClicked(row.id)}/>
            </div>
        </div>
    )
}

export default DepartmentHOC(Departments);
