import * as actionTypes from '../../Actions';

const initialState = {
    type: "basic",
    show: false,
    message: "",

};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHOW_NOTIFICATION:
            var show= !state.show;
            var type = "basic";
            var message = "";

            type = (action.payload.type) ? action.payload.type : "";
            message = (action.payload.message) ? action.payload.message : "";

            return {
                ...state,
                show: show,
                type: type,
                message: message,
            }
        default:
            return state;
    }
}

export default reducer;
