import * as actionTypes from '../../Actions';

const initialState = {
    sourceLanguages: [],
    targetLanguages: [],
    availableLanguages: [],
    allLanguages: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_LANGUAGES:
            return {
                ...state,
                allLanguages: action.payload
            }
        case actionTypes.GET_SOURCE_LANGUAGES:
            return {
                ...state,
                sourceLanguages: action.payload
            }
        case actionTypes.GET_TARGET_LANGUAGES:
            return {
                ...state,
                targetLanguages: action.payload
            }
        case actionTypes.GET_AVAILABLE_LANGUAGES:
            return {
                ...state,
                availableLanguages: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
