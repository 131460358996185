import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from "../../Actions";

export const login = user => dispatch => axiosInstance
    .post("/auth/login", user)
    .then(response => {
        dispatch({ type: actions.SET_LOCALE, payload: response.data.data.user.language })
        dispatch({ type: actions.LOGIN, payload: response.data.data })
    })
    .catch(error => {
        dispatch({ type: actions.LOGIN_ERROR, payload: error.response })
    });

export const getUserDepartments = id => dispatch => axiosInstance
    .get(`/users/${id}/departments`)
    .then(response => {
        dispatch({ type: actions.SET_USER_DEPARTMENTS, payload: response.data})
    })
    .catch(() => null);

export const getCurrentUserDepartments = () => dispatch => axiosInstance
    .get("/users/departments")
    .then(response => {
        dispatch({ type: actions.SET_CURRENT_USER_DEPARTMENTS, payload: response.data.data.departments})
    })
    .catch(() => null);
