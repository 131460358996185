import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import * as actions from "../../store/Actions";

export default function LanguageSelector() {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const locales = useSelector(state => state.locale.locales);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const user = useSelector(state => (state.auth.user));

    useEffect(() => {
        let userLang;
        if (userType === "invitado" && locale.name === null) {
            userLang = navigator.language.split("-")[0];
        } else if (userType === "invitado") {
            userLang = locale.name;
        } else {
            userLang = user.language;
            dispatch({type: 'SET_USER_LOCALE', payload: userLang})
        }
        dispatch({type: 'SET_LOCALE', payload: userLang})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType])


    function getRemainingLanguages(selectedLanguage) {
        return locales.filter(language => language.name !== selectedLanguage)
    }

    function changeLocale(locale) {
        dispatch({type: actions.SET_LOCALE, payload: locale.name})
    }

    function LanguagesOptions(languages) {
        return (
            <div className="navbar-dropdown">
                {languages.map(language => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="navbar-item" key={language.name} href="#" onClick={() => changeLocale(language)}>
                        <FormattedMessage id={"languages." + language.name}/>
                    </a>
                ))}
            </div>
        );
    }


    const LanguageDropdown = () => {
        let remainingLanguages = getRemainingLanguages(locale.name);
        return (
            <div id="locale-dropdown" className="navbar-item has-dropdown is-hoverable">
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a className="navbar-link" href="#">
                    <FormattedMessage id={"languages." + locale.name}/>
                </a>
                {LanguagesOptions(remainingLanguages)}
            </div>)
    }

    return (
        <>
            {LanguageDropdown()}
        </>
    );
}
