import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLanguages} from "../../store/reducers/language/languageActions";
import {FormattedMessage} from "react-intl";
import Table from "../../components/SourceLanguages/Table";
import Filter from "../../components/SourceLanguages/Filter";

const SourceLanguages = () => {
    const dispatch = useDispatch();
    const languages = useSelector(state => state.language.allLanguages);

    const search = (searchString) => {
        let data = {};
        data["searchString"] = searchString;
        dispatch(getLanguages(data));
    }

    useEffect(() => {
        dispatch(getLanguages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary"><FormattedMessage id="menu.languages"/></h4>
            <hr/>
            <Filter search={search}/>
            <div className="table-container">
                <Table languages={languages}/>
            </div>
        </div>
    );
}

export default SourceLanguages;
