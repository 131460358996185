// ** React Imports
import React from "react";

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// ** Components Imports
import Datatable from "../Datatable/Datatable";

// ** Hooks
import { usePrice } from "../../hooks/usePrice";
import { useRound } from "../../hooks/useRound";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInfoRate = props => {
    // ** Hooks
    const intl = useIntl();
    const locale = useSelector(state => state.locale.locale);
    const price = usePrice();
    const round = useRound();

    const columns = [
        {
            name: intl.formatMessage({ id: "task.translation_type" }),
            selector: row => row[`name_${locale.name}`]
        },
        {
            name: intl.formatMessage({ id: "customer.r1" }),
            selector: row => `${price(round(props.extraData.rate.r1 * (row.percent / 100), 4), locale.name, 4)} €`,
            right: true
        }
    ];

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <Datatable columns={columns} data={props.extraData.translationTypes} pointerOnHover={false}/>
                </section>
            </div>
        </div>
    );
}

export default ModalHOC(ModalInfoRate);
