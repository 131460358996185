import * as actionTypes from '../../Actions';

const initialState = {
    tasks: [],
    taskInCreation: {
        name: null,
        comments: null,
        department: null,
        newTaskID: null,
        files: {},
        price: {
            basePrice: null,
            taxedPrice: null,
            taxName: null
        },
        newWordsCount: 0,
        wordCount: {
            WC: {
                total: null
            }
        },
        fileGuids: null,
        projectGUID: null,
        languagesWithFileGUIDS: [],
        sourceLanguage: null,
        targetLanguages: [],
        review: false,
        translate: false,
        applyDiscount: false,
        hasBeenCreated: false,
        isLoadingCount: false
    },
    paymentForm: null,
    task: null,
    customer: null,
    success: null,
    error: null,
    paginationInfo: null,
    filesVerification: null,
    newTask: null,
    hasBeenVerified: false,
    hasBeenSaved: false,
    hasBeenPayed: false,
    hasBeenSentToProduction: false,
    hasBudgetBeenSent: false,
    hasBudgetBeenDownloaded: false,
    hasBeenCancelled: false,
    hasBeenReactivated: false,
    hasBeenDeleted: false,
    hasBeenUploadFiles: false,
    hasBeenDeleteFiles: false,
    prices: null,
    pricesSummary: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TASKS:
            return {
                ...state,
                tasks: action.payload,
            }
        case actionTypes.SET_PAYMENT_FORM:
            return {
                ...state,
                paymentForm: action.payload
            }
        case actionTypes.GET_TASK_ID:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    newTaskID: action.payload
                }
            }
        case actionTypes.SET_REVIEW_STATE:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    review: action.payload
                }
            }
        case actionTypes.SET_TRANSLATE_STATE:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    translate: action.payload
                }
            }
        case actionTypes.SET_APPLY_DISCOUNT_STATE:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    applyDiscount: action.payload
                }
            }
        case actionTypes.SET_SELECTED_SOURCE_LANGUAGE:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    sourceLanguage: action.payload
                }
            }
        case actionTypes.SET_SELECTED_TARGET_LANGUAGES:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    targetLanguages: action.payload
                }
            }
        case actionTypes.SET_TASK_COMMENTS:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    comments: action.payload
                }
            }
        case actionTypes.SET_TASK_NAME:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    name: action.payload
                }
            }
        case actionTypes.SET_TASK_DEPARTMENT:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    department: action.payload
                }
            }
        case actionTypes.SET_LOADING_COUNT_STATUS:
            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    isLoadingCount: action.payload
                }
            }
        case actionTypes.GET_SOURCE_FILES_INFORMATION:
            let files = []
            let newWordsCount = 0;
            let basePrice = null;
            let taxName = null;
            let taxedPrice = null;
            let languagesWithFileGUIDS = null;

            if (action.payload !== []) {
                files = action.payload.files;
                newWordsCount = action.payload.files.totalNewWords//getTotalNewWordCount(files);
                basePrice = action.payload.totalPrice.basePrice;
                taxName = action.payload.totalPrice.taxName;
                taxedPrice = action.payload.totalPrice.taxedPrice;
                languagesWithFileGUIDS = action.payload.languagesWithFileGUIDS[0]
            }

            return {
                ...state,
                taskInCreation: {
                    ...state.taskInCreation,
                    newWordsCount: newWordsCount,
                    files: files,
                    projectGUID: action.payload.projectGUID,
                    price: {
                        basePrice: basePrice,
                        taxName: taxName,
                        taxedPrice: taxedPrice,
                    },
                    languagesWithFileGUIDS: languagesWithFileGUIDS
                }
            }
        case actionTypes.GET_TASK_SUCESS:
            return {
                ...state,
                task: action.payload
            }
        case actionTypes.DEL_TASK_SUCESS:
            return {
                ...state,
                task: null,
                success: {type: actionTypes.DEL_TASK_SUCESS}
            }
        case actionTypes.CLEAR_TASK_SUCESS:
            return {
                ...state,
                success: null
            }
        case actionTypes.CLEAR_TASK_ERROR:
            return {
                ...state,
                error: null
            }
        case actionTypes.TASK_VERIFY_FILES:
            return {
                ...state,
                filesVerification: action.payload,
                hasBeenVerified: true
            }
        case actionTypes.RESET_TASK_VERIFY_FILES:
            return {
                ...state,
                hasBeenVerified: false
            }
        case actionTypes.SAVE_TASK_AS_DRAFT:
            return {
                ...state,
                newTask: action.payload,
                hasBeenSaved: true
            }
        case actionTypes.SET_PAYMENT_DONE:
            return {
                ...state,
                hasBeenPayed: action.payload
            }
        case actionTypes.RESET_SAVE_TASK_AS_DRAFT:
            return {
                ...state,
                newTask: null,
                hasBeenSaved: false
            }
        case actionTypes.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                success: {type: actionTypes.UPDATE_STATUS_SUCCESS}
            }
        case actionTypes.UPDATE_STATUS_ERROR:
            return {
                ...state,
                error: {type: actionTypes.UPDATE_STATUS_ERROR}
            }
        case actionTypes.UPLOAD_TRANSLATED_FILE_SUCCESS:
            return {
                ...state,
                hasBeenUploadFiles: true
            }
        case actionTypes.UPLOAD_TRANSLATED_FILE_ERROR:
            return {
                ...state,
                error: {type: actionTypes.UPLOAD_TRANSLATED_FILE_ERROR}
            }
        case actionTypes.RESET_UPLOAD_TRANSLATED_FILE_SUCCESS:
            return {
                ...state,
                hasBeenUploadFiles: false
            }
        case actionTypes.REMOVE_TRANSLATED_FILE_SUCCESS:
            return {
                ...state,
                hasBeenDeleteFiles: true
            }
        case actionTypes.RESET_REMOVE_TRANSLATED_FILE_SUCCESS:
            return {
                ...state,
                hasBeenDeleteFiles: false
            }
        case actionTypes.REMOVE_TRANSLATED_FILE_ERROR:
            return {
                ...state,
                error: {type: actionTypes.REMOVE_TRANSLATED_FILE_ERROR}
            }
        case actionTypes.UPDATE_TASK_SUCESS:
            return {
                ...state,
                success: {type: actionTypes.UPDATE_TASK_SUCESS}
            }
        case actionTypes.SEND_TASK_TO_PRODUCTION:
            return {
                ...state,
                hasBeenSentToProduction: true
            }
        case actionTypes.SET_CANCELLED_TASK:
            return {
                ...state,
                hasBeenCancelled: true
            }
        case actionTypes.RESET_CANCELLED_TASK_STATUS:
            return {
                ...state,
                hasBeenCancelled: false
            }
        case actionTypes.SET_REACTIVATED_TASK:
            return {
                ...state,
                hasBeenReactivated: true
            }
        case actionTypes.RESET_REACTIVATED_TASK_STATUS:
            return {
                ...state,
                hasBeenReactivated: false
            }
        case actionTypes.SET_DELETED_TASK:
            return {
                ...state,
                hasBeenDeleted: true
            }
        case actionTypes.RESET_DELETED_TASK_STATUS:
            return {
                ...state,
                hasBeenDeleted: false
            }
        case actionTypes.RESET_SEND_TASK_TO_PRODUCTION:
            return {
                ...state,
                hasBeenSentToProduction: false
            }
        case actionTypes.SET_BUDGET_STATUS:
            return {
                ...state,
                hasBudgetBeenSent: true
            }
        case actionTypes.SET_DOWNLOADED_BUDGET:
            return {
                ...state,
                hasBudgetBeenDownloaded: true
            }
        case actionTypes.RESET_DOWNLOADED_BUDGET:
            return {
                ...state,
                hasBudgetBeenDownloaded: false
            }
        case actionTypes.RESET_BUDGET_STATUS:
            return {
                ...state,
                hasBudgetBeenSent: false
            }
        case actionTypes.SET_PRICES:
            return {
                ...state,
                prices: action.payload.prices,
                pricesSummary: action.payload.pricesSummary
            }
        default:
            return state;
    }
}

export default reducer;
