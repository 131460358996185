// ** React Imports
import React, { useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

// ** Icon Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// ** Components Imports
import LanguageSelector from "./LanguagesSelector";

// ** Store Imports
import * as userTypes from "../../store/reducers/auth/userTypes";

export default function Header() {
    // ** States
    const [isActive, setIsActive] = useState(false);

    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const loggedUser = useSelector(state => state.auth.user);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const locale = useSelector(state => state.locale.locale);
    const history = useHistory();
    const location = useLocation();

    const logout = () => {
        dispatch({type: "LOGOUT"});
        history.push("/login");
        toast.info(intl.formatMessage({id: "message.logout"}));
    }

    const openDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.target.closest(".dropdown").classList.add("is-active")
    }

    const goToLocation = (e, location, to) => {
        history.push({pathname: location, exact: true});
        dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: to}})
        setIsActive(false)
    }

    const Login = () => {
        if (loggedUser === null) {
            return (
                <Link className="navbar-item" to="/login">
                    <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile" icon={faUser} color="primary"/>
                    <FormattedMessage id="login"/>
                </Link>)
        } else {
            return (
                <>
                    <Link
                        className="navbar-item is-hidden-tablet"
                        onClick={event => goToLocation(event, "/account", "menu_cuenta")}
                        to="/account"
                    >
                        <FormattedMessage id="menu.my_account"/>
                    </Link>
                    {(userType === userTypes.CLIENT_ADMIN || userType === userTypes.CLIENT_USER) && (
                        <Link
                            className="navbar-item is-hidden-tablet"
                            onClick={event => goToLocation(event, "/company", "menu_company")}
                            to="/company"
                        >
                            <FormattedMessage id="menu.my_company"/>
                        </Link>
                    )}
                    <Link className="navbar-item is-hidden-desktop" onClick={logout} to="#">
                        <FormattedMessage id="logout"/>
                    </Link>

                    <div className="navbar-end">
                        <div id="dropdown" className="navbar-item dropdown is-right is-hidden-mobile"
                             onClick={(e) => openDropdown(e)}>
                            <div className="dropdown-trigger">
                                <div aria-controls="dropdown-menu" aria-haspopup="true" style={{cursor: 'pointer'}}>
                                    <a className="navbar-item" href="/">
                                        <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                         icon={faUser}
                                                         color="primary"/>
                                        {`${loggedUser.name} ${loggedUser.lastname}`}
                                    </a>
                                </div>
                            </div>
                            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a className="dropdown-item has-text-dark" href="/#" onClick={() => logout()}>
                                        <FormattedMessage id="logout"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
        }
    }

    const TasksMenu = () => (
        <div className="navbar-start" key="tasksMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/tasks", "menu_tareas")}
                to="/tasks"
            >
                <FormattedMessage id="menu.tasks"/>
            </Link>
        </div>
    )

    const GlossariesMenu = () => (
        <div className="navbar-start" key="glossariesMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/glossaries", "menu_glossaries")}
                to="/glossaries"
            >
                <FormattedMessage id="menu.glossaries"/>
            </Link>
        </div>
    )

    const ClientsMenu = () => (
        <div className="navbar-start" key="clientsMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/customers", "menu_clientes")}
                to="/customers"
            >
                <FormattedMessage id="menu.clients"/>
            </Link>
        </div>
    )

    const UsersMenu = () => (
        <div className="navbar-start" key="usersMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/users", "menu_usuarios")}
                to="/users"
            >
                <FormattedMessage id="menu.users"/>
            </Link>
        </div>
    )

    const DepartmentsMenu = () => (
        <div className="navbar-start" key="departmentsMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/departments", "menu_departamentos")}
                to="/departments"
            >
                <FormattedMessage id="menu.departments"/>
            </Link>
        </div>
    )

    const LanguagesMenu = () => (
        <div className="navbar-start" key="languagesMenu">
            <Link
                className="navbar-item brand-text is-hidden-tablet"
                href="#"
                onClick={event => goToLocation(event, "/languages", "menu_languages")}
                to="/languages"
            >
                <FormattedMessage id="menu.languages"/>
            </Link>
        </div>
    )

    const RatesMenu = () => {
        if (userType === userTypes.IDISC_ADMIN) return (
            <div className="navbar-start" key="ratesMenu">
                <Link
                    className="navbar-item brand-text is-hidden-tablet"
                    href="#"
                    onClick={event => goToLocation(event, "/general/rates", "menu_rates")}
                    to="/general/rates"
                >
                    <FormattedMessage id="menu.rate_menu"/>
                </Link>
            </div>
        );
        else return (
            <div className="navbar-start" key="ratesMenu">
                <Link
                    className="navbar-item brand-text is-hidden-tablet"
                    href="#"
                    onClick={event => goToLocation(event, "/rates", "menu_tarifas")}
                    to="/rates"
                >
                    <FormattedMessage id="menu.rates"/>
                </Link>
            </div>
        );
    }

    const Menu = () => {
        if (userType === userTypes.CLIENT_USER) return (TasksMenu());
        else if (userType === userTypes.CLIENT_ADMIN) {
            if (loggedUser?.customer?.show_rates)
                return ([TasksMenu(), GlossariesMenu(), RatesMenu(), UsersMenu(), DepartmentsMenu()]);
            else return ([TasksMenu(), GlossariesMenu(), UsersMenu(), DepartmentsMenu()]);
        } else if (userType === userTypes.IDISC_ADMIN) return ([TasksMenu(), ClientsMenu(), LanguagesMenu(), RatesMenu()]);
        else if (userType === userTypes.IDISC_PM) return ([TasksMenu(), ClientsMenu()]);
    }

    return (
        <>
            {(location.pathname === "/quote" || location.pathname === "/quote/registered") ? (
                <header className="header-fixed">
                    <nav className="navbar is-light has-background-white-ter">
                        <div className="container">
                            <div className="is-flex is-flex-grow-1 is-justify-content-flex-end">
                                <LanguageSelector/>
                            </div>
                        </div>
                    </nav>
                    <nav className="navbar px-4">
                        <div className="container pt-3">
                            <div className="navbar-brand">
                                <a href={`https://www.idisc.com/${locale.name}`}>
                                    <img src="/logo.svg" alt="Logo" height={75}/>
                                </a>
                            </div>
                        </div>
                    </nav>
                </header>
            ) : (
                <nav className="navbar is-light">
                    <div className="container">
                        <div className="navbar-brand">
                            <a href="/" className="navbar-item">
                                <img src="/logo.png" alt="Logo"/>
                            </a>
                            <Link
                                className="navbar-item brand-text ml-4"
                                href="/"
                                to="/"
                                onClick={() => dispatch({
                                    type: 'CHANGE_MENU',
                                    payload: { from: "menu", to: "menu_tareas" }
                                })}
                            >
                                <h4 className="title is-4"><FormattedMessage id="app.name"/></h4>
                            </Link>
                            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                            <a
                                aria-label='menu'
                                aria-expanded='false'
                                className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
                                data-target='navbarBasicExample'
                                href='#'
                                onClick={() => setIsActive(!isActive)}
                                role='button'
                            >
                                <span aria-hidden='true'/>
                                <span aria-hidden='true'/>
                                <span aria-hidden='true'/>
                            </a>
                        </div>
                        <div id="navMenu" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                            {Menu()}
                            <div className="navbar-end">
                                {loggedUser === null && (
                                    <LanguageSelector/>
                                )}
                                {Login()}
                            </div>
                        </div>
                    </div>
                </nav>
            )}
        </>
    );
}
