// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

// ** Icon Imports
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ** Store Imports
import { getCountries, getCustomer, getRate, updateCustomer } from "../../store/reducers/customers/customersActions";
import { createBonus, getBonuses } from "../../store/reducers/bonus/bonusActions";
import { disableUser, getUsersFromCustomer } from "../../store/reducers/user/userActions";
import * as userTypes from "../../store/reducers/auth/userTypes";

// ** Actions Import
import * as actions from "../../store/Actions";

// ** Components Imports
import OnlyReadInput from "../../components/Form/OnlyReadInput";
import CustomerAssignsProjects from "./AssignProject";
import RatesEnabledTable from '../../components/Customer/RatesEnabledTable';
import CustomerRatesEnabledTable from "../../components/Customer/CustomerRatesEnabledTable";
import Datatable from "../../components/Datatable/Datatable";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";

let EditCustomer = props => {
    const id = props.match.params.id;

    // ** States
    const [availableCountries, setAvailableCountries] = useState([]);
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const { register, errors, handleSubmit } = useForm({mode: 'onChange'});
    const customer = useSelector(state => state.customer_assing.customer);
    const bonuses = useSelector(state => state.bonus.bonuses);
    const users = useSelector(state => state.user.users);
    const message = useSelector(state => state.customer_assing.message);
    const error = useSelector(state => state.customer_assing.error);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const hasUserBeenDeleted = useSelector(state => state.user.userDeleted);
    const countries = useSelector(state => state.customer_assing.countries);
    const rates = useSelector(state => state.customer_assing.rates);
    const locale = useSelector(state => state.locale.locale);
    const { dateFormat } = useDate();
    const price = usePrice();

    useEffect(() => {
        if (userType === userTypes.IDISC_ADMIN) {
            dispatch(getCustomer(id));
            dispatch(getBonuses({ customer_id: id }));
            dispatch(getUsersFromCustomer(id));
            dispatch(getRate(id));
        }
        dispatch(getCountries());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countries.length > 0) setAvailableCountries(countries
            .map(country => ({label: intl.formatMessage({id: `country.${country.code}`}), value: country.id}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    useEffect(() => {
        if (hasUserBeenDeleted) {
            dispatch({type: 'TOGGLE_MODAL'});
            toast.success(intl.formatMessage({id: "message.user_deleted"}));
            dispatch({type: actions.USER_DELETED, payload: {status: false}})
            dispatch(getUsersFromCustomer(id));
        }
        if (error) {
            switch (error.type) {
                default:
                    toast.error(intl.formatMessage({id: "error.general"}));
            }
            dispatch({type: "CLEAR_ERROR"});
        } else if (message) {
            switch (message.type) {
                case "UPDATE_CUSTOMER_SUCCESS":
                    toast.success(intl.formatMessage({id: "customer.success.updateCustomer"}));
                    let button = document.querySelector("#updateCustomer");
                    button.classList.remove("is-loading");
                    button.disabled = false;
                    break;
                case "DELETE_CUSTOMER_SUCCESS":
                    history.push("/customers");
                    toast.success(intl.formatMessage({id: "customer.success.delete"}));
                    break;
                case actions.DELETE_RATE_SUCCESS:
                    dispatch(getRate(id));
                    dispatch({type:actions.HIDE_MODAL});
                    toast.success(intl.formatMessage({id:"customer.rate.success.delete"}));
                    break;
                case "UPDATE_RATE":
                    dispatch(getRate(id));
                    toast.success(intl.formatMessage({id:"rate.success.update"}));
                    dispatch({type:"TOGGLE_MODAL"});
                    break;
                case "CREATE_RATE":
                    dispatch(getRate(id));
                    toast.success(intl.formatMessage({id:"rate.success.create"}));
                    dispatch({type:"TOGGLE_MODAL"});
                    break;
                default:
            }
            dispatch({type: "CLEAR_SUCCESS_MESSAGE"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenDeleted, error, message]);

    const columns = [
        {
            name: intl.formatMessage({id: "invoice.amount"}),
            selector: row => `${price(row.amount, locale.name)} €`,
            right: true
        },
        {
            name: intl.formatMessage({id: "general.date"}),
            selector: row => dateFormat(row.created_at, locale.name)
        }
    ];

    const handleCustomerSubmit = () => {
        const button = document.querySelector("#updateCustomer");
        button.classList.add("is-loading");
        button.disabled = true;
        dispatch(updateCustomer({
            id: customer.id,
            name: customer.name,
            is_active: customer.is_active,
            customer_number: customer.customer_number,
            payment_scheme: customer.payment_scheme,
            address: customer.address,
            address_2: customer.address_2,
            town: customer.town,
            province: customer.province,
            postal_code: customer.postal_code,
            tin: customer.tin,
            success_representative: customer.success_representative,
            success_representative_email: customer.success_representative_email,
            show_rates: customer.show_rates,
            country_id: customer.country_id,
        }));
    }

    const handleChange = (value, name) => {
        dispatch({type: "CHANGE_CUSTOMER", payload: {...customer, [name]: value}});
    }

    const onSubmitBonus = event => {
        event.preventDefault();
        setLoading(true);
        dispatch(createBonus({ amount, customer_id: id })).finally(() => {
            dispatch(getBonuses({ customer_id: id }));
            dispatch(getCustomer(id));
            setAmount('');
            setLoading(false)
        });
    }

    function showUser(user) {
        history.push({pathname: "/users/" + user.id, exact: true, state: {from: "customer", customer_id: id}});
        dispatch({type: "SET_SELECTED_USER", payload: user});
    }

    const showConfirmation = (e, user) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "message_title.delete"}),
                message: intl.formatMessage({id: "message.delete"}),
                acceptButton: intl.formatMessage({id: "general.delete"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => {
                    deleteUser(user.id)
                },
            }
        })
    }

    const deleteUser = (user) => {
        dispatch({type: 'TOGGLE_MODAL'})
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: "loading",
            }
        })
        dispatch(disableUser(user));
    }

    function createUser(e) {
        e.preventDefault();
        history.push({pathname: "/users/create", exact: true, state: {from: "customer", customer_id: id}});
    }

    const editView = () => {
        return (
            <div className="box is-desktop">
                <div className="columns is-mobile">
                    <nav className="column is-11 breadcrumb has-arrow-separator is-medium mb-6"
                         aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link href="#" to="/customers" className="title is-4">
                                    <FormattedMessage id="menu.clients"/>
                                </Link>
                            </li>
                            <li className="is-active title is-4">
                                <a aria-current="page" className="has-text-primary" href="/#">
                                    {customer?.name ?? (props.location.state?.customer_name ?? "")}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <hr></hr>
                {userType === userTypes.IDISC_ADMIN && customer ? (
                    <section>
                        <div className="p-4 is-desktop">
                            <form onSubmit={handleSubmit(handleCustomerSubmit)}>
                                <div className="columns">
                                    <div className="column">
                                        <OnlyReadInput label="customer.id" fieldValue={customer.id}/>
                                    </div>
                                </div>
                                <div className="columns is-desktop">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label"><FormattedMessage id="customer.name"/></label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.name}
                                                    name="customer_name"
                                                    onChange={event => handleChange(event.target.value, 'name')}
                                                    ref={register({required: true, maxLength: 255})}
                                                />
                                                {errors.customer_name && errors.customer_name.type === "required" && (
                                                    <p className="error">{intl.formatMessage({id: "customer.required_camp"})}</p>)}
                                                {errors.customer_name && errors.customer_name.type === "maxLength" && (
                                                    <p className="error">{intl.formatMessage({id: "customer.maxLength"})}</p>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="customer.number"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.customer_number}
                                                    name="customer_number"
                                                    onChange={event =>
                                                        handleChange(event.target.value, 'customer_number')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.address"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.address}
                                                    name="address"
                                                    onChange={event => handleChange(event.target.value, 'address')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.address"/> 2
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.address_2}
                                                    name="address_2"
                                                    onChange={event => handleChange(event.target.value, 'address_2')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.town"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.town}
                                                    name="town"
                                                    onChange={event => handleChange(event.target.value, 'town')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.province"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.province}
                                                    name="province"
                                                    onChange={event => handleChange(event.target.value, 'province')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <label className="label">
                                            <FormattedMessage id="general.country"/>
                                        </label>
                                        <div className="select is-fullwidth">
                                            <select
                                                name="country"
                                                value={customer.country_id}
                                                onChange={event => handleChange(event.target.value, 'country_id')}
                                            >
                                                {availableCountries.map(country => (
                                                    <option key={country.value} value={country.value}>
                                                        {country.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.postal_code"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.postal_code}
                                                    name="postal_code"
                                                    onChange={event => handleChange(event.target.value, 'postal_code')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="general.tin"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.tin}
                                                    name="tin"
                                                    onChange={event => handleChange(event.target.value, 'tin')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="customer.success_representative"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.success_representative}
                                                    name="success_representative"
                                                    onChange={event =>
                                                        handleChange(event.target.value, 'success_representative')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">
                                                <FormattedMessage id="customer.success_representative_email"/>
                                            </label>
                                            <div className="control">
                                                <input
                                                    className="input"
                                                    defaultValue={customer.success_representative_email}
                                                    name="success_representative_email"
                                                    onChange={event =>
                                                        handleChange(event.target.value, 'success_representative_email')
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <label className="label">
                                                    <input
                                                        checked={(customer.payment_scheme ?? "ON_DEMAND") === "ON_DEMAND"}
                                                        className="mr-1"
                                                        name="paymentType"
                                                        onChange={event => handleChange(
                                                            event.target.checked ? "ON_DEMAND" : "DEFERRED",
                                                            'payment_scheme'
                                                        )}
                                                        type="checkbox"
                                                    />
                                                    <FormattedMessage id="customer.advance_payment"/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <label className="label">
                                                    <input
                                                        checked={!!customer.is_active}
                                                        className="mr-1"
                                                        name="answer"
                                                        onChange={event =>
                                                            handleChange(event.target.checked, 'is_active')
                                                        }
                                                        type="checkbox"
                                                    />
                                                    <FormattedMessage id="customer.active"/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <label className="label">
                                                    <input
                                                        checked={!!customer.show_rates}
                                                        className="mr-1"
                                                        name="rates"
                                                        onChange={event =>
                                                            handleChange(event.target.checked, 'show_rates')
                                                        }
                                                        type="checkbox"
                                                    />
                                                    <FormattedMessage id="customer.show_rates"/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <OnlyReadInput
                                            label="customer.available_balance"
                                            fieldValue={`${price(customer.balance ?? 0, locale.name)} €`}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="columns">
                                    <div className="is-flex column is-3">
                                        <button id="updateCustomer" className="button is-info" type="submit">
                                            <FormattedMessage id="general.update"/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr/>
                        <div className="columns is-mobile">
                            <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                                <ul>
                                    <li className="is-active title is-4 has-text-primary">
                                        <FormattedMessage id="customer.bonuses"/>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <hr/>
                        <div className="p-4 is-desktop">
                            <div className="columns">
                                <div className="column is-half">
                                    <form onSubmit={onSubmitBonus}>
                                        <div className="field has-addons">
                                            <div className="control is-expanded">
                                                <input
                                                    className="input"
                                                    max={999999.99}
                                                    min={-999999.99}
                                                    name="balance"
                                                    onChange={event => setAmount(event.target.value)}
                                                    step={0.01}
                                                    type="number"
                                                    value={amount}
                                                />
                                            </div>
                                            <div className="control">
                                                <button
                                                    className={`button is-info${loading ? " is-loading" : ""}`}
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="general.add"/>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <Datatable columns={columns} data={bonuses} pointerOnHover={false}/>
                        </div>
                        <hr/>
                        <div className="columns is-mobile">
                            <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                                <ul>
                                    <li className="is-active title is-4 has-text-primary">
                                        <FormattedMessage id="customer.users"/>
                                    </li>
                                </ul>

                            </nav>
                        </div>
                        <hr/>
                        <div className="p-4 is-desktop">
                            <div className="level-right">
                                <div className="level-item">
                                    <button className="button is-outlined is-primary" onClick={(e) => createUser(e)}>
                                        <FormattedMessage id="user.add_user"/></button>
                                </div>
                            </div>
                            <div className="table-container">
                                <table id="users-list" className="table is-fullwidth is-hoverable">
                                    <thead>
                                    <tr>
                                        <th className=" is-half"><FormattedMessage id="general.name"/></th>
                                        <th className="is-2"><FormattedMessage id="user.lastname"/></th>
                                        <th className="is-2"><FormattedMessage id="general.type"/></th>
                                        <th className="is-2"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map(user => (
                                        <tr key={user.id} onClick={() => showUser(user)} style={{cursor: "pointer"}}>
                                            <td>{user.name}</td>
                                            <td>{user.lastname}</td>
                                            <td><FormattedMessage id={`type.${user.type}`}/></td>
                                            <td>
                                                <button
                                                    className="button is-small is-danger"
                                                    title={intl.formatMessage({id: "general.delete"})}
                                                    onClick={(e) => {
                                                        showConfirmation(e, user)
                                                    }}>
                                                    <span className="icon">
                                                        <FontAwesomeIcon className="" icon={faTrash}/>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr/>
                    </section>
                ) : null}
                <div className="columns is-mobile">
                    <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                        <ul>
                            <li className="is-active title is-4 has-text-primary"><FormattedMessage
                                id="customer.assignProjects"/></li>
                        </ul>

                    </nav>
                </div>
                <hr/>

                <div className="p-4 is-desktop">
                    <CustomerAssignsProjects id={id}/>
                </div>
                {userType === userTypes.IDISC_ADMIN ? (<section>
                    <div className="columns is-mobile">
                        <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                            <ul>
                                <li className="is-active title is-4 has-text-primary">
                                    <FormattedMessage id="customer.general_rates"/>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <hr></hr>
                    <div className="p-4 is-desktop">
                        <RatesEnabledTable id={id} number={customer?.customer_number ?? ""} customer_id={customer?.id ?? ""}/>
                        <p className="help block"><FormattedMessage id={"customer.rates.helpTest"}/></p>
                    </div>

                    <hr/>
                    <div className="columns is-mobile">
                        <nav className="column is-11 mt-3" aria-label="breadcrumbs">
                            <ul>
                                <li className="is-active title is-4 has-text-primary">
                                    <FormattedMessage id="customer.custom_rates"/>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <hr></hr>
                    <div className="p-4 is-desktop">
                        <CustomerRatesEnabledTable rates={rates} customer_id={customer?.id ?? ""}/>
                    </div>
                </section>) : null}
            </div>
        );
    }

    return editView();
}

export default connect()(EditCustomer);
