import React from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';
import CustomersAssignProject from "./../../containers/Customers/AssignProject";

let ModalCustomerAssignsProjects = (props) =>{
    const id = props.extraData.idCustomer;


    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }></button>
                </header>

                <section className="modal-card-body">
                    <CustomersAssignProject id={id}/>
                </section>

                <footer className="modal-card-foot">
                    <div className="h-36"></div>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalCustomerAssignsProjects);