import React from "react";
import ModalHOC from "../../hoc/Modal/ModalHOC";

function ModalTaskCreated(props) {

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Confirmación de la tarea</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }/>
                </header>

                <section className="modal-card-body">
                    <p>¡La tarea ha sido creada existosamente!</p>
                </section>

                <footer className="modal-card-foot">
                    <button className={`button ${props.acceptButtonClass}`} onClick={ props.acceptButtonMethod }>{props.acceptButton}</button>
                    <button className={`button ${props.cancelButtonClass}`} onClick={ props.closeModal }>{props.cancelButton}</button>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalTaskCreated)
