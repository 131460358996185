import React from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';
import {useIntl} from "react-intl";

function ModalConnectionError(props) {
    const intl = useIntl();

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{intl.formatMessage({id: "error.connection_error_title"})}</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }/>
                </header>

                <section className="modal-card-body">
                    <div>
                        {intl.formatMessage({id: "error.connection_error"})}
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className="button is-danger" onClick={props.closeModal}>{intl.formatMessage({id: "general.accept"})}</button>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalConnectionError);
