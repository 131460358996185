import axios from 'axios';
import * as action from "../../store/Actions";
import * as ErrorCodes from '../../hoc/Axios/ErrorCodes';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
});

export const interceptor = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.Authorization = (store.getState().auth.token) ? `Bearer ${store.getState().auth.token}` : '';
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (next) => {
            return Promise.resolve(next);
        },
        (error) => {
            if (!error.response || error.response.status === 400 ) {
                store.dispatch({type: action.HIDE_MODAL});
                store.dispatch({
                    type: action.SHOW_MODAL,
                    payload: {
                        modalType: "connection_error",
                    }
                });
                error = {response: {data: {error: "connection_error"}}}
                return Promise.reject(error);
            } else if(error.response.status === 401){
                if(error.response.data.error_code === ErrorCodes.BAD_CREDENTIALS_ERROR){
                    store.dispatch({type: action.BAD_CREDENTIALS});
                } else if(error.response.data.error_code === ErrorCodes.INACTIVE_USER){
                    store.dispatch({type: action.HIDE_MODAL});
                    store.dispatch({
                        type: action.SHOW_MODAL,
                        payload: {
                            modalType: "inactive_user",
                        }
                    });
                }
                else {
                    store.dispatch({type: action.HIDE_MODAL});
                    store.dispatch({
                        type: action.SHOW_MODAL,
                        payload: {
                            modalType: "session_expired",
                        }
                    });
                }
                return Promise.reject(error);
            } else if(error.response.status === 404){
                window.location.href = '/404?url=' + window.location.href;
                store.dispatch({type: action.HIDE_MODAL});
                store.dispatch({
                    type: action.SHOW_MODAL,
                    payload: {
                        modalType: "not_found",
                    }
                });

                return Promise.reject(error);
            }
            else if(error.response.status === 422){
                store.dispatch({type: action.HIDE_MODAL});
                store.dispatch({
                    type: action.SHOW_MODAL,
                    payload: {
                        modalType: "validation_error",
                        extraData: error.response.data.errors,
                    }
                });

                return Promise.reject(error);
            }
            else if(error.response.status >= 500){
                store.dispatch({type: action.HIDE_MODAL});
                store.dispatch({
                    type: action.SHOW_MODAL,
                    payload: {
                        modalType: "connection_error",
                    }
                });
                return Promise.reject(error);
            }
            else {
                return Promise.reject(error);
            }
        }
    );
}
