import React from "react";
import {Link} from "react-router-dom";

export default function Breadcrumbs(props) {
    const generateLinks = () => {
        let li = [];
        if (Array.isArray(props.destination)) props.destination.forEach((destination, index) => li.push(
            <li key={index}>
                <Link className="title is-4" href="#" to={{ pathname: destination, state: props.state }}>
                    {props.labels[index]}
                </Link>
            </li>
        ));
        else li = (
            <li>
                <Link className="title is-4" href="#" to={{ pathname: props.destination, state: props.state }}>
                    {props.destinationTitle}
                </Link>
            </li>
        );

        return li;
    }

    return (
        <nav className="column is-11 breadcrumb has-arrow-separator is-medium mb-3" aria-label="breadcrumbs">
            <ul>
                {generateLinks()}
                <li className="is-active title is-4">
                    <a aria-current="page" className="has-text-primary" href="/#">
                        {props.title}
                    </a>
                </li>
            </ul>
        </nav>
    )
}
