import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actionTypes from "../../Actions";
import {SET_CUSTOMER_DISCOUNT} from "../../Actions";

export const getCustomers = (page = 1) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers", {
            params: {
                page: page
            }
        })
            .then(res => {
                let responseFromServer = res.data;
                dispatch({type: "RETRIVE_CUSTOMERS_SUCCESS", payload: responseFromServer.data});
            }).catch(error => {
                dispatch({type: "RETRIVE_CUSTOMERS_ERROR", payload: error});
            })
    }
}

export const getCustomer = (idCustomer) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers/" + idCustomer)
            .then(res => {
                dispatch({type: "RETRIVE_CUSTOMER_SUCCESS", payload: res.data});
            }).catch(error => {
                dispatch({type: "RETRIVE_CUSTOMER_ERROR", payload: error});
            })
    }
}

export const deleteCustomerAction = (idCustomer) => {
    return (dispatch, getState) => {
        return axiosInstance.delete("/customers/" + idCustomer)
            .then(res => {
                dispatch({type: "DELETE_CUSTOMER_SUCCESS", payload: res.data});
            }).catch(error => {
                dispatch({type: "DELETE_CUSTOMER_ERROR", payload: error});
            })
    }
}

export const getCustomerAdminUsers = (idCustomer) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers/" + idCustomer + "/admin/users")
            .then(res => {
                dispatch({type: "RETRIVE_CUSTOMER_USERS_SUCCESS", payload: res.data});
            }).catch(error => {
                dispatch({type: "RETRIVE_CUSTOMER_USERS_ERROR", payload: error});
            })
    }
}

export const storeCustomer = (customer) => {
    return (dispatch, getState) => {
        return axiosInstance.post("/customers/store", customer)
            .then(res => {
                dispatch({type: "SAVE_CUSTOMER_SUCCESS"});
            }).catch(error => {
                dispatch({type: "SAVE_CUSTOMER_ERROR", payload: error.response.data});
            })
    }
}

export const updateCustomer = (customer) => {
    return (dispatch, getState) => {
        return axiosInstance.put("/customers/" + customer.id, customer)
            .then(res => {
                dispatch({type: "UPDATE_CUSTOMER_SUCCESS"});
            }).catch(error => {
                dispatch({type: "UPDATE_CUSTOMER_ERROR", payload: error});
            })
    }
}

export const getLanguages = (user) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/languages")
            .then(res => {
                let options = [];
                res.data.data.forEach(function (v, i) {
                    options.push({value: v.id, label: v.iso_code})
                });

                dispatch({type: "RETRIVE_LANGUAGES_SUCCESS", payload: options});
            }).catch(error => {
                dispatch({type: "RETRIVE_LANGUAGES_ERROR", payload: error});
            })
    }
}

export const sendCustomerProyectAssign = (assigns, id) => {
    return (dispatch, getState) => {
        return axiosInstance.post("/customers/" + id + "/assigns", assigns)
            .then(res => {
                dispatch({type: "ASSIGN_PROJECTS_SUCCESS"});
            }).catch(error => {
                dispatch({type: "ASSIGN_PROJECTS_ERROR", payload: error});
            })
    }
}

export const getMemoQ = (id) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers/" + id + "/MemoQ")
            .then(res => {
                let values = [];
                res.data.data.memoq_proyects.forEach(function (v, i) {
                    values.push({
                        id: v.id, memoQP: v.TM_name, langs: [
                            {value: v.source_language.id, label: v.source_language.iso_code},
                            {value: v.target_language.id, label: v.target_language.iso_code}
                        ]
                    });
                });
                dispatch({type: "RETRIVE_MEMOQ_SUCCESS", payload: values});
            }).catch(error => {
                dispatch({type: "RETRIVE_MEMOQ_ERROR", payload: error});
            })
    }
}

export const getIDCP = (id) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers/" + id + "/IDCP")
            .then(res => {
                dispatch({type: "RETRIVE_IDCP_SUCCESS", payload: res.data});
            }).catch(error => {
                dispatch({type: "RETRIVE_IDCP_ERROR", payload: error});
            })
    }
}

export const getMemoQProjectId = id => dispatch => axiosInstance.get(`/customers/${id}/MemoQProjectId`)
    .then(response => {
        dispatch({type: "RETRIVE_MEMOQ_PROJECT_ID_SUCCESS", payload: response.data.data});
    })
    .catch(error => {
        dispatch({type: "RETRIVE_MEMOQ_PROJECT_ID_ERROR", payload: error});
    });

export const getRate = (id, data) => dispatch =>
    axiosInstance.get(`/customers/${id}/rate`, { params: data })
        .then(res => {
            dispatch({ type: "GET_RATE_SUCCESS", payload: res.data });
        })
        .catch(error => {
            dispatch({ type: "GET_RATE_ERROR", payload: error });
        });

export const getRateEnable = (id, data) => dispatch =>
    axiosInstance.get(`/customers/${id}/enable/rate`, { params: data })
        .then(response => {
            dispatch({ type: "GET_RATE_ENABLE_SUCCESS", payload: response.data });
        })
        .catch(error => {
            dispatch({ type: "GET_RATE_ENABLE_ERROR", payload: error });
        });

export const uploadExcel = (id, data, ismodal) => {
    return (dispatch, getState) => {
        return axiosInstance.post("/customers/" + id + "/rate", data)
            .then(res => {
                dispatch({type: "UPLOAD_EXCEL_SUCCESS"});
            }).catch(error => {
                if (!ismodal) {
                    dispatch({type: "UPLOAD_EXCEL_ERROR", payload: error});
                }
            })
    }
}

export const downloadExcelCustomer = (id) => {
    return (dispatch, getState) => {
        return axiosInstance.get("customers/" + id + "/excel/rate", {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'excel.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                let button = document.querySelector("#downloadExcelFile");
                button.classList.remove("is-loading");
                button.disabled = false;
            }).catch(error => {
            })
    }
}


export const getCountries = () => {
    return (dispatch) => {
        return axiosInstance.get("/countries")
            .then(res => {
                dispatch({type: actionTypes.GET_COUNTRIES, payload: res.data.data});
            }).catch(error => {
            })
    }
}

export const enableRateCustomer = (idRate,customer_id) => {
    return (dispatch) => {
        return axiosInstance.post("/customers/" + customer_id + "/rate/" + idRate)
            .then(res => {
                dispatch({type: "ENABLE_RATE_SUCCESS"});
            }).catch(error => {
            })
    }
}

export const deleteRate = (idRate,customer_id) => {
    return (dispatch) => {
        return axiosInstance.delete("/customers/" + idRate + "/rate/" + idRate)
            .then(res => {
                dispatch({type: actionTypes.DELETE_RATE_SUCCESS});
            }).catch(error => {
            })
    }
}

export const deleteEnableRate = (idRate,customer_id) => {
    return (dispatch) => {
        return axiosInstance.delete("/customers/" + customer_id + "/enable/rate/" + idRate)
            .then(res => {
                dispatch({type: actionTypes.DELETE_ENABLE_RATE_SUCCESS});
            }).catch(error => {
            })
    }
}

export const getCustomerDiscount = (idCustomer) => {
    return (dispatch, getState) => {
        return axiosInstance.get("/customers/" + idCustomer + "/discount")
            .then(res => {
                dispatch({type: SET_CUSTOMER_DISCOUNT, payload: res.data.data.discount});
            }).catch(error => {
                dispatch({type: "RETRIVE_CUSTOMER_ERROR", payload: error});
            })
    }
}
