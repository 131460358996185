import * as actionTypes from '../../Actions';

const initialState = {
    temporalTask: null,
    form: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TEMPORAL_TASK:
            return {
                ...state,
                temporalTask: action.payload
            }
        case actionTypes.PAY_TEMPORAL_TASK:
            return {
                ...state,
                form: action.payload.form
            }
        default:
            return state;
    }
}

export default reducer;
