import React, {useEffect} from "react";
import RatesTable from "./RatesTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import * as actionTypes from "../../store/Actions";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {getRateEnable} from "../../store/reducers/customers/customersActions";
import {toast} from "react-toastify";
import * as action from "../../store/Actions";

let RatesEnabledTable = (props) => {
    const rates = useSelector(state => state.customer_assing.generalRates);
    const message = useSelector(state => state.customer_assing.message);
    const error = useSelector(state => state.customer_assing.error);
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getRateEnable(props.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if (error){
            dispatch({type:"CLEAR_ERROR"});
        }

        if (message){
            switch (message.type){
                case action.DELETE_ENABLE_RATE_SUCCESS:
                    dispatch(getRateEnable(props.id));
                    dispatch({type:action.HIDE_MODAL});
                    toast.success(intl.formatMessage({id:"customer.rate.success.delete"}));
                    break;
                default:
            }
            dispatch({type:"CLEAR_SUCCESS_MESSAGE"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error,message]);

    const enableRate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
            type: actionTypes.SHOW_MODAL,
            payload: {
                modalType: "enableRate",
                title: intl.formatMessage({id:"rate.enabled"}),
                extraData:{customer_id:props.customer_id}
            }
        })
    }

    return (
        <div className="table-container">
            <div className="button-container">
                <button id="enableRate" className="button is-outlined is-primary" onClick={(e)=>{enableRate(e);}}>
                    <FontAwesomeIcon className="" icon={faPlus}/>
                    <span className="ml-3">{intl.formatMessage({id:"rate.enabled"})}</span>
                </button>
            </div>
            <RatesTable rates={rates} customer_id={props.id} />
        </div>
    );
}

export default RatesEnabledTable;
