// ** React Imports
import React, { useState } from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { createPayTaskHistory } from "../../store/reducers/payTaskHistory/payTaskHistoryActions";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalPayment = props => {
    // ** States
    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");

    // ** Hooks
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);

    const onSubmit = event => {
        event.preventDefault();
        dispatch(createPayTaskHistory({ amount, type, task_id: props.extraData?.task_id, lang: locale.name }));
    }

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="is-desktop">
                        <form onSubmit={onSubmit}>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">
                                            <FormattedMessage id="invoice.amount"/>
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input"
                                                maxLength={8}
                                                onChange={event => setAmount(event.target.value)}
                                                value={amount}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">
                                            <FormattedMessage id="task.payment_method"/>
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input"
                                                maxLength={255}
                                                onChange={event => setType(event.target.value)}
                                                value={type}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="button is-info floatingButton" type="submit">
                                <FormattedMessage id="general.upload"/>
                            </button>
                        </form>
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <div className="h-36"></div>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalPayment);
