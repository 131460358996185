// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

// ** Icons Imports
import { faInfo, faQuestionCircle, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import { getTask, saveTaskAsDraft, updateTask } from "../../../store/reducers/task/taskActions";
import { getCustomerDiscount } from "../../../store/reducers/customers/customersActions";
import { getCurrentUserDepartments } from "../../../store/reducers/auth/authActions";
import { getAvailableLanguages } from "../../../store/reducers/language/languageActions";
import { getTranslationTypes } from "../../../store/reducers/translationType/translationTypeActions";

// ** Actions Import
import * as Actions from "../../../store/Actions";

// ** Custom Components
import Breadcrumbs from "../../../components/UI/Breadcrumbs";
import Tooltip from "../../../components/UI/Tooltip";
import Datatable from "../../../components/Datatable/Datatable";
import OnlyReadInput from "../../../components/Form/OnlyReadInput";

// ** File Types
import { FILE_TYPES } from "../../../utils/enums/FileTypes";
import * as actions from "../../../store/Actions";

const CreateTaskVerifyFiles = props => {
    const id = props.match.params.id;

    // ** States
    const [availableSourceLanguages, setAvailableSourceLanguages] = useState([]);
    const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]);
    const [name, setName] = useState("");
    const [department, setDepartment] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [targetLanguages, setTargetLanguages] = useState([]);
    const [type, setType] = useState(null);
    const [files, setFiles] = useState([]);
    const [customQuote, setCustomQuote] = useState(false);
    const [comments, setComments] = useState("");
    const [errors, setErrors] = useState({});

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const user = useSelector(state => state.auth.user);
    const applyDiscount = useSelector(state => state.customer_assing.applyDiscount);
    const departments = useSelector(state => state.auth.currentUserDepartments);
    const availableLanguages = useSelector(state => state.language.availableLanguages);
    const translationTypes = useSelector(state => state.translationType.translationTypes);
    const hasBeenSaved = useSelector(state => state.task.hasBeenSaved);
    const newTask = useSelector(state => state.task.newTask);
    const locale = useSelector(state => state.locale.locale);
    const task = useSelector(state => state.task.task);
    const success = useSelector(state => state.task.success);

    useEffect(() => {
        dispatch(getCurrentUserDepartments());
        dispatch(getAvailableLanguages());
        dispatch(getCustomerDiscount(user.customer_id));
        dispatch(getTranslationTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id) dispatch(getTask(id));
        else dispatch({type: Actions.GET_TASK_SUCESS, payload: null});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (departments.length > 0) {
            if (id) {
                if (task) setDepartment(task.department_id);
            } else setDepartment(departments[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments, task]);

    useEffect(() => {
        if (availableLanguages.length > 0) {
            const sourceLanguages = [...new Map(availableLanguages.map(language => [
                language.source_language.iso_code,
                {
                    label: intl.formatMessage({id: `language.${language.source_language.iso_code}`}),
                    value: language.source_language.iso_code
                }
            ])).values()].sort((a, b) => a.label.localeCompare(b.label));
            let sourceLanguage = null;
            setAvailableSourceLanguages(sourceLanguages);
            if (id) {
                if (task) {
                    sourceLanguage = task.source_language.iso_code;
                    setTargetLanguages(task.target_languages.map(language => ({
                        label: intl.formatMessage({id: `language.${language.target_language.iso_code}`}),
                        value: language.target_language.iso_code
                    })));
                }
            } else sourceLanguage = sourceLanguages[0].value;
            if (sourceLanguage) changeLanguages(sourceLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableLanguages, task]);

    useEffect(() => {
        if (translationTypes.length > 0) {
            if (id) {
                if (task) setType(task.translation_type_id);
            } else setType(translationTypes.find(type => type.id === 2)?.id ?? translationTypes[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translationTypes, task]);

    useEffect(() => {
        if (id && task) {
            if (task.description) setName(task.description);
            if (task.custom_quote) setCustomQuote(task.custom_quote);
            if (task.comments) setComments(task.comments);
            setFiles(task.source_files.map(file => ({ name: file.file_name, path: file.file_path })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task]);

    useEffect(() => {
        if (newTask && hasBeenSaved) {
            const id = newTask.id;
            dispatch({type: Actions.RESET_SAVE_TASK_AS_DRAFT});
            dispatch({type: Actions.HIDE_MODAL});
            history.push(`/tasks/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newTask, hasBeenSaved]);

    useEffect(() => {
        if (success) {
            dispatch({type: Actions.CLEAR_TASK_SUCESS});
            dispatch({type: Actions.HIDE_MODAL});
            history.push(`/tasks/${id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    const columns = [
        {
            name: intl.formatMessage({id: "task.fileName"}),
            selector: "name",
            sortable: true,
        },
        {
            name: "",
            selector: row => (
                <span className="icon has-text-link is-clickable">
                    <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                            dispatch({
                                type: Actions.SHOW_MODAL,
                                payload: {
                                    title: intl.formatMessage({id: "message_title.delete"}),
                                    message: intl.formatMessage({id: "message.delete"}),
                                    acceptButton: intl.formatMessage({id: "general.delete"}),
                                    cancelButton: intl.formatMessage({id: "general.cancel"}),
                                    acceptButtonClass: "is-danger",
                                    acceptButtonMethod: () => {
                                        setFiles(files.filter(file => file.name !== row.name));
                                        dispatch({type: Actions.HIDE_MODAL});
                                    }
                                }
                            });
                        }}
                    />
                </span>
            ),
            maxWidth: "80px"
        }
    ];

    const onChangeSourceLanguage = sourceLanguage => {
        setTargetLanguages([]);
        if (sourceLanguage) changeLanguages(sourceLanguage)
        else {
            setSourceLanguage(null);
            setAvailableTargetLanguages([]);
        }
    }

    const changeLanguages = sourceLanguage => {
        setSourceLanguage(sourceLanguage);
        setAvailableTargetLanguages(availableLanguages
            .filter(language => language.source_language.iso_code === sourceLanguage)
            .map(language => ({
                label: intl.formatMessage({id: `language.${language.target_language.iso_code}`}),
                value: language.target_language.iso_code
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
    }

    const onChangeFiles = newFiles => {
        const newFilesStructure = [];
        newFiles.forEach(newFile => {
            if (files.filter(file => file.name === newFile.name).length > 0)
                toast.error(intl.formatMessage({id: "task.cannot_provide_same_name"}, {name: newFile.name}));
            else newFilesStructure.push(newFile)
        });
        setFiles([...files, ...newFilesStructure]);
    };

    const onClickUpdate = event => {
        event.preventDefault();
        if (!name.trim() || !department || !sourceLanguage || !(targetLanguages.length > 0) || !(files.length > 0) || !type) {
            setErrors({
                ...(!name.trim() && { name: true }),
                ...(!department && { department: true }),
                ...(!sourceLanguage && { source_language: true }),
                ...(!(targetLanguages.length > 0) && { target_languages: true }),
                ...(!(files.length > 0) && { files: true }),
                ...(!type && { type: true })
            });
            return;
        } else setErrors({});

        const formData = new FormData();
        formData.append("description", name);
        formData.append("department", department);
        formData.append("source_language", sourceLanguage);
        formData.append("target_languages", JSON.stringify(targetLanguages.map(language => language.value)));
        formData.append("review", false);
        formData.append("translation_type", type);
        files.map(file => file.path ? formData.append("source_files[]", JSON.stringify(file)) : formData.append("files[]", file));
        formData.append("custom_quote", customQuote);
        formData.append("comments", comments);

        dispatch(updateTask(id, formData));
        dispatch({type: Actions.SHOW_MODAL, payload: {modalType: "loading"}});
    };

    const onClickSave = event => {
        event.preventDefault();
        if (!name.trim() || !department || !sourceLanguage || !(targetLanguages.length > 0) || !(files.length > 0) || !type) {
            setErrors({
                ...(!name.trim() && { name: true }),
                ...(!department && { department: true }),
                ...(!sourceLanguage && { source_language: true }),
                ...(!(targetLanguages.length > 0) && { target_languages: true }),
                ...(!(files.length > 0) && { files: true }),
                ...(!type && { type: true })
            });
            return;
        } else setErrors({});

        const formData = new FormData();
        formData.append("description", name);
        formData.append("department", department);
        formData.append("sourceLanguage", sourceLanguage);
        formData.append("targetLanguages", JSON.stringify(targetLanguages.map(language => language.value)));
        formData.append("review", false);
        formData.append("translation_type", type);
        files.map(file => formData.append("file[]", file));
        formData.append("custom_quote", customQuote);
        formData.append("comments", comments);

        dispatch(saveTaskAsDraft(formData));
        dispatch({type: Actions.SHOW_MODAL, payload: {modalType: "loading"}});
    };

    const onClickInfo = type => {
        switch (type) {
            case "name":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_project_name",
                        title: intl.formatMessage({id: "general.project_name_info"})
                    }
                });
                break;
            case "departments":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_departments",
                        title: intl.formatMessage({id: "general.departments_info"})
                    }
                });
                break;
            case "languages":
                dispatch({
                    type: Actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_languages",
                        title: intl.formatMessage({id: "general.languages_info"})
                    }
                });
                break;
            case "types":
                dispatch({
                    type: Actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_translation_types",
                        title: intl.formatMessage({id: "general.translation_types_info"}),
                        extraData: {translationTypes}
                    }
                });
                break;
            case "files":
                dispatch({
                    type: Actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_source_files",
                        title: intl.formatMessage({id: "general.source_files_info"})
                    }
                });
                break;
            case "custom_quote":
                dispatch({
                    type: actions.SHOW_MODAL,
                    payload: {
                        modalType: "info_custom_quote",
                        title: intl.formatMessage({id: "general.important"})
                    }
                });
                break;
            default:
        }
    };

    return (
        <div className="box">
            <div className="columns is-mobile">
                <Breadcrumbs
                    destination="/tasks"
                    destinationTitle={intl.formatMessage({ id: "menu.tasks" })}
                    title={id && task ? task.description : intl.formatMessage({ id: "task.add_task" })}
                />
            </div>
            <hr/>
            <form>
                <h4 className="title is-4 has-text-primary">
                    {intl.formatMessage({id: "task.task_information"})}
                </h4>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="task.name"/>*
                            </label>
                            <input
                                className="input has-icons-right"
                                maxLength={255}
                                onChange={event => setName(event.target.value)}
                                value={name}
                            />
                            {errors.name && (
                                <p className="help is-danger">
                                    <FormattedMessage id="general.field_required"/>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("name")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="department.name"/>*
                            </label>
                            <div className="select is-fullwidth">
                                <select onChange={event => setDepartment(event.target.value)} value={department ?? ""}>
                                    {departments.map(department => (
                                        <option key={department.id} value={department.id}>
                                            {department.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors.department && (
                                <p className="help is-danger">
                                    <FormattedMessage id="general.field_required"/>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("departments")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            {applyDiscount && (
                                <div className="message is-success is-light mt-4">
                                    <div className="message-body">
                                        {intl.formatMessage({id: "task.discount_description"})}
                                    </div>
                                </div>
                            )}
                            <label className="label">
                                <Tooltip
                                    id="tooltip.sourceLanguage"
                                    description={intl.formatMessage({id: "task.tooltip.source_language"})}
                                    element={<FontAwesomeIcon
                                        className="mr-2 has-text-link tooltip-icon"
                                        icon={faQuestionCircle}
                                    />}
                                    title={intl.formatMessage({id: "task.source_language"})}
                                />
                                {intl.formatMessage({id: "task.source_language"})}*
                            </label>
                            <div className="select is-fullwidth">
                                <select
                                    onChange={event => onChangeSourceLanguage(event.target.value)}
                                    value={sourceLanguage ?? ""}
                                >
                                    {availableSourceLanguages.map(language => (
                                        <option key={language.value} value={language.value}>
                                            {language.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors.source_language && (
                                <p className="help is-danger">
                                    <FormattedMessage id="general.field_required"/>
                                </p>
                            )}
                        </div>
                        <div className="field">
                            <label className="label">
                                <Tooltip
                                    id="tooltip.targetLanguage"
                                    description={intl.formatMessage({id: "task.tooltip.target_languages"})}
                                    element={<FontAwesomeIcon
                                        className="mr-2 has-text-link tooltip-icon"
                                        icon={faQuestionCircle}
                                    />}
                                    title={intl.formatMessage({id: "task.target_languages"})}
                                />
                                {intl.formatMessage({id: "task.target_languages"})}*
                            </label>
                            <Select
                                isMulti
                                onChange={setTargetLanguages}
                                options={availableTargetLanguages}
                                placeholder=""
                                value={targetLanguages}
                            />
                            {errors.target_languages && (
                                <p className="help is-danger">
                                    <FormattedMessage id="general.field_required"/>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("languages")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="task.translation_type"/>*
                            </label>
                            <div className="select is-fullwidth">
                                <select onChange={event => setType(event.target.value)} value={type ?? ""}>
                                    {translationTypes?.map(type => (
                                        <option key={type.id} value={type.id}>
                                            {type[`name_${locale.name}`]}
                                        </option>
                                    ))}
                                </select>
                                {errors.type && (
                                    <p className="help is-danger">
                                        <FormattedMessage id="general.field_required"/>
                                    </p>
                                )}
                            </div>
                            {type && (
                                <p className="has-background-grey-lighter help is-size-6">
                                    {translationTypes.find(t => t.id === parseInt(type))?.[`description_${locale.name}`]}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("types")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <hr/>
                <div className="columns">
                    <div className="column">
                        <h5 className="title is-5 has-text-primary">
                            {intl.formatMessage({id: "task.files"})}
                        </h5>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("files")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <div className="field">
                    <div className="file">
                        <label className="file-label">
                            <input
                                accept={FILE_TYPES.join(", ")}
                                className="file-input"
                                multiple
                                onChange={event => onChangeFiles(Array.from(event.target.files))}
                                type="file"
                            />
                            <span className="file-cta">
                                <span className="file-icon">
                                    <FontAwesomeIcon icon={faUpload}/>
                                </span>
                                <span className="file-label">
                                    <FormattedMessage id="task.select_the_documents"/>
                                </span>
                            </span>
                        </label>
                    </div>
                    {errors.files && (
                        <p className="help is-danger">
                            <FormattedMessage id="general.field_required"/>
                        </p>
                    )}
                </div>
                {files.length > 0 && (
                    <Datatable columns={columns} data={files} pointerOnHover={false}/>
                )}
                <div className="columns">
                    <div className="column">
                        <p className="has-text-weight-bold is-uppercase">
                            <FormattedMessage id="general.important"/>:
                        </p>
                        {!customQuote ? (
                            <OnlyReadInput
                                label="task.translating_editable_text"
                                fieldValue={<FormattedMessage id="task.translating_editable_text_description_edit"/>}
                            />
                        ) : !task?.source_files_validated ? (
                            <OnlyReadInput
                                label="task.source_files_validation_requested"
                                fieldValue={<FormattedMessage id="task.source_files_validation_requested_description"/>}
                            />
                        ) : (
                            <OnlyReadInput
                                label="task.translating_validated_text"
                                fieldValue={<FormattedMessage id="task.translating_validated_text_description"/>}
                            />
                        )}
                        <div className="field">
                            <div className="control">
                                <label className="checkbox">
                                    <input
                                        className="mr-1"
                                        disabled={task?.source_files_validated}
                                        checked={customQuote}
                                        onChange={event => setCustomQuote(event.target.checked)}
                                        type="checkbox"
                                    />
                                    <FormattedMessage id="task.request_source_files_validation"/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="column is-1">
                        <button
                            className="button is-black is-rounded is-small px-3"
                            onClick={() => onClickInfo("custom_quote")}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faInfo}/>
                        </button>
                    </div>
                </div>
                <hr/>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="task.comments_help"/>
                    </label>
                    <textarea
                        className="textarea"
                        maxLength={255}
                        onChange={event => setComments(event.target.value)}
                        placeholder={intl.formatMessage({id: "general.enter_comments"})}
                        rows="5"
                        value={comments}
                    />
                    {errors.comments && (
                        <p className="help is-danger">
                            <FormattedMessage id="general.field_required"/>
                        </p>
                    )}
                </div>
                <div className="buttons">
                    {id ? (
                        <>
                            <button className="button is-primary" onClick={onClickUpdate}>
                                {intl.formatMessage({id: "task.update_and_calculate_a_quote"})}
                            </button>
                            <button className="button" onClick={() => history.push(`/tasks/${id}`)} type="button">
                                {intl.formatMessage({id: "general.cancel"})}
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="button is-link is-primary" onClick={onClickSave}>
                                {intl.formatMessage({id: "task.save_draft_and_calculate_a_quote"})}
                            </button>
                            <button className="button" onClick={() => history.push("/tasks")} type="button">
                                {intl.formatMessage({id: "general.cancel"})}
                            </button>
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};

export default CreateTaskVerifyFiles;
