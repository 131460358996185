import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";

const Filter = (props) => {
    const intl = useIntl();
    const [searchString, setSearchString] = useState("");

    const filter = () => {
        return (
            <>
                <section className="custom-box">
                    <h4 className="title is-5 has-text-primary"><FormattedMessage id="general.filter"/></h4>
                    <div className="columns is-desktop">
                        <div className="column">
                            <label className="label">{intl.formatMessage({id: "language.iso_code"})}</label>
                            <div className="control">
                                <input type="text" name="language-iso-code"
                                       className="input has-icons-right"
                                       value={searchString}
                                       onChange={(e) => {
                                           setSearchString(e.target.value)
                                       }}
                                />
                            </div>
                        </div>
                        <div className="is-flex column is-align-items-flex-end">
                            <button
                                className="button is-info floatingButton"
                                onClick={() => props.search(searchString)}>
                                <FormattedMessage id="general.search"/>
                            </button>
                        </div>
                    </div>

                </section>
            </>
        )
    }

    return (<>{filter()}</>)
}

export default Filter;
