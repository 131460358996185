import React, {useEffect, useState} from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from 'react-redux'
import {updatePassword} from "../../store/reducers/user/userActions"
import {useForm} from "react-hook-form";

function ModalChangePassword(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const error = useSelector(state => state.user.error);

    const { errors, setError } = useForm({mode: 'onChange'});

    useEffect(() => {
        if (error) {
            printErrors(error.errors);
            dispatch({type:"CLEAR_ERROR"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const changePassword = (e) => {
        e.preventDefault();
        props.extraData.password = password;
        props.extraData.password_confirmation = confirmedPassword;
        dispatch(updatePassword(props.extraData));
    }

    const printErrors = (errors)=>{
        Object.entries(errors).forEach(([key, value]) => {
            setError(key,{type:"server",message:value[0]});
        });
    }

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}/>
                </header>

                <section className="modal-card-body">
                    <div className="mt-4 mb-4">
                        <div className="columns">
                            <div className="column is-centered is-8 is-offset-2">
                                <div className="field">
                                    <label className="label"><FormattedMessage id="general.new_password"/></label>
                                    <div className="control">
                                        <input
                                            className="input" type="password" name="password"
                                            placeholder={intl.formatMessage({id: "placeholder.new_password"})}
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}/>
                                        {errors.password&&errors.password.type==="server"&&(<p className="error">{errors.password.message}</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="columns mt-2">
                            <div className="column is-centered is-8 is-offset-2">
                                <div>
                                    <div className="field">
                                        <label className="label"><FormattedMessage
                                            id="general.password_confirmation"/></label>
                                        <div className="control">
                                            <input
                                                className="input" type="password"
                                                name="password_confirmation"
                                                placeholder={intl.formatMessage({id: "placeholder.password_confirmation"})}
                                                value={confirmedPassword}
                                                onChange={(e) => {
                                                    setConfirmedPassword(e.target.value)
                                                }}/>
                                            {errors.password_confirmation&&errors.password_confirmation.type==="server"&&(<p className="error">{errors.password_confirmation.message}</p>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className={`button ${props.acceptButtonClass}`}
                            onClick={(e) => changePassword(e)}>{props.acceptButton}</button>
                    <button className={`button ${props.cancelButtonClass}`}
                            onClick={props.closeModal}>{props.cancelButton}</button>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalChangePassword);
