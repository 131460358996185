import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import React from "react";
import Datatable from "../Datatable/Datatable";
import {updateIsSourceLanguage} from "../../store/reducers/language/languageActions";

const Table = (props) => {
    let languages = props.languages ?? [];
    const intl = useIntl();
    const dispatch = useDispatch();

    const updateLanguage = (languageId) =>{
        dispatch(updateIsSourceLanguage(languageId));
    }

    const SourceLanguagesDatatable = () => {
        let columns = [
            {
                name: intl.formatMessage({id: "language.iso_code"}),
                selector: row => row.iso_code,
                sortable: true
            },
            {
                name: intl.formatMessage({id: "language.languages"}),
                selector: row => intl.formatMessage({id: "language." + row?.iso_code??""}),
                sortable: true
            },
            {
                name: intl.formatMessage({id: "language.set_as_source"}),
                selector: row => (
                    <>
                        <label className="checkbox">
                            <input
                                type="checkbox"
                                name="is-source"
                                defaultChecked={row.is_source}
                                onClick={(e)=>{updateLanguage(row.id);}}
                            />
                        </label>
                    </>),
                sortable: true
            }
        ]

        return (
            <Datatable columns={columns} data={languages} pointerOnHover={false}/>
        )
    }

return (
    <SourceLanguagesDatatable/>
)
}

export default Table;
