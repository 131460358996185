import React from 'react';
import ModalHOC from '../../hoc/Modal/ModalHOC';
import LottieAnimation from '../Lottie';
import loader from '../../assets/41454-loader-animation-ver-11.json'

function ModalLoading(props) {

    return (
        <div id="modal" className="modal">
            <div className="modal-background"/>
            <LottieAnimation lotti={loader} height={300} width={300}/>
        </div>
    );
}

export default ModalHOC(ModalLoading);
