export const FILE_TYPES = [
    ".doc",
    ".docx",
    ".htm",
    ".html",
    ".idml",
    ".mi2",
    ".mif",
    ".odf",
    ".odp",
    ".ods",
    ".odt",
    ".po",
    ".ppt",
    ".pptx",
    ".srt",
    ".txt",
    ".xls",
    ".xlsx",
    ".xlt"
];
