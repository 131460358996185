import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import * as actions from "../../store/Actions";
import {toast} from "react-toastify";
import UserHOC from "../../hoc/User/UserHOC";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Input from "../../components/Form/Input";
import {useForm} from "react-hook-form";
import {updateUser} from "../../store/reducers/user/userActions";
import UserTypeSelect from "../../components/User/UserTypeSelect";
import MultipleSelect from "../../components/Form/MultipleSelect";
import {getDepartments} from "../../store/reducers/department/departmentActions";
import * as userTypes from "../../store/reducers/auth/userTypes";
import * as UserTypes from "../../utils/enums/UserTypes";

function EditUser(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const {register, errors, handleSubmit, setValue} = useForm();
    const selectedUser = useSelector(state => state.user.selectedUser);
    const hasUserBeenUpdated = useSelector(state => state.user.userUpdated);
    const passwordChangedStatus = useSelector(state => state.user.passwordChangedStatus);
    const [selectedDepartments, setSelectedDepartments] = useState(selectedUser.departments);
    const departmentsFromStore = useSelector(state => state.department.departments);
    const user = useSelector(state => state.auth.user);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");

    const [selectedUserType, setSelectedUserType] = useState(selectedUser.type);

    useEffect(() => {
        if (passwordChangedStatus === "in_process") {
            dispatch({type: 'TOGGLE_MODAL'});
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordChangedStatus]);

    useEffect(() => {
        if (departmentsFromStore.length === 0) dispatch(getDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasUserBeenUpdated) {
            dispatch({type: 'TOGGLE_MODAL'});
            toast.success(intl.formatMessage({id: "message.user_updated"}));
            dispatch({type: actions.USER_UPDATED, payload: {status: false}})
            if ((props.location.state?.from??"")==="customer"){
                if(props.location.state.customer_id > 0) {
                    history.replace("/customers/" + props.location.state.customer_id + "/edit");
                } else {
                    history.push({pathname: "/customers/create", exact: true});
                }
            } else {
                history.replace("/users");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenUpdated]);

    useEffect(() => {
        setValue("departments", JSON.stringify(selectedDepartments));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartments])

    const getFullName = () => {
        var name = (selectedUser.name) ? selectedUser.name : "";
        var lastname = (selectedUser.lastname) ? selectedUser.lastname : "";
        return name + " " + lastname;
    }

    const onSubmit = data => {
        data.id = selectedUser.id;
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: "loading",
            }
        })
        dispatch(updateUser(data, user.id === data.id));
    }

    const ShowDepartmentsToEdit = () => {
        if ((userType === userTypes.CLIENT_USER || userType === userTypes.CLIENT_ADMIN) && selectedUserType === UserTypes.CLIENT_USER){
            return (
                <MultipleSelect
                    label="department.name"
                    subtitle="user.departments"
                    name="department"
                    placeholder="placeholder.department"
                    elements={departmentsFromStore}
                    selectedElements={selectedDepartments}
                    setSelectedElements={setSelectedDepartments}
                    exposedAttribute="title"
                    show={true}/>
            )
        }
    }

    return (
        <div className="box is-desktop">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={getFullName()}
                    destinationTitle={
                        (
                            ((props.location.state?.from??"")!=="customer")?
                                intl.formatMessage({id: "menu.users"}):
                                intl.formatMessage({id: "menu.clients"})
                        )
                    }
                    destination={(((props.location.state?.from??"")==="customer")?
                        ((props.location.state.customer_id > 0)?
                                "/customers/" + props.location.state.customer_id + "/edit":
                                "/customers/create"
                        ):
                        "/users")}
                />
            </div>

            <hr/>

            <div className="p-2 is-desktop">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="columns is-desktop">
                        <div className="column">
                            <Input name="name"
                                   value={selectedUser.name}
                                   label="general.name"
                                   type="name"
                                   errors={errors}
                                   register={register}/>
                        </div>
                        <div className="column">
                            <Input name="lastname"
                                   value={selectedUser.lastname}
                                   label="user.lastname"
                                   type="lastname"
                                   errors={errors}
                                   register={register}/>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            <Input name="email"
                                   value={selectedUser.email}
                                   label="general.email"
                                   type="email"
                                   errors={errors}
                                   register={register}/>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="general.phone"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        defaultValue={selectedUser.phone}
                                        name="phone"
                                        placeholder={intl.formatMessage({id: "placeholder.phone"})}
                                        ref={register()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-half">
                            <button
                                className="button is-light"
                                onClick={(e) => props.showChangePasswordModal(e, selectedUser)}>
                                {intl.formatMessage({id: "general.change_password"})}
                            </button>
                        </div>
                    </div>

                    {user.id !== selectedUser.id && (
                        <>
                            <hr/>
                            <div className="columns is-desktop">
                                <div className="column">
                                    <UserTypeSelect
                                        name="type"
                                        onChange={event => setSelectedUserType(event.target.value)}
                                        register={register}
                                        value={selectedUserType}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <input type="hidden" name="departments" ref={register}/>

                    {ShowDepartmentsToEdit()}

                    <hr/>

                    <div className="columns">
                        <div className="is-flex column is-3">
                            <button className="button is-primary is-flex-grow-5" type="submit">
                                <FormattedMessage id="general.save"/>
                            </button>
                        </div>
                        <div className="is-flex column is-3">
                            <button
                                className="button is-light is-flex-grow-5"
                                onClick={() => history.push("/users")}
                            >
                                <FormattedMessage id="general.cancel"/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserHOC(EditUser);
