import * as actionTypes from "../../Actions";

const initialState = {
    rates: [],
    sourceLanguages: [],
    targetLanguages: [],
    canUserReviewOriginals: false,
    message:null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RATES:
            return {
                ...state,
                rates: action.payload
            }
        case actionTypes.CAN_USER_REVIEW_ORIGINAL:
            return {
                ...state,
                canUserReviewOriginals: action.payload
            }
        case actionTypes.RESET_CAN_USER_REVIEW_ORIGINAL:
            return {
                ...state,
                canUserReviewOriginals: false
            }
        case actionTypes.GET_RATES_SOURCE_LANGUAGES:
            return {
                ...state,
                sourceLanguages: action.payload
            }
        case actionTypes.GET_RATES_TARGET_LANGUAGES:
            return {
                ...state,
                targetLanguages: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
