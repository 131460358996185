import * as actionTypes from '../../../Actions';

const initialState = {
    isSendLinkReset: null,
    message: null
};

const reducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case actionTypes.SEND_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isSendLinkReset: true,
                message: action.payload
            };
        case actionTypes.RESET_SENDLINK:
            return {
                ...state,
                isSendLinkReset: false,
                message: null
            };
        default:
            return state;
    }
};

export default reducer;
