import {FormattedMessage, useIntl} from "react-intl";
import React from "react";

export default function Input(props) {
    const intl = useIntl();

    return (
        <div className="field">
            <label className="label"><FormattedMessage id={props.label}/></label>
            <div className="control">
                <input
                    className="input"
                    key="input_password"
                    type={(props.type) ? props.type : "text"}
                    name={props.name}
                    defaultValue={props.value}
                    placeholder={intl.formatMessage({id: "placeholder." + props.name})}
                    ref={props.register({required: true})}
                />
                {props.errors[props.name] && <span className="help is-danger"><FormattedMessage id={props.errorText}/></span>}
            </div>
        </div>
    )
}
