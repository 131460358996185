// ** React Imports
import React from "react";

// ** Third Party Imports
import { useSelector } from "react-redux";

// ** Modal Imports
import ModalBasic from "./ModalConfirmation";
import ModalAlert from "./ModalAlert";
import ModalCustomerAssignsProjects from "./ModalCustomerAssignsProjects";
import ModalUploadExcel from "./ModalUploadExcel";
import ModalLoading from "./ModalLoading";
import ModalChangePassword from "./ModalChangePassword";
import ModalConnectionError from "./ModalServerError";
import ModalSessionExpired from "./ModalSessionExpired";
import ModalTaskCreated from "./ModalTaskCreated";
import ModalNotFound from "./ModalNotFound";
import ModalInactiveUser from "./ModalInactiveUser";
import ModalValidationError from "./ModalValidationError";
import ModalOptions from "./ModalOptions";
import FilesLoading from "./FilesLoading";
import ModalUpdateRate from "./ModalUpdateRate";
import ModalCreateRate from "./ModalCreateRate";
import ModalEnableRate from "./ModalEnableRate";
import ModalImportRates from "./ModalImportRates";
import ModalInfoRate from "./ModalInfoRate";
import ModalInfoProjectName from "./ModalInfoProjectName";
import ModalInfoDepartments from "./ModalInfoDepartments";
import ModalInfoLanguages from "./ModalInfoLanguages";
import ModalInfoTranslationTypes from "./ModalInfoTranslationTypes";
import ModalInfoSourceFiles from "./ModalInfoSourceFiles";
import ModalInfoCustomQuote from "./ModalInfoCustomQuote";
import ModalInfoDeliveryDate from "./ModalInfoDeliveryDate";
import ModalInfoAmount from "./ModalInfoAmount";
import ModalOtherConcept from "./ModalOtherConcept";
import ModalPayment from "./ModalPayment";

function Modal() {
    // ** Hooks
    const modalType = useSelector(state => state.modal.modalType);
    const extraData = useSelector(state => state.modal.extraData);

    const renderSwitch = () => {
        switch (modalType) {
            case 'basic':
                return <ModalBasic/>;
            case 'alert':
                return <ModalAlert/>;
            case 'assignProjects':
                return <ModalCustomerAssignsProjects/>;
            case 'loading':
                return <ModalLoading/>;
            case 'files_loading':
                return <FilesLoading/>;
            case 'change_password':
                return <ModalChangePassword/>;
            case 'connection_error':
                return <ModalConnectionError/>;
            case 'session_expired':
                return <ModalSessionExpired/>;
            case 'not_found':
                return <ModalNotFound/>;
            case 'inactive_user':
                return <ModalInactiveUser/>;
            case 'validation_error':
                return <ModalValidationError extraData={extraData}/>;
            case 'addRateExcel':
                return <ModalUploadExcel/>;
            case 'task_created':
                return <ModalTaskCreated/>;
            case "options":
                return <ModalOptions/>;
            case "editRate":
                return <ModalUpdateRate extraData={extraData}/>;
            case "createRate":
                return <ModalCreateRate extraData={extraData}/>;
            case "enableRate":
                return <ModalEnableRate extraData={extraData}/>;
            case "import_rates":
                return <ModalImportRates/>;
            case "info_rate":
                return <ModalInfoRate extraData={extraData}/>;
            case "info_project_name":
                return <ModalInfoProjectName/>;
            case "info_departments":
                return <ModalInfoDepartments/>;
            case "info_languages":
                return <ModalInfoLanguages/>;
            case "info_translation_types":
                return <ModalInfoTranslationTypes extraData={extraData}/>;
            case "info_source_files":
                return <ModalInfoSourceFiles/>;
            case "info_custom_quote":
                return <ModalInfoCustomQuote/>;
            case "info_delivery_date":
                return <ModalInfoDeliveryDate/>;
            case "info_amount":
                return <ModalInfoAmount extraData={extraData}/>;
            case "other_concept":
                return <ModalOtherConcept extraData={extraData}/>;
            case "payment":
                return <ModalPayment extraData={extraData}/>;
            default:
                return <ModalBasic/>;
        }
    };

    return (
        <>
            {renderSwitch()}
        </>
    );
}

export default Modal;
