import React from 'react';
import {useIntl} from "react-intl";
import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalValidationError = (props) => {
    const intl = useIntl();
    let errors = props.extraData;

    let messages = [];

    if(errors){
        let camps = Object.keys(errors);
        camps.forEach(camp => {
            messages.push(...errors[camp]);
        });
    }

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal }/>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{intl.formatMessage({id: "error.validation_error"})}</p>
                    <button className="delete" aria-label="close" onClick={ props.closeModal }/>
                </header>

                <section className="modal-card-body">
                    <div>
                        {messages.length > 0 ? (
                            <ul>
                                {messages.map((message, index) => (
                                    <li key={index}>{message}</li>
                                ))}
                            </ul>
                        ): null}
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <button className="button is-danger" onClick={props.closeModal}>{intl.formatMessage({id: "general.accept"})}</button>
                </footer>
            </div>
        </div>
    )
}

export default ModalHOC(ModalValidationError);
