import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import * as actions from "../../store/Actions";
import {toast} from "react-toastify";
import UserHOC from "../../hoc/User/UserHOC";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import OnlyReadInput from "../../components/Form/OnlyReadInput";
import {getAllUser} from "../../store/reducers/user/userActions";
import MultipleSelect from "../../components/Form/MultipleSelect";
import * as userTypes from "../../store/reducers/auth/userTypes";
import * as UserTypes from "../../utils/enums/UserTypes";

function ShowUser(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedUser = useSelector(state => state.user.selectedUser);
    const hasUserBeenUpdated = useSelector(state => state.user.userUpdated);
    const hasUSerDeleted = useSelector(state => state.user.userDeleted);
    const passwordChangedStatus = useSelector(state => state.user.passwordChangedStatus);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");

    useEffect(() => {
        let id = props.match.params.id
        dispatch(getAllUser(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasUSerDeleted){
            dispatch({type: actions.USER_UPDATED, payload: {status: false}});
            if (userType === userTypes.CLIENT_ADMIN) {
                history.push("/users");
            } else if (userType === userTypes.IDISC_ADMIN) {
                history.push((props.location.state.customer_id > 0)?
                    "/customers/" + props.location.state.customer_id + "/edit":
                    "/customers/create"
                );
            } else {
                history.push("/tasks");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUSerDeleted]);

    useEffect(() => {
        if (passwordChangedStatus === "in_process") {
            dispatch({type: 'TOGGLE_MODAL'});
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordChangedStatus]);

    useEffect(() => {
        if (hasUserBeenUpdated) {
            dispatch({type: 'TOGGLE_MODAL'});
            toast.success(intl.formatMessage({id: "message.user_updated"}));
            dispatch({type: actions.USER_UPDATED, payload: {status: false}})
            if (userType === userTypes.CLIENT_ADMIN) {
                history.push("/users");
            } else if (userType === userTypes.IDISC_ADMIN) {
                history.push((props.location.state.customer_id > 0)?
                    "/customers/" + props.location.state.customer_id + "/edit":
                    "/customers/create"
                );
            } else {
                history.push("/tasks");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenUpdated]);

    const getFullName = () => {
        var name = (selectedUser.name) ? selectedUser.name : "";
        var lastname = (selectedUser.lastname) ? selectedUser.lastname : "";
        return name + " " + lastname;
    }

    const handleClick = () => {
        if ((props.location.state?.from??"")==="customer"){
            history.push({pathname: "/users/"+ selectedUser.id+"/edit", exact: true, state:{from:"customer",customer_id:props.location.state.customer_id}});
        } else {
            history.push("/users/" + selectedUser.id + "/edit");
        }
    }

    return (
        <div className="box is-desktop">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={getFullName()}
                    destinationTitle={
                        (
                            ((props.location.state?.from??"")!=="customer")?
                                intl.formatMessage({id: "menu.users"}):
                                intl.formatMessage({id: "menu.clients"})
                        )
                    }
                    destination={(((props.location.state?.from??"")==="customer")?
                        ((props.location.state.customer_id > 0)?
                                "/customers/" + props.location.state.customer_id + "/edit":
                                "/customers/create"
                        ):
                        "/users")}/>
            </div>

            <hr/>

            <div className="p-2 is-desktop">
                <div className="columns is-desktop">
                    <div className="column">
                        <OnlyReadInput label="general.name" fieldValue={selectedUser.name}/>
                    </div>
                    <div className="column">
                        <OnlyReadInput label="user.lastname" fieldValue={selectedUser.lastname}/>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-half">
                        <OnlyReadInput label="general.email" fieldValue={selectedUser.email}/>
                    </div>
                    {selectedUser.phone && (
                        <div className="column is-half">
                            <OnlyReadInput label="general.phone" fieldValue={selectedUser.phone}/>
                        </div>
                    )}
                </div>

                <div className="columns">
                    <div className="column is-half">
                        <button
                            className="button is-light"
                            onClick={(e) => props.showChangePasswordModal(e, selectedUser)}>
                            {intl.formatMessage({id: "general.change_password"})}
                        </button>
                    </div>
                </div>

                <hr/>

                <div className="columns is-desktop">
                    <div className="column">
                        <OnlyReadInput label="general.type"
                                       fieldValue={intl.formatMessage({id: "type." + selectedUser.type})}/>
                    </div>
                </div>
                {selectedUser.type === UserTypes.CLIENT_USER ? (
                    <MultipleSelect
                        subtitle="user.departments"
                        // elements={[]}
                        selectedElements={selectedUser.departments}
                        exposedAttribute="title"
                        show={false}
                        from="user"
                        handleClick={handleClick}/>
                ) : null}

            </div>
        </div>
    )
}

export default UserHOC(ShowUser);
