import React from 'react';
import {FormattedMessage} from "react-intl";

export default function Error404() {
    return (
        <div className="box is-desktop">
            <div className="has-text-centered">
                <h1 className="title has-text-centered">
                    <FormattedMessage id={"page_404"} />
                </h1>
                <section>
                    <FormattedMessage id={"page_404_message"} />
                </section>
            </div>
        </div>
    );
}
