// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInfoDeliveryDate = props => {
    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="content">
                        <FormattedMessage id="general.info_delivery_date"/>
                        {" "}
                        <FormattedMessage id="general.info_contact" values={{
                            email: <a href="mailto:xpress@idisc.com">xpress@idisc.com</a>
                        }}/>
                        <br/>
                        <FormattedMessage id="general.info_spain"/>
                        {" "}
                        <a href="tel:+34937787300">(+34) 93 778 73 00</a>
                        <br/>
                        <FormattedMessage id="general.info_mexico"/>
                        {" "}
                        <a href="tel:+525541639062">(+52) (55) 4163 9062</a>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ModalHOC(ModalInfoDeliveryDate);
