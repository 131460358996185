// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInfoProjectName = props => {
    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="content">
                        <FormattedMessage id="general.info_project_name"/>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ModalHOC(ModalInfoProjectName);
