import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';

export const getTemporalTask = (id) => {
    return (dispatch) =>{
        return axiosInstance.get("/task/temporal/" + id)
            .then(res =>{
                dispatch({ type: actions.GET_TEMPORAL_TASK, payload: res.data.data})
            }).catch(() => null)
    }
}
export const payTemporalTask = (id) => {
    return (dispatch) =>{
        return axiosInstance.get("/task/temporal/" + id + "/pay")
            .then(res =>{
                dispatch({ type: actions.PAY_TEMPORAL_TASK, payload: res.data.data})
            }).catch(() => null)
    }
}
