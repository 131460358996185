import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {verifyTemporalTaskPayment} from "../../store/reducers/task/taskActions";
import * as Actions from "../../store/Actions";

const GuestCorrectPayment = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const taskId = props.match.params.id;

    const hasBeenPayed = useSelector(state => state.task.hasBeenPayed);

    useEffect(() => {
        dispatch({
            type: Actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
        dispatch({
            type: Actions.SET_PAYMENT_DONE,
            payload: null
        })

        const params = new URLSearchParams(props.location.search);
        let Ds_SignatureVersion = (params.get("Ds_SignatureVersion"));
        let Ds_MerchantParameters = (params.get("Ds_MerchantParameters"));
        let Ds_Signature = (params.get("Ds_Signature"));


        let mp = {}
        mp.Ds_SignatureVersion = Ds_SignatureVersion;
        mp.Ds_MerchantParameters = Ds_MerchantParameters;
        mp.Ds_Signature = Ds_Signature;

        let formData = new FormData();
        formData.append("merchantParameters", JSON.stringify(mp));
        formData.append("taskId", taskId);
        dispatch(verifyTemporalTaskPayment(formData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasBeenPayed !== null) dispatch({type: Actions.HIDE_MODAL});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenPayed])


    const correctPayment = () => {
        if (hasBeenPayed) {
            return (<>
                <div className="content">
                    <h1 className="has-text-centered">{intl.formatMessage({id: "task.APPROVED"})}</h1>
                    <p className="has-text-centered">{intl.formatMessage({id: "task.APPROVED.success"})}</p>
                    <p className="has-text-centered">{intl.formatMessage({id: "task.APPROVED.description"})}</p>
                </div>
            </>)
        } else {
            return (<>
                <div className="content">
                    <h1 className="has-text-centered">{intl.formatMessage({id: "task.DRAFT"})}</h1>
                    <p className="has-text-centered">{intl.formatMessage({id: "task.APPROVED.fail"})}</p>
                </div>
            </>)
        }
    }

    return (<>
        <div className="box">
            {correctPayment()}
        </div>
    </>)
}

export default GuestCorrectPayment;
