// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { createSingleRate,updateSingleRate } from "../../store/reducers/rate/rateActions";
import { getLanguages } from "../../store/reducers/customers/customersActions";
import { getSourceLanguages } from "../../store/reducers/language/languageActions";

export default function ActionRate(props) {
    const id_rate = props?.id_rate ?? 0;
    const rate = props?.rate;

    // ** States
    const [availableSourceLanguages, setAvailableSourceLanguages] = useState([]);
    const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState(undefined);
    const [targetLanguage, setTargetLanguage] = useState(undefined);
    const [r1, setR1] = useState(0);
    const [r2, setR2] = useState(0);
    const [defaultGroup, setDefaultGroup] = useState(false);

    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const { register, errors, handleSubmit, reset, setError } = useForm({mode: 'onChange'});
    const languages = useSelector(state => state.customer_assing.languages);
    const sourceLanguages = useSelector(state => state.language.sourceLanguages);
    const error = useSelector(state => state.customer_assing.error);

    useEffect(() => {
        if (!error) {
            dispatch(getLanguages());
            dispatch(getSourceLanguages());
        }
        if (props.type === "edit") {
            reset();
            setR1(rate?.r1);
            setR2(rate?.r2);
            setSourceLanguage(rate?.source_lang??0);
            setTargetLanguage(rate?.target_lang??0);
            setDefaultGroup(!!rate?.default_group);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        setAvailableSourceLanguages(sourceLanguages
            .map(language => ({label: intl.formatMessage({id: `language.${language.iso_code}`}), value: language.id}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceLanguages]);

    useEffect(() => {
        setAvailableTargetLanguages(languages
            .map(language => ({label: intl.formatMessage({id: `language.${language.label}`}), value: language.value}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languages]);

    const onChangeSourceLang = (e) => {
        setSourceLanguage(e.target.value);
        reset();
    }

    const onChangeTargerLang = (e) => {
        setTargetLanguage(e.target.value);
        reset();
    }

    const submitControl = () => {
        if (document.getElementsByName("sourceLanguage")[0].value === 0) {
            setError("sourceLanguage",{type:"zeroCase",message:intl.formatMessage({id: "customer.requiredTwoLanguages"})});
            return false;
        }
        if (document.getElementsByName("targetLanguage")[0].value === 0) {
            setError("targetLanguage",{type:"zeroCase",message:intl.formatMessage({id: "customer.requiredTwoLanguages"})});
            return false;
        }
        if (document.getElementsByName("sourceLanguage")[0].value === document.getElementsByName("targetLanguage")[0].value) {
            setError("targetLanguage",{type:"custom",message:intl.formatMessage({id: "customer.different_languages"})});
            return false;
        }

        let neoRate = {source_lang:sourceLanguage,target_lang:targetLanguage,r1:r1,r2:r2}
        if (props.customer_id) neoRate.customer_id = props.customer_id;
        else neoRate.default_group = defaultGroup;

        if (props.type === "edit") dispatch(updateSingleRate(id_rate, neoRate));
        else dispatch(createSingleRate(neoRate));
    }

    return (
        <div className="is-desktop">
            <form onSubmit={handleSubmit(submitControl)}>
                <div className="columns is-desktop">
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="customer.assignLangSource"/>
                            </label>
                            <div className="select is-fullwidth">
                                <select name="sourceLanguage" onChange={onChangeSourceLang} value={sourceLanguage}>
                                    <option key="0" value={0}>
                                        {intl.formatMessage({id: "customer.assignLangSource"})}
                                    </option>
                                    {availableSourceLanguages?.map(language => (
                                        <option key={language.value} value={language.value}>
                                            {language.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors.sourceLanguage && errors.sourceLanguage.type === "custom" && (
                                <p className="error">{errors.sourceLanguage.message}</p>
                            )}
                            {errors.sourceLanguage && errors.sourceLanguage.type === "zeroCase" && (
                                <p className="error">{errors.sourceLanguage.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="customer.assignLangTarget"/>
                            </label>
                            <div className="select is-fullwidth">
                                <select name="targetLanguage" onChange={onChangeTargerLang} value={targetLanguage}>
                                    <option key="0" value={0}>
                                        {intl.formatMessage({id: "customer.assignLangTarget"})}
                                    </option>
                                    {availableTargetLanguages?.map(language => (
                                        <option key={language.value} value={language.value}>
                                            {language.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors.targetLanguage && errors.targetLanguage.type === "custom" && (
                                <p className="error">{errors.targetLanguage.message}</p>
                            )}
                            {errors.targetLanguage && errors.targetLanguage.type === "zeroCase" && (
                                <p className="error">{errors.targetLanguage.message}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <label className="label">
                            <FormattedMessage id="customer.r1"/>
                        </label>
                        <div className="control">
                            <input
                                className="input has-icons-right"
                                defaultValue={r1}
                                name="R1"
                                onChange={event => setR1(event.target.value)}
                                ref={register({min: 0, required: true, validate: {isNumber: value => !isNaN(value)}})}
                                type="text"
                            />
                            {errors.R1 && errors.R1.type === "required" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.required"})}</p>
                            )}
                            {errors.R1 && errors.R1.type === "min" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.zero"})}</p>
                            )}
                            {errors.R1 && errors.R1.type === "isNumber" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.is_number"})}</p>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <label className="label">
                            <FormattedMessage id="customer.r2"/>
                        </label>
                        <div className="control">
                            <input
                                className="input has-icons-right"
                                defaultValue={r2}
                                name="R2"
                                onChange={event => setR2(event.target.value)}
                                ref={register({min: 0, required: true, validate: {isNumber: value => !isNaN(value)}})}
                                type="text"
                            />
                            {errors.R2 && errors.R2.type === "required" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.required"})}</p>
                            )}
                            {errors.R2 && errors.R2.type === "min" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.zero"})}</p>
                            )}
                            {errors.R2 && errors.R2.type === "isNumber" && (
                                <p className="error">{intl.formatMessage({id: "rate.validate.is_number"})}</p>
                            )}
                        </div>
                    </div>
                </div>
                {!props.customer_id && (
                    <div className="columns">
                        <div className="column">
                            <label className="checkbox has-text-weight-bold">
                                <input
                                    className="mr-1"
                                    checked={defaultGroup}
                                    onChange={event => setDefaultGroup(event.target.checked)}
                                    type="checkbox"
                                />
                                <FormattedMessage id="customer.check_as_default_group"/>
                            </label>
                        </div>
                    </div>
                )}
                <input
                    className="button is-info floatingButton"
                    type="submit"
                    value={intl.formatMessage({id: "general.save"})}
                />
            </form>
        </div>
    );
}
