import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faShare} from "@fortawesome/free-solid-svg-icons";
import {FormattedMessage,useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {connect, useDispatch, useSelector} from "react-redux";
import {sendResetPassword,validateToken} from "../../store/reducers/auth/resetPassword/resetPasswordActions";
import * as actionTypes from "../../store/Actions";

let ResetPassword = props => {
    let email = props.location.search.split("?")[1].split("&").map(param => param.split("=")).find(param => param[0] === "email")[1];
    let token = props.match.params.token;
    const locale = useSelector(state => state.locale.locale);
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const errors = useSelector(state => state.resetPassword.error);
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const isConfirmed = useSelector(state => state.resetPassword.isConfirmed);
    const isTokenValid = useSelector(state => state.resetPassword.isTokenValid);

    useEffect(() => {
        if (isTokenValid==null){
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
            dispatch(validateToken({token:token,lang:locale}));
        }

        if (isConfirmed&&isTokenValid) {
            dispatch({type: 'TOGGLE_MODAL',});
            setPassword("");
            setConfirmPassword("");
            dispatch({type: actionTypes.RESET_TOKEN_VALID,payload:{isTokenValid:null}});
            dispatch({type: actionTypes.RESET_ERRORS,payload:{errors:null}});
            toast(intl.formatMessage({id: "reset.reset_password_success"}));
            history.push("/");
        } else if (errors!=null&&isTokenValid) {
            let target = document.querySelector(".field:nth-child(2)");
            //remove pre messages-----------------------------
            removeErrorMessages(target);
            //-------------------------------------------------
            Object.entries(errors).forEach(([key, value]) => {
                if (key === "password") {
                    if (Array.isArray(value)) {
                        value.forEach(function (v, i) {
                            //Append error message
                            appendErrorMessages(target,v,true);
                        });
                    } else {
                        //Append error message
                        appendErrorMessages(target,value,false);
                    }
                } else{
                    showAlert(value);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmed,isTokenValid, errors]);

    const onSubmit = (e) =>{
        e.preventDefault();
        if(password !== confirmpassword){
            toast.error(intl.formatMessage({id: "reset.confirm_password_not_equal"}));
        } else{
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
            let userLang;
            if (locale.name === null) {
                userLang = navigator.language.split("-")[0];
            } else {
                userLang = locale.name;
            }
            //dispatch
            const user = {
                email: email,
                password: password,
                password_confirmation:confirmpassword,
                token: token,
                lang:userLang
            }
            dispatch(sendResetPassword(user));
        }
    };

    const changePassword = (e) =>{
        e.target.classList.remove("error-input");
        setPassword(e.target.value);
    }

    const appendErrorMessages=(target,value,isAfter)=>{
        let div = document.createElement("div");
        div.className = "error";
        let p = document.createElement("p");
        p.innerText = "* " + value;
        div.append(p);
        if (!isAfter) {
            target.parentElement.appendChild(div);
        } else{
            target.after(div);
        }
    };

    const removeErrorMessages=(target)=>{
        let messages = target.parentElement.querySelectorAll(".error");
        Array.from(messages).forEach(function (v,i){
            v.remove();
        });
        document.querySelector("#password").classList.add("error-input");
        document.querySelector("#confirm_password").classList.add("error-input");
    };

    const changeConfirmPassword = (e) =>{
        setConfirmPassword(e.target.value);
        //remove pre messages-----------------------------
        removeErrorMessages(e.target);
        //-------------------------------------------------
        if (e.target.value.trim() !== password.trim()){
            e.target.classList.add("error-input");
            //Message
            //Append error message
            appendErrorMessages(e.target,intl.formatMessage({id: "reset.confirm_password_not_equal"}),false);
        }
    }

    const disableLoginButton = () => {
        if (password.trim() !== "" && confirmpassword.trim() !== "" && password.trim() === confirmpassword.trim()) {
            return false;
        } else{
            return true;
        }
    }

    const showAlert = (message) => {
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "error.wrong_credentials_title"}),
                modalType: "alert",
                message: message,
                acceptButton: intl.formatMessage({id: "general.accept"}),
                acceptButtonClass: "is-danger"
            }
        })
    }

    const items = [<p className="has-text-centered">{intl.formatMessage({id: "message.loading"})}</p>]

    if (errors!=null){
        Object.entries(errors).forEach(([key, value]) => {
            items[0]=<p className="has-text-centered">{value}</p>;
        });
    }

    return (
        <section className="hero is-fullheight" style={{backgroundImage: `url("${require("../../assets/idisc-xpress.jpg")}` }}>
            <div className="hero-body">
                <div className="container" style={{opacity: 1.2}}>
                    <div className="column is-6 is-offset-3">
                        <div className="box">
                            {(!isConfirmed&&isTokenValid)?(
                            <form onSubmit={(e) => {
                                onSubmit(e)
                            }}>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input className="input" type="password" id="password"
                                               placeholder={intl.formatMessage({id: "placeholder.password"})}
                                               value={password}
                                               onChange={(e) => {
                                                   changePassword(e)
                                               }}
                                               autoFocus=""/>
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                             icon={faKey} color="primary"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input className="input" type="password" id="confirm_password"
                                               placeholder={intl.formatMessage({id: "placeholder.password_confirmation"})}
                                               value={confirmpassword}
                                               onChange={(e) => {
                                                   changeConfirmPassword(e)
                                               }}
                                               autoFocus=""/>
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                             icon={faKey} color="primary"/>
                                        </span>
                                    </div>
                                </div>
                                <button id="reset_submit" type="submit" className="button is-block is-fullwidth is-link" disabled={disableLoginButton()}>
                                    <FontAwesomeIcon className="mr-2" icon={faShare} color="primary"/>
                                    <FormattedMessage id="reset.resetPassword"/>
                                </button>
                            </form>
                            ):items
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default connect()(ResetPassword);
