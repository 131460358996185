import * as actionTypes from '../../Actions';

const initialState = {
    modalType: "basic",
    showModal: false,
    title: "",
    message: "",
    cancelButton: "",
    acceptButton: "",
    acceptButtonClass: "is-success",
    acceptButtonMethod: null,
    cancelButtonClass: "is-white",
    extraData: null,
    optionButtons: [] //use for set multiple options in modal
};

const reducer = (state = initialState, action) => {
    let showModal = !state.showModal;
    let modalType = "basic";
    let title = "";
    let message = "";
    let cancelButton = "";
    let acceptButton = "";
    let acceptButtonClass = "is-success"
    let acceptButtonMethod = null
    let cancelButtonClass = "is-white";
    let extraData = {};
    let optionButtons = [];

    if (action.payload) {
        //Se usan operaciones ternarias para prevenir un error si un atributo es nulo
        modalType = (action.payload.modalType) ? action.payload.modalType : "basic";
        title = (action.payload.title) ? action.payload.title : "";
        message = (action.payload.message) ? action.payload.message : "";
        cancelButton = (action.payload.cancelButton) ? action.payload.cancelButton : "";
        acceptButton = (action.payload.acceptButton) ? action.payload.acceptButton : "";
        acceptButtonClass = (action.payload.acceptButtonClass) ? action.payload.acceptButtonClass : "is-success";
        acceptButtonMethod = (action.payload.acceptButtonMethod) ? action.payload.acceptButtonMethod : null;
        cancelButtonClass = (action.payload.cancelButtonClass) ? action.payload.cancelButtonClass : "is-white";
        extraData = (action.payload.extraData) ? action.payload.extraData : {};
        optionButtons = (action.payload.optionButtons) ? action.payload.optionButtons : [];
    }

    switch (action.type) {

        case actionTypes.SHOW_MODAL:
            showModal = true;
            return {
                ...state,
                showModal: showModal,
                modalType: modalType,
                title: title,
                message: message,
                cancelButton: cancelButton,
                acceptButton: acceptButton,
                acceptButtonClass: acceptButtonClass,
                cancelButtonClass: cancelButtonClass,
                acceptButtonMethod: acceptButtonMethod,
                extraData: extraData,
                optionButtons: optionButtons
            }
        case actionTypes.HIDE_MODAL:
            showModal = false;
            return {
                ...state,
                showModal: showModal,
                modalType: modalType,
                title: title,
                message: message,
                cancelButton: cancelButton,
                acceptButton: acceptButton,
                acceptButtonClass: acceptButtonClass,
                cancelButtonClass: cancelButtonClass,
                acceptButtonMethod: acceptButtonMethod,
                extraData: extraData,
                optionButtons: optionButtons
            }
        case actionTypes.TOGGLE_MODAL:

            return {
                ...state,
                showModal: showModal,
                modalType: modalType,
                title: title,
                message: message,
                cancelButton: cancelButton,
                acceptButton: acceptButton,
                acceptButtonClass: acceptButtonClass,
                cancelButtonClass: cancelButtonClass,
                acceptButtonMethod: acceptButtonMethod,
                extraData: extraData,
                optionButtons: optionButtons
            }
        default:
            return state;
    }
}

export default reducer;
