// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { createOtherConcept, updateOtherConcept } from "../../store/reducers/otherConcept/otherConceptActions";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalOtherConcept = props => {
    const id = props.extraData?.id;

    // ** States
    const [concept, setConcept] = useState("");
    const [description, setDescription] = useState("");
    const [unit, setUnit] = useState("");
    const [quantity, setQuantity] = useState("");
    const [price, setPrice] = useState("");

    // ** Hooks
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);

    useEffect(() => {
        if (id) {
            setConcept(props.extraData?.other_concept.concept);
            setDescription(props.extraData?.other_concept.description);
            setUnit(props.extraData?.other_concept.unit);
            setQuantity(props.extraData?.other_concept.quantity);
            setPrice(props.extraData?.other_concept.price);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onSubmit = event => {
        event.preventDefault();

        const data = { concept, description, unit, quantity, price, lang: locale.name };

        if (id) dispatch(updateOtherConcept(id, data));
        else dispatch(createOtherConcept({ ...data, task_id: props.extraData?.task_id }));
    }

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="is-desktop">
                        <form onSubmit={onSubmit}>
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="task.concept"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        maxLength={100}
                                        onChange={event => setConcept(event.target.value)}
                                        value={concept}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="task.description"/>
                                </label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        maxLength={255}
                                        onChange={event => setDescription(event.target.value)}
                                        rows="2"
                                        value={description}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="task.unit"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        maxLength={50}
                                        onChange={event => setUnit(event.target.value)}
                                        value={unit}
                                    />
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label className="label">
                                            <FormattedMessage id="task.quantity"/>
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input"
                                                max={999999.99}
                                                min={0.01}
                                                onChange={event => setQuantity(event.target.value)}
                                                step={0.01}
                                                type="number"
                                                value={quantity}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">
                                            <FormattedMessage id="task.price"/>
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input"
                                                max={999999.99}
                                                min={-999999.99}
                                                onChange={event => setPrice(event.target.value)}
                                                step={0.01}
                                                type="number"
                                                value={price}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="button is-info floatingButton" type="submit">
                                <FormattedMessage id="general.upload"/>
                            </button>
                        </form>
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <div className="h-36"></div>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalOtherConcept);
