// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// ** Store Imports
import { getCountries, storeCustomer } from "../../store/reducers/customers/customersActions";

export default function CreateCustomer(props) {
    const locale = navigator.language.split("-")[0];

    // ** States
    const [availableCountries, setAvailableCountries] = useState([]);
    const [customer,setCustomer] = useState(props.location.state?.customer ?? {
        name: "",
        customer_number: "",
        address: "",
        address_2: "",
        town: "",
        province: "",
        postal_code: "",
        tin: "",
        success_representative: "",
        success_representative_email: "",
        payment_scheme: "ON_DEMAND"
    });

    // ** Hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const { register, errors, handleSubmit, setError } = useForm({mode: 'onChange'});
    const countries = useSelector(state => state.customer_assing.countries);
    const message = useSelector(state => state.customer_assing.message);
    const error = useSelector(state => state.customer_assing.error);

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({id:"error.general"}));
            printErrors(error.errors);
            dispatch({type:"CLEAR_ERROR"});
        }

        if(message) {
            switch (message.type){
                case "SAVE_CUSTOMER_SUCCESS":
                    toast.success(intl.formatMessage({id:"customer.success.create"}));
                    history.push({pathname: "/customers", exact: true});
                    break;
                default:
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, error]);

    useEffect(() => {
        dispatch(getCountries());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAvailableCountries(countries
            .map(country => ({label: intl.formatMessage({id: `country.${country.code}`}), value: country.id}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        if (countries.length > 0 && (customer?.country ?? null) == null)
            setCustomer({...customer, country: countries[0].id});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    const printErrors = (errors)=>{
        Object.entries(errors).forEach(([key, value]) => {
            setError(key,{type:"server",message:value[0]});
        });
    }
    const onSubmit = () => {
        dispatch(storeCustomer({customer:customer,lang:locale}));
    }

    return (
        <div className="box is-desktop">
            <nav className="breadcrumb has-arrow-separator is-medium mb-6" aria-label="breadcrumbs">
                <ul>
                    <li><Link href="#" to="/customers" className="title is-4"><FormattedMessage id="menu.clients"/></Link>
                    </li>
                    <li className="is-active title is-4">
                        <a href="/#" aria-current="page" className="has-text-primary">
                            <FormattedMessage id="customer.add_customer"/>
                        </a>
                    </li>
                </ul>
            </nav>

            <section>
                <div className="p-4 is-desktop">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="columns is-desktop">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="customer.name"/>*
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer.name}
                                            name="name"
                                            onChange={event => setCustomer({...customer, name: event.target.value})}
                                            ref={register({required: true, maxLength: 255})}
                                        />
                                        {errors.name && errors.name.type === "required" && (
                                            <p className="error">
                                                {intl.formatMessage({id: "customer.required_camp"})}
                                            </p>
                                        )}
                                        {errors.name && errors.name.type === "maxLength" && (
                                            <p className="error">
                                                {intl.formatMessage({id: "customer.maxLength"})}
                                            </p>
                                        )}
                                        {errors.name && errors.name.type === "server" && (
                                            <p className="error">
                                                {errors.name.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="customer.number"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer.customer_number}
                                            name="customer_number"
                                            onChange={event => setCustomer({
                                                ...customer,
                                                customer_number: event.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.address"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.address}
                                            name="address"
                                            onChange={event => setCustomer({...customer, address: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.address"/> 2
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.address_2}
                                            name="address_2"
                                            onChange={event => setCustomer({
                                                ...customer,
                                                address_2: event.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.town"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.town}
                                            name="town"
                                            onChange={event => setCustomer({...customer, town: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.province"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.province}
                                            name="province"
                                            onChange={event => setCustomer({...customer, province: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <label className="label">
                                    <FormattedMessage id="general.country"/>*
                                </label>
                                <div className="select is-fullwidth">
                                    <select
                                        name="country"
                                        onChange={event => setCustomer({...customer, country: event.target.value})}
                                        value={customer?.country}
                                    >
                                        {availableCountries.map(country => (
                                            <option key={country.values} value={country.value}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.postal_code"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.postal_code}
                                            name="postal_code"
                                            onChange={event => setCustomer({
                                                ...customer,
                                                postal_code: event.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="general.tin"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer?.tin}
                                            name="tin"
                                            onChange={event => setCustomer({...customer, tin: event.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="customer.success_representative"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer.success_representative}
                                            name="success_representative"
                                            onChange={event => setCustomer({
                                                ...customer,
                                                success_representative: event.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="customer.success_representative_email"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            defaultValue={customer.success_representative_email}
                                            name="success_representative_email"
                                            onChange={event => setCustomer({
                                                ...customer,
                                                success_representative_email: event.target.value
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <div className="control">
                                        <label className="label">
                                            <input
                                                checked={(customer?.payment_scheme ?? "ON_DEMAND") === "ON_DEMAND"}
                                                className="mr-1"
                                                name="paymentType"
                                                onChange={event => setCustomer({
                                                    ...customer,
                                                    payment_scheme: event.target.checked ? "ON_DEMAND" : "DEFERRED"
                                                })}
                                                type="checkbox"
                                            />
                                            <FormattedMessage id="customer.advance_payment"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="columns">
                            <div className="is-flex column is-3">
                                <button id="saveCustomer" className="button is-info" type="submit">
                                    <FormattedMessage id="general.save"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
