import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import {getCustomers,deleteCustomerAction} from '../../store/reducers/customers/customersActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, useIntl} from 'react-intl';
import * as userTypes from "../../store/reducers/auth/userTypes";
import {toast} from "react-toastify";
import Datatable from "../../components/Datatable/Datatable";

export default function Customers() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const customers = useSelector(state => state.customer_assing.customers);
    const message = useSelector(state => state.customer_assing.message);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");

    useEffect(() => {
        dispatch(getCustomers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message){
            switch (message.type){
                case "DELETE_CUSTOMER_SUCCESS":
                    dispatch({type:"TOGGLE_MODAL"});
                    toast.success(intl.formatMessage({id:"customer.success.delete"}));

                    dispatch(getCustomers());
                    break;
                default:
            }
            dispatch({type:"CLEAR_SUCCESS_MESSAGE"});
        }
    });

    const columns = [
        {
            name: intl.formatMessage({id: "general.name"}),
            selector: "name",
            sortable: true
        },
        ...(userType === userTypes.IDISC_ADMIN ? [
            {
                name: "",
                maxWidth: "100px",
                selector: row => (
                    <button
                        className="button is-small is-danger"
                        title={intl.formatMessage({id: "general.delete"})}
                        onClick={event => deleteCustomer(event, row.id)}
                    >
                            <span className="icon">
                                <FontAwesomeIcon className="" icon={faTrash}/>
                            </span>
                    </button>
                )
            }
        ] : [])
    ];

    const editCustomer = (id, name) => history.push({pathname: `/customers/${id}/edit`, exact: true, state: {customer_name: name}});

    const deleteCustomer = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "message_title.delete"}),
                message: intl.formatMessage({id: "message.delete"}),
                acceptButton: intl.formatMessage({id: "general.delete"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => deleteCustomerHandler(id),
            }
        })
    }

    const deleteCustomerHandler = id => {
        dispatch(deleteCustomerAction(id));
    }

    const createCustomer = () => history.push({pathname: "/customers/create", exact: true});

    return (
        <>
            <div className="box">
                <h4 className="title is-4 has-text-primary">
                    <FormattedMessage id="menu.clients"/>
                </h4>
                <div className="level">
                    <div className="level-left"></div>
                    <div className="level-right">
                        {userType === userTypes.IDISC_ADMIN && (
                            <div className="level-item">
                                <button className="button is-outlined is-primary" onClick={createCustomer}>
                                    <FormattedMessage id="customer.add_customer"/>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="table-container">
                    <Datatable columns={columns} data={customers} onRowClicked={row => editCustomer(row.id, row.name)}/>
                </div>
            </div>
        </>
    )
}
