import * as actionTypes from '../../Actions';

const initialState = {
    invoices: [],
    selectedInvoice: null,
    invoiceHasBeenCreated: false,
    invoiceHasBeenUpdated: false,
    invoiceHasBeenDeleted: false,
    invoiceHasBeenDownloaded: false,
    paginationInfo: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                paginationInfo: action.paginationInfo
            }
        case actionTypes.CREATE_INVOICE:
            return {
                ...state,
                invoiceHasBeenCreated: true
            }
        case actionTypes.RESET_CREATED_INVOICE:
            return {
                ...state,
                invoiceHasBeenCreated: false
            }

        case actionTypes.GET_INVOICE:
            return {
                ...state,
                selectedInvoice: action.payload
            }
        case actionTypes.DELETE_INVOICE:
            return {
                ...state,
                invoiceHasBeenDeleted: true
            }
        case actionTypes.RESET_DELETED_INVOICE:
            return {
                ...state,
                invoiceHasBeenDeleted: false
            }
        case actionTypes.UPDATE_INVOICE:
            return {
                ...state,
                invoiceHasBeenUpdated: true
            }
        case actionTypes.RESET_UPDATED_INVOICE:
            return {
                ...state,
                invoiceHasBeenUpdated: false
            }
        case actionTypes.DOWNLOADED_INVOICE:
            return {
                ...state,
                invoiceHasBeenDownloaded: true
            }
        case actionTypes.RESET_DOWNLOADED_INVOICE:
            return {
                ...state,
                invoiceHasBeenDownloaded: false
            }
        default:
            return state;
    }
}

export default reducer;