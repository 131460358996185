// ** React Imports
import React from "react";

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function IncorrectPayment(props) {
    const id = props.match.params.id;

    // ** Hooks
    const intl = useIntl();
    const history = useHistory();
    const userType = useSelector(state => state.auth.user?.type);

    return (
        <div className="box">
            <div className="content">
                <h1 className="has-text-centered">
                    {intl.formatMessage({id: "task.DRAFT"})}
                </h1>
                <p className="has-text-centered">
                    {intl.formatMessage({id: "task.error.payment"})}
                </p>
                {userType && (
                    <button className="button is-primary is-centered mt-4" onClick={() => history.push(`/tasks/${id}`)}>
                        {intl.formatMessage({id: "task.back_to_project"})}
                    </button>
                )}
            </div>
        </div>
    )
}
