import {axiosInstance} from "../../../../hoc/Axios/Axios";
import * as actionTypes  from "../../../Actions";

export const sendResetPassword = user => dispatch => axiosInstance
    .post("/auth/password/reset", user)
    .then(() => {
        dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS});
    })
    .catch(error => {
        dispatch({ type: actionTypes.RESET_PASSWORD_ERROR, payload: error.response.data.errors });
        dispatch({ type: 'TOGGLE_MODAL' });
    });

export const validateToken = token => dispatch => axiosInstance
    .post("/auth/password/validate", token)
    .then(response => {
        dispatch({ type: actionTypes.VALID_TOKEN, payload: response.data });
        dispatch({ type: 'TOGGLE_MODAL' });
    })
    .catch(error => {
        dispatch({ type: actionTypes.INVALID_TOKEN, payload: { message: error.response.data.message } });
        dispatch({ type: 'TOGGLE_MODAL' });
    });
