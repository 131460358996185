import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';

export const getDepartments = () => {
    return (dispatch, getState) =>{
        return axiosInstance.get("/departments")
        .then(res =>{
            dispatch({ type: actions.GET_DEPARTMENTS, payload: res.data.data})
        }).catch(() => null)
    }
}

export const createDepartment = (data) => {
    return (dispatch) => {
        return axiosInstance.post("/departments/store", data)
            .then(res => {
                dispatch({type: actions.CREATE_DEPARTMENT, payload: res.data})
            }).catch(() => null)
    }
}

export const getDepartment = id => {
    return (dispatch, getState) => {
        return axiosInstance.get("/departments/" + id)
            .then(res => {
                dispatch({type: actions.GET_DEPARTMENT, payload: res.data.data})
            }).catch(() => null);
    };
}

export const updateDepartment = data => {
    return (dispatch, getState) => {
        return axiosInstance.put(`/departments/${data.id}`, data.body)
            .then(res => {
               dispatch({type: actions.UPDATE_DEPARTMENT, payload: res.data})
            })
            .catch(() => null);
    }
}

export const deleteDepartment = id => {
    return (dispatch, getState) => {
        return axiosInstance.delete(`/departments/${id}`)
            .then(res => {
                dispatch({type: actions.DELETE_DEPARTMENT, payload: res.data})
            })
            .catch(() => null);
    }
}
