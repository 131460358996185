// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// ** Icons Imports
import { faDownload, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import { downloadExcelCustomer, getLanguages } from "../../store/reducers/customers/customersActions";
import { getSourceLanguages } from "../../store/reducers/language/languageActions";
import { getRates } from "../../store/reducers/rate/rateActions";

// ** Actions Import
import * as actions from "../../store/Actions";

// ** Components Imports
import RatesTable from "../../components/Customer/RatesTable";

const GeneralRates = () => {
    // ** States
    const [availableSourceLanguages, setAvailableSourceLanguages] = useState([]);
    const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState(undefined);
    const [targetLanguage, setTargetLanguage] = useState(undefined);

    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const rates = useSelector(state => state.rate.rates);
    const languages = useSelector(state => state.customer_assing.languages);
    const sourceLanguages = useSelector(state => state.language.sourceLanguages);
    const message = useSelector(state => state.customer_assing.message);

    useEffect(() => {
        dispatch(getRates());
        dispatch(getLanguages());
        dispatch(getSourceLanguages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAvailableSourceLanguages(sourceLanguages
            .map(language => ({label: intl.formatMessage({id: `language.${language.iso_code}`}), value: language.id}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceLanguages]);

    useEffect(() => {
        setAvailableTargetLanguages(languages
            .map(language => ({label: intl.formatMessage({id: `language.${language.label}`}), value: language.value}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languages]);

    useEffect(() => {
        if (message) {
            switch (message.type) {
                case "UPLOAD_EXCEL_SUCCESS":
                    toast.success(intl.formatMessage({ id: "rate.success.uploadExcel" }));
                    dispatch(getRates());
                    dispatch({ type:actions.HIDE_MODAL });
                    break;
                case "UPDATE_RATE":
                    dispatch(getRates());
                    toast.success(intl.formatMessage({ id: "rate.success.update" }));
                    dispatch({ type:"TOGGLE_MODAL" });
                    break;
                case "CREATE_RATE":
                    dispatch(getRates());
                    toast.success(intl.formatMessage({ id: "rate.success.create" }));
                    dispatch({ type:"TOGGLE_MODAL" });
                    break;
                case actions.DELETE_RATE_SUCCESS:
                    dispatch(getRates());
                    dispatch({ type:actions.HIDE_MODAL });
                    toast.success(intl.formatMessage({ id: "customer.rate.success.delete" }));
                    break;
                default:
            }
            dispatch({ type:"CLEAR_SUCCESS_MESSAGE" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);

    const downloadExcel = event => {
        event.preventDefault();
        let button = document.querySelector("#downloadExcelFile");
        button.classList.add("is-loading");
        button.disabled = true;
        dispatch(downloadExcelCustomer(0));
    };

    const search = () => dispatch(getRates({
        ...(sourceLanguage > 0 && { sourceLanguage }),
        ...(targetLanguage > 0 && { targetLanguage })
    }));

    const createRate = () => dispatch({
        type: actions.SHOW_MODAL,
        payload: { modalType: "createRate", title: intl.formatMessage({ id: "customer.createRate" }) }
    });

    const importRates = () => dispatch({
        type: actions.SHOW_MODAL,
        payload: { modalType: "import_rates", title: intl.formatMessage({ id: "customer.import_rates" }) }
    });

    const filters = () => (
        <section className="custom-box">
            <h4 className="title is-5 has-text-primary">
                <FormattedMessage id="general.filter"/>
            </h4>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="customer.assignLangSource"/>
                        </label>
                        <div className="select is-fullwidth">
                            <select
                                name="sourceLanguage"
                                onChange={event => setSourceLanguage(event.target.value)}
                                value={sourceLanguage}
                            >
                                <option key="0" value={0}>
                                    {intl.formatMessage({ id: "customer.assignLangSource" })}
                                </option>
                                {availableSourceLanguages?.map(language => (
                                    <option key={language.value} value={language.value}>
                                        {language.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="customer.assignLangTarget"/>
                        </label>
                        <div className="select is-fullwidth">
                            <select
                                name="targetLanguage"
                                onChange={event => setTargetLanguage(event.target.value)}
                                value={targetLanguage}
                            >
                                <option key="0" value={0}>
                                    {intl.formatMessage({ id: "customer.assignLangTarget" })}
                                </option>
                                {availableTargetLanguages?.map(language => (
                                    <option key={language.value} value={language.value}>
                                        {language.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="is-flex column is-align-items-flex-end">
                    <button className="button is-info floatingButton" id="uploadExcelFile" onClick={search}>
                        <FormattedMessage id="general.search"/>
                    </button>
                </div>
            </div>
        </section>
    );

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.rates"/>
            </h4>
            <hr/>
            <div className="rate-container">
                {filters()}
                <div className="table-container">
                    <div className="buttons">
                        <button className="button is-outlined is-primary" onClick={createRate}>
                            <FontAwesomeIcon icon={faPlus}/>
                            <span className="ml-3">
                                <FormattedMessage id="rate.create"/>
                            </span>
                        </button>
                        <button className="button is-outlined is-primary" onClick={importRates}>
                            <FontAwesomeIcon icon={faUpload}/>
                            <span className="ml-3">
                                <FormattedMessage id="customer.import_rates"/>
                            </span>
                        </button>
                        <button className="button is-link is-light" id="downloadExcelFile" onClick={downloadExcel}>
                            <FontAwesomeIcon icon={faDownload}/>
                            <span className="ml-3">
                                <FormattedMessage
                                    id={`customer.${rates.length === 0 ? "downloadTemplateExcel" : "exportRates"}`}
                                />
                            </span>
                        </button>
                    </div>
                    <RatesTable rates={rates}/>
                </div>
            </div>
        </div>
    );
}

export default GeneralRates;
