import React, {useEffect} from 'react';
import {getTasks} from "../../store/reducers/task/taskActions"
import {useDispatch, useSelector} from 'react-redux'
import {FormattedMessage, useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import * as userTypes from "../../store/reducers/auth/userTypes";
import * as actions from "../../store/Actions";
import {toast} from "react-toastify";
import {getCurrentUserDepartments} from "../../store/reducers/auth/authActions";
import TaskStateTag from "../../components/Task/TaskStateTag";
import Datatable from "../../components/Datatable/Datatable";
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";

export default function Task() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const tasks = useSelector(state => state.task.tasks);
    const success = useSelector(state => state.task.success);
    const error = useSelector(state => state.task.error);
    const userType = useSelector(state => state.auth.user?.type);
    const locale = useSelector(state => state.locale.locale);
    const { dateFormat } = useDate();
    const price = usePrice();

    useEffect(() => {
        dispatch(getTasks( true));
        dispatch(getCurrentUserDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: "task.success.delete"}));
            dispatch({type: "TOGGLE_MODAL"});
            dispatch({type: actions.CLEAR_TASK_SUCESS});
            history.push({pathname: "/tasks", exact: true});
        } else if (error) {
            toast.success(intl.formatMessage({id: "task.error.delete"}));
            dispatch({type: actions.CLEAR_TASK_ERROR});
        }
    });

    function showAllTasks(e) {
        e.preventDefault();
        history.push({pathname: "/tasks", exact: true});
    }

    const editTask = (taskID) => {
        history.push({pathname: "/tasks/" + taskID, exact: true});
    }

    let TaskTable = () => {
        let column5 = {
            sortable: true
        }
        if(userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM){
            column5 = {
                ...column5,
                name: intl.formatMessage({id: "general.customer"}),
                selector: row => row.user?.customer?.name,
                sortable: true
            }
        } else {
            column5 = {
                ...column5,
                name: intl.formatMessage({id: "task.department"}),
                selector: row => row.department?.title,
                sortable: true
            }
        }
        const columns = [
            {
                name: intl.formatMessage({id: "task.id"}),
                selector: 'id',
                sortable: true,
                maxWidth: "100px",
                minWidth: "100px"
            },
            {
                name: intl.formatMessage({id: "task.name"}),
                selector: 'description',
                sortable: true,
                grow: 2
            },
            {
                name: intl.formatMessage({id: "general.creation_date"}),
                selector: row => dateFormat(row.created_at, locale.name),
                sortable: true,
                grow: 2
            },
            {
                name: intl.formatMessage({id: "task.state"}),
                sortable: true,
                cell: row => (<TaskStateTag task={row} />)
            },
            {
                ...column5
            },
            {
                name: intl.formatMessage({id: "task.requested_by"}),
                selector: row => (
                    row.user?.name + " " + row.user?.lastname ?? intl.formatMessage({id: "general.guest"})
                ),
                sortable: true,
                grow: 1.5
            },
            {
                name: intl.formatMessage({id: "task.invoice"}),
                selector: row => row.invoice?.folio ?? "",
                sortable: true
            },
            {
                name: intl.formatMessage({id: "invoice.amount_taxes_not_included"}),
                selector: row => `${price(row.taxed_price, locale.name)} €`,
                right: true,
                grow: 1.5
            }
        ];
        return (
            <Datatable
                columns={columns}
                data={tasks}
                onRowClicked={row => editTask(row.id)}
                />
        );
    }



    const showAllTasksButton = () => {
        return (<>
            <div className="level-item">
                <button className="button is-outlined is-primary" onClick={(e) => showAllTasks(e)}>
                    <FormattedMessage id="task.show_all"/></button>
            </div>
        </>)
    }

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary"><FormattedMessage id="task.cancelled_tasks"/></h4>
            <div className="level">
                <div className="level-left"/>
                <div className="level-right">
                    {showAllTasksButton()}
                </div>
            </div>
            <hr/>
            <div className="table-container">
                <TaskTable />
            </div>
        </div>
    );
}
