import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import * as userTypes from "../../store/reducers/auth/userTypes";
import {useSelector} from "react-redux";

export default function UserTypeSelect(props) {
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const intl = useIntl();

    const TypeOption = () => {
        var OptionClientAdmin = () => <option
            value={userTypes.CLIENT_ADMIN}>{intl.formatMessage({id: "type.CLIENT_ADMIN"})}</option>;
        var OptionIdiscAdmin = () => <option
            value={userTypes.IDISC_ADMIN}>{intl.formatMessage({id: "type.IDISC_ADMIN"})}</option>
        var OptionIdiscPM = () => <option
            value={userTypes.IDISC_PM}>{intl.formatMessage({id: "type.IDISC_PM"})}</option>;
        var OptionClientUser = () => <option
            value={userTypes.CLIENT_USER}>{intl.formatMessage({id: "type.CLIENT_USER"})}</option>;

        switch (userType) {
            case userTypes.IDISC_ADMIN:
                if (props.from==="customer"){
                    return (<>
                        <OptionClientAdmin/>
                    </>);
                } else {
                    return (<>
                        <OptionClientAdmin/>
                        <OptionIdiscAdmin/>
                        <OptionIdiscPM/>
                    </>)
                }
            case userTypes.CLIENT_ADMIN:
                return (<>
                    <OptionClientAdmin/>
                    <OptionClientUser/>
                </>)
            default:
                return (<>
                </>)
        }
    }

    return (
        <>
            <label className="label"><FormattedMessage id="general.type"/></label>
            <div className="select is-fullwidth">
                <select
                    // defaultValue={props.value}
                    value={props.value}
                    onChange={(e) => props.onChange(e)}
                    ref={props.register}
                    name={props.name}>
                    <TypeOption/>
                </select>
            </div>
        </>
    )
}
