// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalInfoCustomQuote = props => {
    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="content">
                        <p>
                            <FormattedMessage id="general.info_custom_quote_1"/>
                        </p>
                        <p>
                            <FormattedMessage id="general.info_custom_quote_2"/>
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage id="general.info_custom_quote_3"/>
                            </li>
                            <li>
                                <FormattedMessage id="general.info_custom_quote_4"/>
                            </li>
                            <li>
                                <FormattedMessage id="general.info_custom_quote_5"/>
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage id="general.info_custom_quote_6"/>
                        </p>
                        <p className="has-text-right">
                            <a href="https://www.idisc.com/es/xpress/procesos" rel="noopener noreferrer" target="_blank">
                                <FormattedMessage id="general.info_more_info"/>...
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ModalHOC(ModalInfoCustomQuote);
