import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';

export const getAvailableLanguages = () => {
    return dispatch => {
        return axiosInstance.get("/languages/available")
            .then(res => {
                dispatch({type: actions.GET_AVAILABLE_LANGUAGES, payload: res.data.data})
            }).catch(error => {
            })
    }
}

export const getLanguages = (data) => {
    return dispatch => {
        return axiosInstance.get("/languages", {
            params: data
        })
            .then(res => {
                dispatch({type: actions.GET_ALL_LANGUAGES, payload: res.data.data})
            }).catch(error => {
            })
    }
}

export const getSourceLanguages = () => {
    return dispatch => {
        return axiosInstance.get("/languages/sources")
            .then(res => {
                dispatch({type: actions.GET_SOURCE_LANGUAGES, payload: res.data.data})
            }).catch(() => null)
    }
}

export const updateIsSourceLanguage = (id) => {
    return (dispatch, getState) => {
        var url = "/languages/" + id + "/source";
        return axiosInstance.put(url)
            .then(res => {
            }).catch(() => null)
    }
}
