// ** React Imports
import React, { useEffect } from "react";

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// ** Store Imports
import { getTask, verifyPayment } from "../../../store/reducers/task/taskActions";

// ** Actions Import
import * as Actions from "../../../store/Actions";

export default function CorrectPayment(props) {
    const id = props.match.params.id;

    // ** Hooks
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const task = useSelector(state => state.task.task);
    const hasBeenPayed = useSelector(state => state.task.hasBeenPayed);

    useEffect(() => {
        dispatch(getTask(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (task !== null) {
            dispatch({type: Actions.SHOW_MODAL, payload: {modalType: "loading"}})
            dispatch({type: Actions.SET_PAYMENT_DONE, payload: null})

            const params = new URLSearchParams(props.location.search);
            const formData = new FormData();
            formData.append("merchantParameters", JSON.stringify({
                Ds_SignatureVersion: params.get("Ds_SignatureVersion"),
                Ds_MerchantParameters: params.get("Ds_MerchantParameters"),
                Ds_Signature: params.get("Ds_Signature")
            }));
            formData.append("taskId", id);
            dispatch(verifyPayment(formData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task])

    useEffect(() => {
        if (hasBeenPayed !== null) dispatch({type: Actions.HIDE_MODAL})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasBeenPayed])

    return (
        <div className="box">
            <div className="content">
                <h1 className="has-text-centered">
                    {intl.formatMessage({id: `task.${hasBeenPayed ? 'APPROVED' : 'DRAFT'}`})}
                </h1>
                {hasBeenPayed && (
                    <p className="has-text-centered">
                        {intl.formatMessage({id: "task.name"})}: {task.description}
                    </p>
                )}
                <p className="has-text-centered">
                    {intl.formatMessage({id: `task.APPROVED.${hasBeenPayed ? 'success' : 'fail'}`})}
                </p>
                <button className="button is-primary is-centered mt-4" onClick={() => history.push(`/tasks/${id}`)}>
                    {intl.formatMessage({id: "task.back_to_project"})}
                </button>
            </div>
        </div>
    )
}
