import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import {disableUser, getAllUser, getUsersFromCustomer} from '../../store/reducers/user/userActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, useIntl} from 'react-intl';
import * as userTypes from "../../store/reducers/auth/userTypes"
import {toast} from "react-toastify";
import * as actions from "../../store/Actions";
import Datatable from "../../components/Datatable/Datatable";

export default function Users() {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const users = useSelector(state => state.user.users);
    const authUser = useSelector(state => state.auth.user);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const hasUserBeenDeleted = useSelector(state => state.user.userDeleted);

    //Obtener los usuarios
    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasUserBeenDeleted) {
            dispatch({type: 'TOGGLE_MODAL'});
            toast.success(intl.formatMessage({id: "message.user_deleted"}));
            dispatch({type: actions.USER_DELETED, payload: {status: false}})
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenDeleted]);

    const columns = [
        {
            name: intl.formatMessage({id: "general.name"}),
            selector: "name",
            sortable: true
        },
        {
            name: intl.formatMessage({id: "user.lastname"}),
            selector: "lastname",
            sortable: true
        },
        {
            name: intl.formatMessage({id: "general.type"}),
            selector: row => intl.formatMessage({id: "type." + row.type}),
            sortable: true
        },
        {
            name: "",
            maxWidth: "100px",
            selector: row => (
                <button
                    className="button is-small is-danger"
                    onClick={event => showConfirmation(event, row.id)}
                    title={intl.formatMessage({id: "general.delete"})}
                >
                    <span className="icon">
                        <FontAwesomeIcon icon={faTrash}/>
                    </span>
                </button>
            )
        }
    ];

    function getUsers(){
        if (userType === userTypes.CLIENT_ADMIN) {
            dispatch(getUsersFromCustomer(authUser.customer_id));
        } else if (userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) {
            dispatch(getAllUser());
        }
    }

    const createUser = event => {
        event.preventDefault();
        history.push({pathname: "/users/create", exact: true});
    }

    const onRowClicked = row =>{
        history.push(`/users/${row.id}/edit`);
        dispatch({type: "SET_SELECTED_USER", payload: row});
    }

    const deleteUser = (id) => {
        dispatch({type: 'TOGGLE_MODAL'})
        dispatch({type: 'TOGGLE_MODAL', payload: {modalType: "loading"}})
        dispatch(disableUser(id));
    }

    const showConfirmation = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        if (id === authUser.id) {
            toast.error(intl.formatMessage({id: "error.cannot_delete_current_user"}))
            return;
        }

        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                title: intl.formatMessage({id: "message_title.delete"}),
                message: intl.formatMessage({id: "message.delete"}),
                acceptButton: intl.formatMessage({id: "general.delete"}),
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => deleteUser(id),
            }
        })
    }

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.users"/>
            </h4>
            <div className="level">
                <div className="level-left"/>
                {userType === userTypes.CLIENT_ADMIN && (
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-outlined is-primary" onClick={createUser}>
                                <FormattedMessage id="user.add_user"/>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className="table-container">
                <Datatable columns={columns} data={users} onRowClicked={row => onRowClicked(row)}/>
            </div>
        </div>
    )
}
