import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { uploadExcel, downloadExcelCustomer, getRate } from "../../store/reducers/customers/customersActions";
import * as action from "../../store/Actions";
import CustomerRatesEnabledTable from "./CustomerRatesEnabledTable";

let CustomerAddRatesByExcel = props => {
    const [file,setFile] = useState(null);
    const { register, errors, handleSubmit, reset } = useForm({ mode: "onChange" });
    const dispatch = useDispatch();
    const intl = useIntl();
    const message = useSelector(state => state.customer_assing.message);
    const error = useSelector(state => state.customer_assing.error);
    const rates = useSelector(state => state.customer_assing.rates);

    useEffect(() => {
        dispatch(getRate(props.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (error) {
            dispatch({type:"CLEAR_ERROR"});
            switch (error.type) {
                case "UPLOAD_EXCEL_ERROR":
                    toast.error(intl.formatMessage({id:"rate.error.uploadExcel"}));
                    let button = document.querySelector("#uploadExcelFile");
                    button.classList.remove("is-loading");
                    button.disabled = false;
                    break;
                default:
            }
        }

        if (message) {
            switch (message.type) {
                case "UPLOAD_EXCEL_SUCCESS":
                    toast.success(intl.formatMessage({id:"rate.success.uploadExcel"}));
                    let button = document.querySelector("#uploadExcelFile");
                    button.classList.remove("is-loading");
                    button.disabled = false;
                    reset("excel");
                    dispatch(getRate(props.id));
                    dispatch({type:action.HIDE_MODAL});
                    break;
                case action.DELETE_RATE_SUCCESS:
                    dispatch(getRate(props.id));
                    dispatch({type:action.HIDE_MODAL});
                    toast.success(intl.formatMessage({id:"customer.rate.success.delete"}));
                    break;
                case "UPDATE_RATE":
                    dispatch(getRate(props.id));
                    toast.success(intl.formatMessage({id:"rate.success.update"}));
                    dispatch({type:"TOGGLE_MODAL"});
                    break;
                case "CREATE_RATE":
                    dispatch(getRate(props.id));
                    toast.success(intl.formatMessage({id:"rate.success.create"}));
                    dispatch({type:"TOGGLE_MODAL"});
                    break;
                default:
            }
            dispatch({type:"CLEAR_SUCCESS_MESSAGE"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error, message]);

    const onChangeHandler = event => {
        if (event.target.files[0]) setFile(event.target.files[0]);
    }

    const submitHandler = () => {
        const data = new FormData();
        data.append('file', file);
        if (props?.modal) {
            let button = document.querySelector("#uploadExcelFile");
            button.classList.add("is-loading");
            button.disabled = true;
        }
        dispatch(uploadExcel(props.id,data,props?.modal));
    }

    const downloadExcel = event => {
        event.preventDefault();
        if (typeof props.id!=="undefined"){
            let button = document.querySelector("#downloadExcelFile");
            button.classList.add("is-loading");
            button.disabled = true;
            dispatch(downloadExcelCustomer(props.id));
        }
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <input type="file" name="excel"
                               className="input has-icons-right"
                               ref={register({
                                   required:true,
                                   validate: {
                                       mimeTypeCase: (files) => {
                                           if(files.length>0){
                                               if (files[0].type==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                               || files[0].type==="application/vnd.ms-excel"){
                                                   return true;
                                               }
                                           }
                                           return false;
                                       },
                                   }
                               })}
                               onChange={onChangeHandler}/>
                        {errors.excel&&errors.excel.type==="required"&&(<p className="error">El campo es requerido</p>)}
                        {errors.excel&&errors.excel.type==="mimeType"&&(<p className="error">{errors.excel.message}</p>)}
                        {errors.excel&&errors.excel.type==="mimeTypeCase"&&(<p className="error">El campo no coincide</p>)}
                    </div>
                </div>
                <div className="is-flex column is-3">
                    <button id="uploadExcelFile" className="button is-info floatingButton" type="submit">
                        <FormattedMessage id="general.upload"/>
                    </button>
                </div>
            </div>

            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <button id="downloadExcelFile" className="button is-link is-light" onClick={downloadExcel}>
                            <FontAwesomeIcon className="" icon={faDownload}/>
                            <span className="ml-3">
                                {rates.length === 0 ?
                                    (<FormattedMessage id="customer.downloadTemplateExcel"/>):
                                    (<FormattedMessage id="customer.exportRates"/>)
                                }
                            </span>
                        </button>
                    </div>
                </div>
            </div>
           <CustomerRatesEnabledTable rates={rates} customer_id={props.customer_id}/>
        </form>
    );
}

export default connect()(CustomerAddRatesByExcel);
