import * as actionTypes from '../../Actions';
import Spanish from "../../../lang/es.json";
import English from "../../../lang/en.json";
import Catalan from "../../../lang/ca.json";

const initialState = {
    locale: {
        "name": "en",
        "object": English,
    },
    locales: [
        {
            "name": "es",
            "object": Spanish,
        },
        {
            "name": "en",
            "object": English,
        },
        {
            "name": "ca",
            "object": Catalan,
        }]
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOCALE:
            let userLang = action.payload;
            let locale;
            switch (userLang) {
                case "es":
                    locale = {
                        "name": "es",
                        "object": Spanish,
                    }
                    break;
                case "en":
                    locale = {
                        "name": "en",
                        "object": English,
                    }
                    break;
                case "ca":
                    locale = {
                        "name": "ca",
                        "object": Catalan,
                    }
                    break;
                default:
                    locale = {
                        "name": "en",
                        "object": English,
                    }
                    break;
            }
            return {
                ...state,
                locale: locale
            }
        default:
            return state;
    }
}

export default reducer;
