import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';

export const getUsers = () => {
    return (dispatch, getState) => {
        var url = "/users";
        return axiosInstance.get(url)
            .then(res => {
                dispatch({ type: actions.GET_USERS, payload: res.data.data })
            }).catch(error => {

            })
    }
}

export const validateUsers = (customer) => {
    return (dispatch, getState) => {
        var url = "/users/validate";
        return axiosInstance.post(url,customer)
            .then(res => {
                dispatch({ type: actions.VALID_USERS, payload: customer })
            }).catch(error => {
                dispatch({ type: actions.VALID_USERS_ERROR, payload: error.response.data })
            })
    }
}

export const getUsersFromCustomer = (customerId) => {
    return (dispatch, getState) => {
        var url = "/customers/"+ customerId + "/users";
        return axiosInstance.get(url)
            .then(res => {
                //
                dispatch({ type: actions.GET_USERS, payload: res.data.data.users })
            }).catch(error => {

            })
    }
}

export const getAllUser = (id) => {
    var url = "/users/" + id;
    return (dispatch, getState) => {
        return axiosInstance.get(url)
            .then(res => {
                //
                dispatch({ type: actions.SET_SELECTED_USER, payload: res.data.data })
            }).catch(error => {

            })
    }
}

export const storeUser = (user) => {
    return (dispatch, getState) => {
        return axiosInstance.post("/users/store", user)
            .then(res => {
                dispatch({ type: actions.USER_CREATED, payload: {status: true} })
            }).catch(error => {
                //
                dispatch({ type: actions.USER_CREATED_ERROR, payload: error.response.data})
                dispatch({type: 'TOGGLE_MODAL'});
            })
    }
}

export const updateUser = (user, isProfile = false) => dispatch => axiosInstance
    .put(`/users/${user.id}`, user)
    .then(response => {
        dispatch({ type: actions.USER_UPDATED, payload: { status: true } })
        if (isProfile) dispatch({ type: actions.SET_USER, payload: response.data.data })
    })
    .catch(() => null)

export const updatePassword = (user) => {
    return (dispatch, getState) => {
        var url = "/users/" + user.id + "/changePassword";
        return axiosInstance.put(url, user)
            .then(res => {
                dispatch({ type: actions.USER_UPDATED, payload: {status: true} })
                dispatch({
                    type: "UPDATE_PASSWORD_STATUS",
                    payload: {
                        password: user.password,
                        password_confirmation: user.password_confirmation,
                        status: ""
                    }
                });
            }).catch(error => {
                dispatch({ type: actions.USER_UPDATED_ERROR, payload: error.response.data })
            })
    }
}

export const disableUser = (id) => {
    return (dispatch, getState) => {
        var url = "/users/" + id;
        return axiosInstance.delete(url)
            .then(res => {
                dispatch({ type: actions.USER_DELETED, payload: {status: true} })
            }).catch(error => {

            })
    }
}

export const getPaymentScheme = () => {
    return dispatch => {
        return axiosInstance.get("/users/payment-scheme")
            .then(res => {

                dispatch({ type: actions.GET_PAYMENT_SCHEME, payload: res.data.data})
            }).catch(error => {

            })
    }
}

export const changeLanguage = (user, language) => {
    return (dispatch, getState) => {
        var url = "/users/" + user.id + "/language";
        return axiosInstance.post(url, language)
            .then(res => {
                dispatch({ type: actions.SET_LOCALE, payload: res.data.data })
                dispatch({ type: actions.SET_USER_LOCALE, payload: res.data.data })
            }).catch(error => {

            })
    }
}
