// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import {getRates} from "../../store/reducers/rate/rateActions";
import {getRate,getRateEnable} from "../../store/reducers/customers/customersActions";
import {getLanguages} from "../../store/reducers/customers/customersActions";
import {getSourceLanguages} from "../../store/reducers/language/languageActions";

// ** Components Imports
import RatesTable from "../../components/Customer/RatesTable";

const Rates = () => {
    // ** States
    const [availableSourceLanguages, setAvailableSourceLanguages] = useState([]);
    const [availableTargetLanguages, setAvailableTargetLanguages] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState(undefined);
    const [targetLanguage, setTargetLanguage] = useState(undefined);

    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const rates = useSelector(state => state.customer_assing.rates);
    const generalRates = useSelector(state => state.customer_assing.generalRates);
    const languages = useSelector(state => state.customer_assing.languages);
    const sourceLanguages = useSelector(state => state.language.sourceLanguages);
    const authUser = useSelector(state => state.auth.user);

    useEffect(() => {
        if (authUser?.customer_id) {
            dispatch(getRate(authUser.customer_id));
            dispatch(getRateEnable(authUser.customer_id));
        } else dispatch(getRates());
        dispatch(getLanguages());
        dispatch(getSourceLanguages());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAvailableSourceLanguages(sourceLanguages
            .map(language => ({label: intl.formatMessage({id: `language.${language.iso_code}`}), value: language.id}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceLanguages]);

    useEffect(() => {
        setAvailableTargetLanguages(languages
            .map(language => ({label: intl.formatMessage({id: `language.${language.label}`}), value: language.value}))
            .sort((a, b) => a.label.localeCompare(b.label))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languages]);

    const onClick = () => {
        if (authUser?.customer_id) {
            dispatch(getRate(authUser.customer_id, {
                ...(sourceLanguage > 0 && { source_language: sourceLanguage }),
                ...(targetLanguage > 0 && { target_language: targetLanguage })
            }));
            dispatch(getRateEnable(authUser.customer_id, {
                ...(sourceLanguage > 0 && { source_language: sourceLanguage }),
                ...(targetLanguage > 0 && { target_language: targetLanguage })
            }));
        } else dispatch(getRates({
            ...(sourceLanguage > 0 && { sourceLanguage }),
            ...(targetLanguage > 0 && { targetLanguage })
        }));
    }

    const filters = () => (
        <section className="custom-box">
            <h4 className="title is-5 has-text-primary">
                <FormattedMessage id="general.filter"/>
            </h4>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="customer.assignLangSource"/>
                        </label>
                        <div className="select is-fullwidth">
                            <select
                                name="sourceLanguage"
                                onChange={event => setSourceLanguage(event.target.value)}
                                value={sourceLanguage}
                            >
                                <option key="0" value={0}>
                                    {intl.formatMessage({ id: "customer.assignLangSource" })}
                                </option>
                                {availableSourceLanguages?.map(language => (
                                    <option key={language.value} value={language.value}>
                                        {language.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="customer.assignLangTarget"/>
                        </label>
                        <div className="select is-fullwidth">
                            <select
                                name="targetLanguage"
                                onChange={event => setTargetLanguage(event.target.value)}
                                value={targetLanguage}
                            >
                                <option key="0" value={0}>
                                    {intl.formatMessage({id: "customer.assignLangTarget"})}
                                </option>
                                {availableTargetLanguages?.map(language => (
                                    <option key={language.value} value={language.value}>
                                        {language.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="is-flex column is-align-items-flex-end">
                    <button className="button is-info floatingButton" id="uploadExcelFile" onClick={onClick}>
                        <FormattedMessage id="general.search"/>
                    </button>
                </div>
            </div>
        </section>
    );

    return (
        <div className="box">
            <h4 className="title is-4 has-text-primary">
                <FormattedMessage id="menu.rates"/>
            </h4>
            <hr/>
            {filters()}
            <div className="table-container">
                <RatesTable rates={([...generalRates,...rates]).filter(rate => {
                    let item2 = ([...generalRates,...rates]).find(rate2 =>
                        rate.source_lang === rate2.source_lang &&
                        rate.target_lang === rate2.target_lang &&
                        rate.from !== rate2.from
                    );
                    if (item2) {
                        return rate.from !== 'general';
                    }
                    return true;
                })} />
            </div>
        </div>
    );
}

export default Rates;
