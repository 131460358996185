// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// ** Store Imports
import { getIDCP, getMemoQProjectId, sendCustomerProyectAssign } from "../../store/reducers/customers/customersActions";

// ** Actions Import
import * as action from "../../store/Actions";

let AssignProject = (props) =>{
    const id = props.id;

    // ** State
    const [loading, setLoading] = useState(false);

    // ** Hooks
    const dispatch = useDispatch();
    const intl = useIntl();
    const memoqProjectId = useSelector(state => state.customer_assing.memoqProjectId);
    const error = useSelector(state => state.customer_assing.error);
    const message = useSelector(state => state.customer_assing.message);

    useEffect(() => {
        dispatch(getIDCP(id));
        dispatch(getMemoQProjectId(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (error) {
            dispatch({type:"CLEAR_ERROR"});
            switch (error.type) {
                case "RETRIVE_LANGUAGES_ERROR":
                    toast.error(intl.formatMessage({id: "customer.error.getLanguages"}));
                    break;
                case "RETRIVE_MEMOQ_ERROR":
                    toast.error(intl.formatMessage({id: "customer.error.getMemoQP"}));
                    break;
                case "RETRIVE_IDCP_ERROR":
                    toast.error(intl.formatMessage({id: "customer.error.getIDCPP"}));
                    break;
                case "ASSIGN_PROJECTS_ERROR":
                    toast.error(intl.formatMessage({id: "customer.error.assignProjects"}));
                    break;
                default:
                    toast.error(intl.formatMessage({id: "error.general"}));
            }
        } else if (message) {
            switch (message.type) {
                case "ASSIGN_PROJECTS_SUCCESS":
                    toast.success(intl.formatMessage({id: "customer.success.assignProjects"}));
                    dispatch({type:action.HIDE_MODAL});
                    break;
                default:
            }
            dispatch({type:"CLEAR_SUCCESS_MESSAGE"});
        }
    });

    const submitHandler = () => {
        setLoading(true);
        dispatch(sendCustomerProyectAssign({ memoq_project_id: memoqProjectId }, id)).finally(() => setLoading(false));
    }

    return (
        <div className="">
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="customer.MemoQ_project_id"/>
                        </label>
                        <div className="control">
                            <input
                                className="input"
                                name="memoqProjectId"
                                onChange={event => {
                                    dispatch({type: "RETRIVE_MEMOQ_PROJECT_ID_SUCCESS", payload: event.target.value});
                                }}
                                value={memoqProjectId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="columns">
                <div className="is-flex column is-3">
                    <button
                        className={`button floatingButton is-info${loading ? " is-loading" : ""}`}
                        onClick={submitHandler}
                    >
                        <FormattedMessage id="general.save"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect()(AssignProject);
