import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import * as userTypes from "../store/reducers/auth/userTypes"

export default function Menu({ rates }) {
    const selectedMenu = useSelector(state => state.menu.selectedMenu);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const dispatch = useDispatch();
    useEffect(() => {
        setActiveMenu();
    });

    const setActiveMenu = () => {
        let listElements = document.getElementsByClassName('list-element');
        for (let element of listElements) {
            if (element.id === selectedMenu) element.classList.add("is-active");
            else element.classList.remove("is-active");
        }
    };

    const TasksMenu = () => (
        <>
            <p className="menu-label">
                <FormattedMessage id="menu.tasks"/>
            </p>
            <ul className="menu-list">
                <li>
                    <Link
                        className="list-element"
                        id="menu_tareas"
                        onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_tareas"}})}
                        to="/tasks"
                    >
                        <FormattedMessage id="menu.tasks"/>
                    </Link>
                </li>
                {userType === userTypes.CLIENT_ADMIN && (
                    <>
                        <li>
                            <Link
                                className="list-element"
                                id="menu_glossaries"
                                onClick={() =>
                                    dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_glossaries"}})
                                }
                                to="/glossaries"
                            >
                                <FormattedMessage id="menu.glossaries"/>
                            </Link>
                        </li>
                        {!!rates && (
                            <li>
                                <Link
                                    className="list-element"
                                    id="menu_tarifas"
                                    onClick={() =>
                                        dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_tarifas"}})
                                    }
                                    to="/rates"
                                >
                                    <FormattedMessage id="menu.rates"/>
                                </Link>
                            </li>
                        )}
                    </>
                )}
            </ul>
        </>
    );

    const CustomersMenu = () => {
        if (userType === userTypes.IDISC_ADMIN || userType === userTypes.IDISC_PM) return (
            <>
                <p className="menu-label">
                    <FormattedMessage id="menu.clients"/>
                </p>
                <ul className="menu-list">
                    <li>
                        <Link
                            className="list-element"
                            id="menu_clientes"
                            onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_clientes"}})}
                            to="/customers"
                        >
                            <FormattedMessage id="menu.clients"/>
                        </Link>
                    </li>
                </ul>
            </>
        );

        return null;
    }

    const RatesMenu = () => {
        if (userType === userTypes.IDISC_ADMIN) return (
            <>
                <p className="menu-label">
                    <FormattedMessage id="menu.rates"/>
                </p>
                <ul className="menu-list">
                    <li>
                        <Link
                            className="list-element"
                            id="menu_languages"
                            onClick={() =>
                                dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_languages"}})
                            }
                            to="/languages"
                        >
                            <FormattedMessage id="menu.languages"/>
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="list-element"
                            id="menu_rates"
                            onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_rates"}})}
                            to="/general/rates"
                        >
                            <FormattedMessage id="menu.rate_menu"/>
                        </Link>
                    </li>
                </ul>
            </>
        );

        return null;
    }

    const UsersMenu = () => {
        if (userType === userTypes.CLIENT_ADMIN) return (
            <>
                <p className="menu-label">
                    <FormattedMessage id="menu.my_users"/>
                </p>
                <ul className="menu-list">
                    <li>
                        <Link
                            className="list-element"
                            id="menu_usuarios"
                            onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_usuarios"}})}
                            to="/users"
                        >
                            <FormattedMessage id="menu.my_users"/>
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="list-element"
                            id="menu_departamentos"
                            onClick={() =>
                                dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_departamentos"}})
                            }
                            to="/departments"
                        >
                            <FormattedMessage id="menu.departments"/>
                        </Link>
                    </li>
                </ul>
            </>
        );

        return null;
    }

    const AccountMenu = () => (
        <>
            <p className="menu-label">
                <FormattedMessage id="menu.my_account"/>
            </p>
            <ul className="menu-list">
                <li>
                    <Link
                        className="list-element"
                        id="menu_cuenta"
                        onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_cuenta"}})}
                        to="/account"
                    >
                        <FormattedMessage id="menu.my_account"/>
                    </Link>
                </li>
                {(userType === userTypes.CLIENT_ADMIN || userType === userTypes.CLIENT_USER) && (
                    <li>
                        <Link
                            className="list-element"
                            id="menu_company"
                            onClick={() => dispatch({type: 'CHANGE_MENU', payload: {from: "menu", to: "menu_company"}})}
                            to="/company"
                        >
                            <FormattedMessage id="menu.my_company"/>
                        </Link>
                    </li>
                )}
            </ul>
        </>
    )

    const hideMenu = (e) => {
        e.preventDefault();
        let element = document.querySelector("aside.menu");
        if (element.style.display === 'none'){
            element.style.display = "block";
            document.querySelector(".burger.burger-left").classList.add("is-active");
            element.closest('.columns').querySelector("div:first-child").classList.remove("is-1");
            element.closest('.columns').querySelector("div:first-child").classList.add("is-3");
            element.closest('.columns').querySelector("div:nth-child(2)").classList.remove("is-11");
            element.closest('.columns').querySelector("div:nth-child(2)").classList.add("is-9");
        } else {
            element.style.display = "none";
            document.querySelector(".burger.burger-left").classList.remove("is-active");
            element.closest('.columns').querySelector("div:first-child").classList.add("is-1");
            element.closest('.columns').querySelector("div:first-child").classList.remove("is-3");
            element.closest('.columns').querySelector("div:nth-child(2)").classList.add("is-11");
            element.closest('.columns').querySelector("div:nth-child(2)").classList.remove("is-9");
        }
    }

    let contentToLoad;
    if (userType === userTypes.invitado) {
        contentToLoad = (
            <></>
        );
    } else {
        contentToLoad = (
            <section>
                <a className="navbar-burger burger burger-left is-active" href="/#" onClick={hideMenu} role="button">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                <aside className="menu is-hidden-mobile">
                    <TasksMenu/>
                    <CustomersMenu/>
                    <RatesMenu/>
                    <UsersMenu/>
                    <AccountMenu/>
                </aside>
            </section>
        )
    }


    return contentToLoad;
}
