import * as actionTypes from "../../Actions";

const initialState = {
    success: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_OTHER_CONCEPT:
            return {
                ...state,
                success: action.payload
            }
        case actionTypes.UPDATE_OTHER_CONCEPT:
            return {
                ...state,
                success: action.payload
            }
        case actionTypes.DELETE_OTHER_CONCEPT:
            return {
                ...state,
                success: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
