import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const ModalHOC = (WrappedComponent) => {
    const NewComponent = (props) => {
        const showModal = useSelector(state => state.modal.showModal);
        const title = useSelector(state => state.modal.title);
        const message = useSelector(state => state.modal.message);
        const cancelButton = useSelector(state => state.modal.cancelButton);
        const acceptButton = useSelector(state => state.modal.acceptButton);
        const acceptButtonClass = useSelector(state => state.modal.acceptButtonClass);
        const acceptButtonMethod = useSelector(state => state.modal.acceptButtonMethod);
        const cancelButtonClass = useSelector(state => state.modal.cancelButtonClass);
        const extraData = useSelector(state => state.modal.extraData);
        const optionButtons = useSelector(state => state.modal.optionButtons);
        const dispatch = useDispatch();

        useEffect(() => {
            toggleModal();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showModal]);

        const toggleModal = () => {
            var modal = document.getElementById("modal");

            if (showModal) {
                modal.classList.add("is-active");
            } else {
                modal.classList.remove("is-active");
                //clear customer assign projects store if is assign
                if(props.modalType==="assignProjects") {
                    dispatch({type: "CLEAR_ASSIGN_DATA"});
                }
            }
        }

        const closeModal = () => {
            //dispatch({ type: 'TOGGLE_MODAL', payload: "" });
            dispatch({ type: 'TOGGLE_MODAL', payload: "" });
        }

        return (
            <WrappedComponent
                id="modal"
                message={message}
                title={title}
                cancelButton={cancelButton}
                acceptButton={acceptButton}
                acceptButtonClass={acceptButtonClass}
                cancelButtonClass={cancelButtonClass}
                extraData={extraData}
                acceptButtonMethod={acceptButtonMethod}
                closeModal={closeModal}
                optionButtons={optionButtons}>

            </WrappedComponent>
        )
    }

    return NewComponent;
}
export default ModalHOC;


