import * as actionTypes from '../../Actions';

const initialState = {
    users: [],
    selectedUser: {},
    userCreated: false,
    userUpdated: false,
    userDeleted: false,
    passwordChangedStatus: "",
    validateUser:null,
    error:null,
    payment_scheme: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case actionTypes.SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            }
        case actionTypes.RESET_SELECTED_USER:
            return {
                ...state,
                selectedUser: {}
            }
        case actionTypes.USER_CREATED:
            return {
                ...state,
                userCreated: action.payload.status
            }
        case actionTypes.USER_CREATED_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case actionTypes.USER_UPDATED:
            return {
                ...state,
                userUpdated: action.payload.status
            }
        case actionTypes.USER_DELETED:
            return {
                ...state,
                userDeleted: action.payload.status
            }
        case actionTypes.UPDATE_PASSWORD_STATUS:
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    password: action.payload.password,
                    password_confirmation: action.payload.confirmedPassword
                },
                passwordChangedStatus: action.payload.status
            }
        case actionTypes.USER_UPDATED_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case actionTypes.VALID_USERS:
            return {
                ...state,
                validateUser: action.payload
            }
        case actionTypes.VALID_USERS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case "CLEAR_ERROR":
            return {
                ...state,
                error: null
            }
        case "CLEAR_VALID_USER":
            return {
                ...state,
                validateUser: null
            }
        case actionTypes.GET_PAYMENT_SCHEME:
            return {
                ...state,
                payment_scheme: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
