// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Store Imports
import { getRates, enableRateByCustomer } from "../../store/reducers/rate/rateActions";

// ** Components Imports
import Datatable from "../Datatable/Datatable";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";

const RatesTablesSelection = (props) => {
    const customer_id = props.customer_id;

    // ** State
    const [localRate,setLocalRate] = useState([]);

    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const rates = useSelector(state => state.rate.rates);
    const locale = useSelector(state => state.locale.locale);
    const { dateTimeFormat } = useDate();
    const price = usePrice();

    useEffect(()=>{
        dispatch(getRates({"customer_id":customer_id}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        setLocalRate(rates);
    },[rates]);

    const enableRate = (e, idRate) => {
        dispatch(enableRateByCustomer(idRate,customer_id));
    }

    const RatesDatatable = () => {
        let columns = [
            {
                name: intl.formatMessage({id: "customer.assignLangSource"}),
                selector: row => intl.formatMessage({id: "language." + row?.source_language?.iso_code??""}),
                sortable: true
            },
            {
                name: intl.formatMessage({id: "customer.assignLangTarget"}),
                selector: row => intl.formatMessage({id: "language." + row?.target_language?.iso_code??""}),
                sortable: true
            },
            {
                name: intl.formatMessage({id: "customer.r1"}),
                selector: row => `${price(row.r1, locale.name, 4)} €`,
                sortable: true,
                right: true
            },
            {
                name: intl.formatMessage({id: "customer.r2"}),
                selector: row => `${price(row.r2, locale.name)} €`,
                sortable: true,
                right: true
            },
            {
                name: intl.formatMessage({id: "customer.lastUpdateDate"}),
                selector: row => row.updated_at_format ?? dateTimeFormat(row.updated_at, locale.name),
                sortable: true
            },{
                name: "",
                selector: row => (
                    <>
                        <input key={row.id} id={row.id} type="checkbox" onClick={(e)=>enableRate(e,row.id)}/>
                    </>
                )
            }
        ];

        return (
            <Datatable columns={columns} data={localRate} pointerOnHover={false}/>
        )
    }


    return (
        <>
            <RatesDatatable />
        </>
    )
}

export default RatesTablesSelection;
