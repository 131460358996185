// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector, connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// ** Icons Imports
import { faShare, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import {sendResetLink} from "../../store/reducers/auth/sendResetPassword/sendResetPasswordActions";

// ** Actions Import
import * as actions from "../../store/Actions";

let SendResetPasswordMail= () => {
    // ** States
    const [email, setEmail] = useState("");

    // ** Hooks
    const { register, errors, handleSubmit, reset } = useForm();
    const intl = useIntl();
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const isSendResetLink = useSelector(state => state.sendresetPassword.isSendLinkReset);
    const message = useSelector(state => state.sendresetPassword.message);

    useEffect(() => {
        if (isSendResetLink) {
            setEmail("");
            reset("");
            dispatch({type: actions.TOGGLE_MODAL});
            dispatch({type: actions.RESET_SENDLINK, payload: {isSendResetLink: false}});
            toast.success(message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSendResetLink]);

    const onSubmit = () => {
        dispatch({type: actions.TOGGLE_MODAL, payload: {modalType: "loading"}})

        let lang = locale.name;
        if (!lang) lang = navigator.language.split("-")[0];

        dispatch(sendResetLink({email, lang}));
    }

    const renderError = () =>{
        if (errors.email && errors.email.type === "required"){
            return (
                <p className="error"><FormattedMessage id="customer.required_camp" /></p>
            );
        }

        if (errors.email && errors.email.type === "emailCase"){
            return (<p className="error"><FormattedMessage id="error.email_format" /></p>);
        }
    }

    return (
        <section className="hero is-fullheight" style={{backgroundImage: `url("${require("../../assets/idisc-xpress.jpg")}` }}>
            <div className="hero-body">
                <div className="container" style={{opacity: 1.2}}>
                    <div className="column is-6 is-offset-3">
                        <div className="box">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <input className="input" type="text" id="email"
                                               name="email"
                                               placeholder={intl.formatMessage({id: "placeholder.email"})}
                                               defaultValue={email}
                                               onChange={event => setEmail(event.target.value)}
                                               autoFocus=""
                                               ref={register({
                                                   required: "This input is requiread.",
                                                   validate:{
                                                       emailCase: value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
                                                   }
                                               })}
                                        />
                                        {renderError()}
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon className="mr-2 has-text-primary is-hidden-mobile"
                                                             icon={faEnvelope} color="primary"/>
                                        </span>
                                    </div>
                                </div>
                                <button id="reset_submit" type="submit" className="button is-block is-fullwidth is-link">
                                    <FontAwesomeIcon className="mr-2" icon={faShare} color="primary"/>
                                    <FormattedMessage id="user.send_resetPassword"/>
                                </button>
                                <div className="columns mt-1">
                                    <div className="column">
                                        <Link className="has-text-primary" to="/login">
                                            <FormattedMessage id="user.return_login"/>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default connect()(SendResetPasswordMail);
