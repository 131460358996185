// ** React Imports
import React from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const RegisteredQuote = () => {
    // ** Hook
    const locale = useSelector(state => state.locale.locale);

    return (
        <div style={{ paddingBottom: "20px", paddingTop: "180px" }}>
            <section className="hero">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title has-text-weight-bold">
                            <FormattedMessage id="quote.register"/>
                        </h1>
                        <div className="has-text-centered">
                            <a
                                className="custom-anchor button is-uppercase mt-5 px-6"
                                href={`https://www.idisc.com/${locale.name}`}
                            >
                                <FormattedMessage id="quote.back_to_home"/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default RegisteredQuote;
