import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteDepartment} from "../../store/reducers/department/departmentActions";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import * as actions from "../../store/Actions";

const DepartmentHOC = (WrappedComponent) => {
    const NewComponent = (props) => {
        const intl = useIntl();
        const history = useHistory();
        const dispatch = useDispatch();

        const departmentHasBeenDeleted = useSelector(state => state.department.departmentHasBeenDeleted);
        const usersOnDepartment = useSelector(state => state.department.usersOnDepartment);

        const deleteDepartmentHandler = (id) => {
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    title: intl.formatMessage({id: "message_title.delete"}),
                    message: intl.formatMessage({id: "message.delete"}),
                    acceptButton: intl.formatMessage({id: "general.delete"}),
                    cancelButton: intl.formatMessage({id: "general.cancel"}),
                    acceptButtonClass: "is-danger",
                    acceptButtonMethod: () => {
                        dispatch(deleteDepartment(id))
                        dispatch({type: 'TOGGLE_MODAL'})
                    },
                }
            })
        }

        useEffect(() => {
            if(departmentHasBeenDeleted){
                history.replace("/departments");
                toast.success(intl.formatMessage({id: "general.element_deleted"}));
                dispatch({type: actions.RESET_DELETED_DEPARTMENT});
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [departmentHasBeenDeleted])

        useEffect(() => {
            if (usersOnDepartment != null) {
                //toast.error(intl.formatMessage({id: "general.element_deleted_error"}));
                dispatch({
                    type: 'TOGGLE_MODAL',
                    payload: {
                        title: intl.formatMessage({id: "department.error.not_delete"}),
                        message: intl.formatMessage({id: "department.placeholderMessageUsers"}, {users: usersOnDepartment.length}),
                        acceptButton: intl.formatMessage({id: "general.accept"}),
                        cancelButton: intl.formatMessage({id: "general.cancel"}),
                        acceptButtonClass: "is-danger",
                        acceptButtonMethod: () => {
                            dispatch({type: 'TOGGLE_MODAL'})
                        },
                    }
                })
                dispatch({type: actions.RESET_DELETED_DEPARTMENT});
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [usersOnDepartment]);

        return (
            <WrappedComponent
                {...props}
                deleteHandler={deleteDepartmentHandler}
            />
        )
    }
    return NewComponent;
};
export default DepartmentHOC;
