import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from "react-hook-form";
import {updateDepartment, getDepartment} from '../../store/reducers/department/departmentActions';
import * as actions from '../../store/Actions';
import {toast} from "react-toastify";
import {Link, useHistory} from 'react-router-dom';
import {getUsers} from "../../store/reducers/user/userActions";
import MultipleSelect from "../../components/Form/MultipleSelect";
import DepartmentHOC from "../../hoc/Department/DepartmentHOC";


let EditDepartment = (props) => {
    const {register, handleSubmit, errors, setValue} = useForm();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const selectedDepartment = useSelector(state => state.department.selectedDepartment);
    const usersFromStore = useSelector(state => state.user.users);
    const departmentHasBeenUpdated = useSelector(state => state.department.departmentHasBeenUpdated)

    const [isSending, setIsSending] = useState(false);
    const [currentDepartment, setCurrentDepartment] = useState({});
    const [selectedUsers, setSelectedUsers] = useState(selectedDepartment?.users ?? []);


    useEffect(() => {
        let id = props.match.params.id;
        dispatch(getDepartment(id));
        if (usersFromStore.length === 0) {
            dispatch(getUsers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (departmentHasBeenUpdated) {
            setIsSending(false);
            toast.success(intl.formatMessage({id: "general.element_updated"}));
            dispatch({type: actions.RESET_UPDATED_DEPARTMENT});
            history.push("/departments")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departmentHasBeenUpdated]);

    useEffect(() => {
        setCurrentDepartment(selectedDepartment);
        setSelectedUsers(selectedDepartment?.users ?? []);
    }, [selectedDepartment]);

    useEffect(() => {
        setValue("users", JSON.stringify(selectedUsers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUsers])

    const onSubmit = (data) => {
        setIsSending(true);
        dispatch(updateDepartment({
            id: props.match.params.id,
            body: data
        }));
    }

    return (
        <>
            <div className="box">
                <div className="columns is-mobile">
                    <nav className="column is-11 breadcrumb has-arrow-separator is-medium" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link href="#" to="/departments" className="title is-4">
                                    <FormattedMessage id="menu.departments" />
                                </Link>
                            </li>
                            <li className="is-active title is-4">
                                <a aria-current="page" className="has-text-primary" href="/#">
                                    {selectedDepartment?.title ?? ""}
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <hr/>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="field">
                        <label className={"label"}>
                            <FormattedMessage id={"department.title"}/> *
                        </label>
                        <div className="control">
                            <input
                                className={"input has-icons-right"}
                                name="title"
                                id="title"
                                value={currentDepartment?.title ?? ""}
                                onChange={ (e) => setCurrentDepartment({...currentDepartment, title: e.target.value})}
                                ref={register({required: true})}/>
                            {errors.title && <p className="help is-danger"><FormattedMessage id="general.field_required"/></p>}
                        </div>
                    </div>

                    <input type="hidden" name="users" ref={register}/>
                    <MultipleSelect
                        label="department.users"
                        subtitle="department.users"
                        name="user"
                        placeholder="placeholder.users"
                        elements={usersFromStore}
                        selectedElements={selectedUsers}
                        setSelectedElements={setSelectedUsers}
                        exposedAttribute="name,lastname"
                        show={true}/>



                    <button className={"button is-link " + (isSending && "is-loading")} disabled={isSending} ><FormattedMessage id="general.save"/>
                    </button>

                </form>

            </div>
        </>
    );
}

export default DepartmentHOC (EditDepartment);
