import React from 'react';
import DataTable from 'react-data-table-component';
import {useIntl} from "react-intl";

const Datatable = (props) => {
    const intl = useIntl();

    return (
        <DataTable
            className={"mxpTable"}
            pagination
            highlightOnHover={true}
            pointerOnHover={true}
            noHeader={true}
            paginationComponentOptions={{
                rowsPerPageText: intl.formatMessage({id: "datatable.rowsPerPageText"}),
                rangeSeparatorText: intl.formatMessage({id: "datatable.rangeSeparatorText"}),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: intl.formatMessage({id: "datatable.selectAllRowsItemText"})
            }}
            noDataComponent={intl.formatMessage({id: "datatable.noDataComponent"})}
            {...props}
        />
    )
}

export default Datatable;
