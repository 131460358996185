import * as actionTypes from '../../Actions';

const initialState = {
    selectedMenu: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CHANGE_MENU:
            switch (true) {
                case new RegExp(`^menu`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: action.payload.to
                    };
                case new RegExp(`^/login`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_inicio_sesion"
                    };
                case new RegExp(`^/users`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_usuarios"
                    };
                case new RegExp(`^/tasks`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_tareas"
                    };
                case new RegExp(`^/glossaries`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_glossaries"
                    };
                case new RegExp(`^/departments`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_departamentos"
                    };
                case new RegExp(`^/invoices`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_facturas"
                    };
                case new RegExp(`^/benefits`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_beneficios"
                    };
                case new RegExp(`^/xpress-methodology`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_metodologia"
                    };
                case new RegExp(`^/premium-service`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_servicio"
                    };
                case new RegExp(`^/terminology`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_terminologia"
                    };
                case new RegExp(`^/rates`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_tarifas"
                    };
                case new RegExp(`^/general/rates`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_rates"
                    };
                case new RegExp(`^/languages`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_languages"
                    };
                case new RegExp(`^/account`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_cuenta"
                    };
                case new RegExp(`^/company`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_company"
                    };
                case new RegExp(`^/customers`).test(action.payload.from):
                    return {
                        ...state,
                        selectedMenu: "menu_clientes"
                    };
                default:
                    return {
                        ...state,
                        selectedMenu: "menu_tareas"
                    };
            }

        default:
            return state;
    }
}

export default reducer;
