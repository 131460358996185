import {axiosInstance} from "../../../hoc/Axios/Axios";
import * as actions from '../../Actions';
import fileDownload from "js-file-download";

export const getTasks = ( showCancelled, config) => {
    return (dispatch, getState) => {
        var url = "/tasks";
        return axiosInstance.get(url,{
            params: {
                showCancelled,
                ...config
            }
        })
            .then(res => {
                if (Array.isArray(res.data.data)) {
                    // it means it's an array with the tasks and the customers
                    dispatch({ type: actions.GET_TASKS, payload: res.data.data[0] })
                    dispatch({ type: actions.SET_SIMPLE_CUSTOMERS, payload: res.data.data[1] })
                } else{
                    //it means it's only the tasks
                    dispatch({ type: actions.GET_TASKS, payload: res.data.data })
                }
            }).catch(() => null)
    }
}

export const getPaymentGateway = (formData) => {
    return (dispatch, getState) => {
        var url = "/tasks/paymentGateway";
        return axiosInstance.post(url, formData)
            .then(res => {
                dispatch({ type: actions.SET_PAYMENT_FORM, payload: res.data.data.form })
            }).catch(() => null)
    }
}

export const updateTaskStatusAsApproved = (id) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsApproved";
        return axiosInstance.post(url)
            .then(res => {
                dispatch({ type: actions.UPDATE_STATUS_SUCCESS });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const updateTaskStatusAsCancelled = (id) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsCancelled";
        return axiosInstance.post(url)
            .then(res => {
                dispatch({ type: actions.SET_CANCELLED_TASK });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const updateTaskStatusAsDraft = (id) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsDraft";
        return axiosInstance.post(url)
            .then(res => {
                dispatch({ type: actions.SET_REACTIVATED_TASK });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const updateTaskStatusAsTemporal = (id) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsTemporal";
        return axiosInstance.post(url)
            .then(res => {
                dispatch({ type: actions.UPDATE_STATUS_SUCCESS });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const updateTaskStatusAsIncourse = (id,formData) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsIncourse";
        return axiosInstance.post(url,formData,{
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                dispatch({ type: actions.UPDATE_STATUS_SUCCESS });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const updateTaskStatusAsDone = (id,formData) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/updateStateAsDone";
        return axiosInstance.post(url,formData,{
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                dispatch({ type: actions.UPDATE_STATUS_SUCCESS });
            }).catch(error => {
                dispatch({ type: actions.UPDATE_STATUS_ERROR });
            })
    }
}

export const uploadTranslatedFile = (id, data) => dispatch => axiosInstance
    .post(`/tasks/${id}/uploadTranslatedFile`, data,{
        headers: { "Content-Type": "multipart/form-data" }
    })
    .then(() => {
        dispatch({ type: actions.UPLOAD_TRANSLATED_FILE_SUCCESS});
    })
    .catch(() => {
        dispatch({ type: actions.UPLOAD_TRANSLATED_FILE_ERROR });
    })

export const removeTranslatedFile = (id,fileID) => {
    return (dispatch, getState) => {
        var url = "/tasks/"+id+"/removeTranslatedFile/"+fileID;
        return axiosInstance.delete(url)
            .then(res => {
                dispatch({ type: actions.REMOVE_TRANSLATED_FILE_SUCCESS});
            }).catch(error => {
                dispatch({ type: actions.REMOVE_TRANSLATED_FILE_ERROR });
            })
    }
}

export const getTaskID = () => {
    return (dispatch, getState) => {
        var url = "tasks/create";
        return axiosInstance.get(url)
            .then(res => {
                dispatch({ type: actions.GET_TASK_ID, payload: res.data.data })
            }).catch(() => null)
    }
}

export const uploadFiles = (id, formData) => {
    return (dispatch, getState) => {
        dispatch({ type: actions.SET_LOADING_COUNT_STATUS, payload: true })
        var url = "tasks/" + id + "/sourceFile";
        return axiosInstance.post(url, formData)
            .then(res => {
                dispatch({ type: actions.GET_SOURCE_FILES_INFORMATION, payload: res.data.data })
                dispatch({ type: actions.SET_LOADING_COUNT_STATUS, payload: false })
            }).catch(() => null)
    }
}

export const getWordCount = (id, formData) => {
    return (dispatch, getState) => {
        dispatch({ type: actions.SET_LOADING_COUNT_STATUS, payload: true })
        var url = "tasks/" + id + "/wordCount";
        return axiosInstance.post(url, formData)
            .then(res => {
                // dispatch({ type: actions.GET_SOURCE_FILES_INFORMATION, payload: res.data })
                dispatch({ type: actions.GET_SOURCE_FILES_INFORMATION, payload: res.data.data })
                dispatch({ type: actions.SET_LOADING_COUNT_STATUS, payload: false })
            }).catch(() => null)
    }
}

/**
 * Not use, cause API ZIP error
 * @param id
 * @returns {function(*, *): Promise<void>}
 */
export const downloadTranslatedFiles = (id) =>{
    return (dispatch, getState) => {
        return axiosInstance.get("task/"+id+"/downloadTranslatedFiles")
            .then(res => {
                let fileName = res.headers["content-disposition"].split("filename=")[1];
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(error => {

            })
    }
}

export const downloadTranslatedFile = (id,idFile,filename) =>{
    return (dispatch, getState) => {
        return axiosInstance.get("task/"+id+"/downloadTranslatedFile/"+idFile, {
            responseType: 'blob',
        })
            .then(res => {
                fileDownload(res.data, filename);
            }).catch(() => null)
    }
}

export const downloadSourceFile = (id,idFile,filename) =>{
    return (dispatch, getState) => {
        return axiosInstance.get("task/"+id+"/downloadSourceFile/"+idFile, {
            responseType: 'blob',
        })
            .then(res => {
                fileDownload(res.data, filename);
            }).catch(() => null)
    }
}


export const getTask = (id) => {
    return (dispatch, getState) => {
        var url = "/task/"+id;
        return axiosInstance.get(url)
            .then(res => {
                dispatch({ type: actions.GET_TASK_SUCESS, payload: res.data })
            }).catch(() => null)
    }
}

export const updateTask = (id, data) => dispatch => axiosInstance.post(`/tasks/${id}/update`, data)
    .then(() => dispatch({ type: actions.UPDATE_TASK_SUCESS}))
    .catch(() => null)

export const deleteTask = (id) => {
    return (dispatch, getState) => {
        var url = "/task/"+id;
        return axiosInstance.delete(url)
            .then(res => {
                dispatch({ type: actions.SET_DELETED_TASK})
            }).catch(() => null)
    }
}

export const verifyFiles = (data) => {
    return dispatch => {
        return axiosInstance.post("/tasks/verifyFiles", data, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                dispatch({ type: actions.TASK_VERIFY_FILES, payload: res.data.data})
            }).catch(() => null)
    }
}

export const saveTaskAsDraft = data => dispatch => axiosInstance
    .post("/tasks/store-as-draft", data, { headers: { "Content-Type": "multipart/form-data" } })
    .then(response => {
        if (response.data.data.form) dispatch({ type: actions.SET_PAYMENT_FORM, payload: response.data.data.form })
        else dispatch({ type: actions.SAVE_TASK_AS_DRAFT, payload: response.data.data })
    })
    .catch(() => null)

export const saveTaskAsApproved = (data) => {
    return dispatch => {
        return axiosInstance.post("/tasks/store-as-approved", data, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                if (res.data.data.form) {
                    dispatch({ type: actions.SET_PAYMENT_FORM, payload: res.data.data.form})
                } else {
                    dispatch({ type: actions.SAVE_TASK_AS_DRAFT, payload: res.data.data})
                }
            }).catch(() => null)
    }
}

export const getBudget = (data) => {
    return dispatch => {
        return axiosInstance.post("/tasks/budget", data, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(res => {
                dispatch({ type: actions.SAVE_TASK_AS_DRAFT, payload: res.data.data})
            }).catch(() => null)
    }
}
export const getBudgetPDF = (data, createTask, name = "Translation_quote.pdf") => dispatch =>
    axiosInstance.post("/task/budget/pdf", data, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob"
    })
        .then(response => {
            fileDownload(response.data, name);
            if (createTask) {
                dispatch({ type: actions.SAVE_TASK_AS_DRAFT})
            } else{
                dispatch({ type: actions.SET_DOWNLOADED_BUDGET})
            }
        })
        .catch(() => null)

export const verifyPayment = (data) => {
    return dispatch => {
        return axiosInstance.post("/tasks/verifyPayment", data)
            .then(res => {
                dispatch({ type: actions.SET_PAYMENT_DONE, payload: res.data.data.paymentDone})
            }).catch(() => null)
    }
}

export const verifyTemporalTaskPayment = (data) => {
    return dispatch => {
        return axiosInstance.post("/task/temporal/verifyPayment", data)
            .then(res => {
                dispatch({ type: actions.SET_PAYMENT_DONE, payload: res.data.data.paymentDone})
            }).catch(() => null)
    }
}

export const payDraftTask = (taskId) => {
    return dispatch => {
        return axiosInstance.post("/tasks/payDraft/" + taskId)
            .then(res => {
                dispatch({ type: actions.SET_PAYMENT_FORM, payload: res.data.data.form})
            }).catch(() => null)
    }
}

export const sendTaskToProductionDeferred = (taskId) => {
    return dispatch => {
        return axiosInstance.post(`/tasks/${taskId}/update/deferred`)
            .then(res => {
                dispatch({ type: actions.SEND_TASK_TO_PRODUCTION})
            }).catch(() => null)
    }
}

export const sendBudget = (id, data) => dispatch => axiosInstance
    .post(`/tasks/${id}/send/budget`, data)
    .then(() => {
        dispatch({ type: actions.SET_BUDGET_STATUS })
    })
    .catch(() => null)

export const getPrices = id => dispatch => axiosInstance
    .get(`tasks/getPrice/${id}`)
    .then(response => {
        dispatch({ type: actions.SET_PRICES, payload: response.data.data });
    })
    .catch(() => null)

export const getCustomer = (userID) => {
    return dispatch => {
        return axiosInstance.get(`user/${userID}/customers`)
            .then(res => {
                dispatch({ type: "SET_TASK_CUSTOMER", payload: res.data.data});
            }).catch(() => null)
    }
}

export const updateAmountToPay = (id, data) => () => axiosInstance.put(`/tasks/${id}/updateAmountToPay`, data)

export const updateDaysToAdd = (id, data) => () => axiosInstance.put(`/tasks/${id}/updateDaysToAdd`, data)

export const updateSourceFilesValidated = (id, data) => () => axiosInstance
    .put(`/tasks/${id}/updateSourceFilesValidated`, data)
