import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl';
import {disableUser, updatePassword} from '../../store/reducers/user/userActions'

const UserHOC = (WrappedComponent) => {
    const NewComponent = (props) => {
        const intl = useIntl();
        const dispatch = useDispatch();
        const selectedUser = useSelector(state => state.user.selectedUser);

        const handleUpdatePassword = (e) => {
            e.preventDefault();
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
            dispatch(updatePassword(selectedUser));
        }

        const deleteUser = () => {
            dispatch({type: 'TOGGLE_MODAL'});
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            });
            dispatch(disableUser(selectedUser.id));
        }

        function showConfirmation(e) {
            e.preventDefault();
            e.stopPropagation();
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    title: intl.formatMessage({id: "message_title.delete"}),
                    message: intl.formatMessage({id: "message.delete"}),
                    acceptButton: intl.formatMessage({id: "general.delete"}),
                    cancelButton: intl.formatMessage({id: "general.cancel"}),
                    acceptButtonClass: "is-danger",
                    acceptButtonMethod: deleteUser
                }
            })
        }

        const showChangePasswordModal = (e, selectedUser) => {
            e.preventDefault();
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "change_password",
                    title: intl.formatMessage({id: "general.change_password"}),
                    acceptButton: intl.formatMessage({id: "general.change"}),
                    acceptButtonMethod: props.handleUpdatePassword,
                    cancelButton: intl.formatMessage({id: "general.cancel"}),
                    extraData: selectedUser
                }
            })
        }

        return (
            <WrappedComponent
                {...props}
                handleUpdatePassword={handleUpdatePassword}
                showConfirmation={showConfirmation}
                showChangePasswordModal={showChangePasswordModal}>
            </WrappedComponent>
        )
    }
    return NewComponent;
}
export default UserHOC;
