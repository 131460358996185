// ** React Imports
import React from 'react';

// ** Third Party Imports
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

// ** Icon Imports
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import { deleteRate } from "../../store/reducers/customers/customersActions";
import * as userTypes from "../../store/reducers/auth/userTypes"

// ** Actions Import
import * as actionTypes  from "../../store/Actions";

// ** Components Imports
import Datatable from "../Datatable/Datatable";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";

const CustomerRatesTable = ({ rates, customer_id }) => {
    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const userType = useSelector(state => state.auth.user ? state.auth.user.type : "invitado");
    const locale = useSelector(state => state.locale.locale);
    const { dateTimeFormat } = useDate();
    const price = usePrice();

    const columns = [
        {
            name: intl.formatMessage({ id: "customer.assignLangSource" }),
            selector: row => row?.source_language?.iso_code && intl.formatMessage({ id: `language.${row?.source_language?.iso_code}` }),
            sortable: true
        },
        {
            name: intl.formatMessage({ id: "customer.assignLangTarget" }),
            selector: row => row?.target_language?.iso_code && intl.formatMessage({ id: `language.${row?.target_language?.iso_code}` }),
            sortable: true
        },
        {
            name: intl.formatMessage({ id: "customer.r1" }),
            selector: row => `${price(row.r1, locale.name, 4)} €`,
            sortable: true,
            right: true
        },
        {
            name: intl.formatMessage({ id: "customer.r2" }),
            selector: row => `${price(row.r2, locale.name)} €`,
            sortable: true,
            right: true
        },
        ...(userType === userTypes.IDISC_ADMIN ? [
            {
                name: intl.formatMessage({ id: "customer.updated_on_date" }),
                selector: row => row.updated_at_format ?? dateTimeFormat(row.updated_at, locale.name),
                sortable: true
            },
            {
                name: intl.formatMessage({ id: "customer.updated_by_user" }),
                selector: row => row.user && `${row.user.name} ${row.user.lastname}`,
                sortable: true
            },
            {
                name: "",
                maxWidth: "100px",
                selector: row => (
                    <button
                        className="button is-small is-danger"
                        title={intl.formatMessage({ id: "general.delete" })}
                        onClick={event => onClickDelete(event, row.id)}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                )
            }
        ] : [])
    ];

    const onClickUpdate = rate => {
        dispatch({
            type: actionTypes.SHOW_MODAL,
            payload: {
                modalType: "editRate",
                title: intl.formatMessage({ id: "customer.editRate" }),
                extraData: { id_rate: rate.id, rate, customer_id }
            }
        })
    };

    const onClickDelete = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: "TOGGLE_MODAL",
            payload: {
                title: intl.formatMessage({ id: "message_title.delete" }),
                message: intl.formatMessage({ id: "message.delete" }),
                acceptButton: intl.formatMessage({ id: "general.delete" }),
                cancelButton: intl.formatMessage({ id: "general.cancel" }),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => dispatch(deleteRate(id, customer_id))
            }
        })
    };

    return (
        <Datatable
            columns={columns}
            data={rates}
            onRowClicked={row => userType === userTypes.IDISC_ADMIN ? onClickUpdate(row) : null}
            pointerOnHover={userType === userTypes.IDISC_ADMIN}
        />
    );
};

export default CustomerRatesTable;
