// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

// ** Store Imports
import { storeUser,validateUsers } from "../../store/reducers/user/userActions";
import { getDepartments } from "../../store/reducers/department/departmentActions";
import * as UserTypes from "../../utils/enums/UserTypes";

// ** Components Imports
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Input from "../../components/Form/Input";
import UserTypeSelect from "../../components/User/UserTypeSelect";
import MultipleSelect from "../../components/Form/MultipleSelect";

function CreateUser(props) {
    // ** Hooks
    const dispatch = useDispatch();
    const { register, errors, handleSubmit, setValue, setError } = useForm();
    const history = useHistory();
    const intl = useIntl();
    const location = useLocation();
    const departmentsFromStore = useSelector(state => state.department.departments);
    const userType = useSelector(state => state.auth.user?.type);
    const hasUserBeenCreated = useSelector(state => state.user.userCreated);
    const validateUser = useSelector(state => state.user.validateUser);
    const error = useSelector(state => state.user.error);
    const locale = useSelector(state => state.locale.locale);
    const locales = useSelector(state => state.locale.locales);

    let defaultUserType;
    if (userType === UserTypes.CLIENT_ADMIN || userType === UserTypes.CLIENT_USER)
        defaultUserType = UserTypes.CLIENT_ADMIN;
    else defaultUserType = UserTypes.IDISC_ADMIN;

    // ** States
    const [selectedUserType, setSelectedUserType] = useState(defaultUserType);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    //Delete selected user from store
    useEffect(() => {
        dispatch({type: "RESET_SELECTED_USER"});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        setValue("departments", JSON.stringify(selectedDepartments));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartments])

    useEffect(() => {
        if (hasUserBeenCreated) {
            dispatch({type: 'TOGGLE_MODAL',});
            toast.success(intl.formatMessage({id: "message.user_created"}));
            dispatch({type: "USER_CREATED", payload: {status: false}});
            if ((props.location.state?.from??"")==="customer"){
                if(props.location.state.customer_id > 0) {
                    history.replace("/customers/" + props.location.state.customer_id + "/edit");
                }
            } else {
                history.replace("/users");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenCreated])

    useEffect(() => {
        if (departmentsFromStore.length === 0) dispatch(getDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if ((props.location.state?.from??"")==="customer"){
            if (validateUser!==null){
                let RawCustomer = getCreateCustomer();
                dispatch({type:"CLEAR_VALID_USER"});
                history.push({pathname: "/customers/create", exact: true, state:{customer:RawCustomer}});
            }
        }

        if (error){
            printErrors(error.message);
            dispatch({type:"CLEAR_ERROR"});
        }
    });

    const printErrors = (errors)=>{
        Object.entries(errors).forEach(([key, value]) => {
            setError(key,{type:"server",message:value[0]});
        });
    }

    const onSubmit = data => {
        data = {...data, lang: locale.name};
        if ((props.location.state?.from ?? "") === "customer") {
            if (props.location.state.customer_id > 0) {
                data.customer_id = props.location.state.customer_id;
            } else if (props.location.state?.customer_raw) {
                dispatch(validateUsers(data));
                return false;
            }
        }
        dispatch({type: 'TOGGLE_MODAL', payload: {modalType: "loading"}});
        dispatch(storeUser(data));
    }

    const getCreateCustomer = () => {
        let RawCustomer = props.location.state.customer_raw;
        let usersEx = [...RawCustomer.users];
        if (validateUser!==null) {
            if (!usersEx.includes(validateUser)){
                usersEx.push(validateUser);
            }
        }
        RawCustomer.users = usersEx;

        return RawCustomer;
    }

    return (
        <div className="box is-desktop">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={intl.formatMessage({id: "user.add_user"})}
                    destinationTitle={
                        (
                            ((props.location.state?.from??"")!=="customer")?
                                intl.formatMessage({id: "menu.users"}):
                                intl.formatMessage({id: "menu.clients"})
                        )
                    }
                    destination={
                        (((props.location.state?.from??"")==="customer")?
                            ((props.location.state.customer_id > 0)?
                                "/customers/" + props.location.state.customer_id + "/edit":
                                "/customers/create"
                            ):
                        "/users")
                    }
                    state={
                        (((props.location.state?.from??"")==="customer")?
                            ((props.location.state.customer_id === 0)?
                                    {customer:getCreateCustomer()}:
                                    {}
                            ):
                            {})
                    }
                />
            </div>

            <hr/>

            <div className="p-2 is-desktop">
                <form key="form_user" onSubmit={
                    handleSubmit(onSubmit)
                }>
                    <div className="columns is-desktop">
                        <div className="column is-half">
                            <Input name="name"
                                   value=""
                                   label="general.name"
                                   type="name"
                                   errors={errors}
                                   register={register}
                                   errorText="general.field_required"/>
                            {errors.name&&errors.name.type==="server"&&(<p className="error">{errors.name.message}</p>)}
                        </div>
                        <div className="column is-half">
                            <Input name="lastname"
                                   value=""
                                   label="user.lastname"
                                   type="lastname"
                                   errors={errors}
                                   register={register}
                                   errorText="general.field_required"/>
                            {errors.lastname&&errors.lastname.type==="server"&&(<p className="error">{errors.lastname.message}</p>)}
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-half">
                            <Input name="email"
                                   value=""
                                   label="general.email"
                                   type="email"
                                   errors={errors}
                                   register={register}
                                   errorText="general.field_required"/>
                            {errors.email&&errors.email.type==="server"&&(<p className="error">{errors.email.message}</p>)}
                        </div>
                        <div className="column is-half">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="general.phone"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        name="phone"
                                        placeholder={intl.formatMessage({id: "placeholder.phone"})}
                                        ref={register()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="columns is-desktop">
                        <div className="column">
                            <UserTypeSelect
                                value={selectedUserType}
                                onChange={ (e) => setSelectedUserType(e.target.value)}
                                name="type"
                                from={props.location.state?.from??""}
                                register={register}
                                errorText="general.field_required"/>
                        </div>
                        <div className="column">
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="language.language"/>
                                </label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select defaultValue={locale.name} name="language" ref={register()}>
                                            {locales.map(locale => (
                                                <option key={locale.name} value={locale.name}>
                                                    {intl.formatMessage({id: `languages.${locale.name}`})}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userType === UserTypes.IDISC_ADMIN && (
                        <div className="columns">
                            <div className="column is-half">
                                <div className="field">
                                    <label className="label">
                                        <FormattedMessage id="customer.bonus"/>
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            max={999999.99}
                                            min={0.01}
                                            name="bonus"
                                            ref={register()}
                                            step={0.01}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {userType === UserTypes.CLIENT_ADMIN && selectedUserType === UserTypes.CLIENT_USER ? (
                        <>
                        <input type="hidden" name="departments" ref={register}/>

                        <MultipleSelect
                            label="department.name"
                            subtitle="user.departments"
                            name="department"
                            placeholder="placeholder.department"
                            elements={departmentsFromStore}
                            selectedElements={selectedDepartments}
                            setSelectedElements={(data) => setSelectedDepartments(data)}
                            exposedAttribute="title"
                            show={true}/>
                        </>
                    ): null}


                    <hr/>
                    <div className="columns">
                        <div className="is-flex column is-3">
                            <button
                                type="submit"
                                className="button is-primary is-flex-grow-5">
                                <FormattedMessage id="general.save"/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateUser;
