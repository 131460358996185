// ** React Imports
import React, { useEffect, useState } from "react";

// ** Third Party Imports
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// ** Store Imports
import { getCurrentUserDepartments } from "../../store/reducers/auth/authActions";
import { changeLanguage } from "../../store/reducers/user/userActions";
import * as userTypes from "../../store/reducers/auth/userTypes";

// ** Actions Imports
import * as actions from "../../store/Actions";

// ** Custom Components
import MultipleSelect from "../../components/Form/MultipleSelect";
import OnlyReadInput from "../../components/Form/OnlyReadInput";

// ** User Types
import * as UserTypes from "../../utils/enums/UserTypes";

function UserAccount() {
    // ** State
    const [loading, setLoading] = useState(false);

    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.auth.user);
    const userDepartments = useSelector(state => state.auth.currentUserDepartments);
    const passwordChangedStatus = useSelector(state => state.user.passwordChangedStatus);
    const hasUserBeenUpdated = useSelector(state => state.user.userUpdated);
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.type : "invitado");
    const locales = useSelector(state => state.locale.locales);

    useEffect(() => {
        dispatch(getCurrentUserDepartments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (passwordChangedStatus === "in_process") {
            dispatch({type: 'TOGGLE_MODAL'});
            dispatch({
                type: 'TOGGLE_MODAL',
                payload: {
                    modalType: "loading",
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordChangedStatus]);

    useEffect(() => {
        if (hasUserBeenUpdated) {
            dispatch({type: 'TOGGLE_MODAL'});
            toast.success(intl.formatMessage({id: "message.user_updated"}));
            dispatch({type: actions.USER_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenUpdated]);

    const handleUpdatePass = event => {
        event.preventDefault();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: "loading",
            }
        })
    }

    const showChangePasswordModal = event => {
        event.preventDefault();
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: "change_password",
                title: intl.formatMessage({id: "general.change_password"}),
                acceptButton: intl.formatMessage({id: "general.change"}),
                acceptButtonMethod: handleUpdatePass,
                cancelButton: intl.formatMessage({id: "general.cancel"}),
                extraData: authUser
            }
        })
    }

    const ShowDepartments = () => {
        if ((userType === userTypes.CLIENT_USER || userType === userTypes.CLIENT_ADMIN) && authUser.type === UserTypes.CLIENT_USER) {
            return (
                <MultipleSelect
                    subtitle="user.departments"
                    selectedElements={userDepartments}
                    exposedAttribute="title"
                    show={false}/>
            )
        }
    }

    const onChange = language => {
        setLoading(true);
        dispatch(changeLanguage(authUser, { language })).finally(() => setLoading(false));
    }

    return (
        <div className="box is-desktop">
            <h4 className="title is-4 has-text-primary"><FormattedMessage id="menu.my_account"/></h4>
            <div className="p-2 is-desktop">
                <div className="columns is-desktop">
                    <div className="column">
                        <OnlyReadInput label="general.name" fieldValue={authUser.name}/>
                    </div>
                    <div className="column">
                        <OnlyReadInput label="user.lastname" fieldValue={authUser.lastname}/>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-half">
                        <OnlyReadInput label="general.email" fieldValue={authUser.email}/>
                    </div>
                    {authUser.phone && (
                        <div className="column is-half">
                            <OnlyReadInput label="general.phone" fieldValue={authUser.phone}/>
                        </div>
                    )}
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="language.language"/>
                            </label>
                            <div className="control">
                                <div className={`select${loading ? " is-loading" : ""}`}>
                                    <select onChange={event => onChange(event.target.value)} value={authUser.language}>
                                        {locales.map(locale => (
                                            <option key={locale.name} value={locale.name}>
                                                {intl.formatMessage({id: `languages.${locale.name}`})}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-half">
                        <button className="button is-light" onClick={showChangePasswordModal}>
                            {intl.formatMessage({id: "general.change_password"})}
                        </button>
                    </div>
                </div>
                <div className="columns is-desktop">
                    <div className="column">
                        <OnlyReadInput
                            label="general.type"
                            fieldValue={intl.formatMessage({id: `type.${authUser.type}`})}
                        />
                    </div>
                </div>
                {ShowDepartments()}
            </div>
        </div>
    );
}

export default UserAccount;
