import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDepartment} from "../../store/reducers/department/departmentActions";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {useHistory} from 'react-router-dom';
import DepartmentHOC from '../../hoc/Department/DepartmentHOC';
import MultipleSelect from '../../components/Form/MultipleSelect';
import OnlyReadInput from "../../components/Form/OnlyReadInput";

const ShowDepartment = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const selectedDepartment = useSelector(state => state.department.selectedDepartment);
    let [currentDepartment, setCurrentDepartment] = useState({});

    useEffect(() => {
        let id = props.match.params.id;
        dispatch(getDepartment(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    useEffect(() => {
        setCurrentDepartment(selectedDepartment);
    }, [selectedDepartment]);

    const handleClick = () => history.push(`/departments/${currentDepartment?.id}/edit`);

    return (<>
        <div className="box">
            <div className="columns is-mobile">
                <nav className="column is-11 breadcrumb has-arrow-separator is-medium" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <Link href="#" to="/departments" className="title is-4">
                                <FormattedMessage id="menu.departments" />
                            </Link>
                        </li>
                        <li className="is-active title is-4">
                            <a aria-current="page" className="has-text-primary" href="/#">
                                {currentDepartment?.title ?? ""}
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <hr/>

            <div>
                <OnlyReadInput label="department.title" fieldValue={currentDepartment?.title}/>

                <MultipleSelect
                    subtitle="department.users"
                    selectedElements={currentDepartment?.users ?? []}
                    exposedAttribute="name,lastname"
                    show={false}
                    handleClick={handleClick}/>
            </div>

        </div>
    </>);
};

export default DepartmentHOC(ShowDepartment);
