import React, {useEffect} from 'react';
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getTemporalTask, payTemporalTask} from '../../store/reducers/guest/guestActions';
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import OnlyReadInput from "../../components/Form/OnlyReadInput";
import parse from "html-react-parser";
import * as Actions from "../../store/Actions";

const GuestTask = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const temporalTask = useSelector(state => state.guest.temporalTask);
    const paymentForm = useSelector(state => state.guest.form);

    let targetLanguageIds = null;
    if (temporalTask != null) {
        if (temporalTask.target_languages.length > 0) {
            targetLanguageIds = temporalTask.target_languages.map(lang => {
                return intl.formatMessage({id: "languages." + lang.target_language.iso_code});
            }).reduce((prev, current) => {
                return prev + ", " + current;
            })
        }
    }

    const paymentHtmlForm = () => {
        if (paymentForm !== null){
            return parse(paymentForm);
        }
    }

    useEffect(() => {
        dispatch(getTemporalTask(props.match.params.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFormattedDate = () => {
        let taskCreationDate = temporalTask?.created_at;
        if (taskCreationDate) {
            let date = new Date(temporalTask?.created_at);
            return date.toLocaleDateString() + " " + date.toLocaleTimeString();
        }
    }

    const getDueDate = () => {
        if (temporalTask?.due_date){
            let date = new Date(temporalTask?.due_date);
            return date.toLocaleDateString() + " " + date.toLocaleTimeString()
        }
        return null;
    }


    /**
     *
     * @param action DRAFT (save task as draft), PAY (save task as draft and Pay), BUDGET (Save task as draft and send budget by mail)
     * @returns {Promise<void>}
     */
    const payTask = async () => {
        dispatch(payTemporalTask(temporalTask.id));

        dispatch({
            type: Actions.SHOW_MODAL,
            payload: {
                modalType: "loading",
            }
        })
    }

    useEffect(() => {
        dispatch({type: Actions.HIDE_MODAL});
        if (paymentForm !== null) {
            let element = document.getElementById("payment_form");
            if (element !== null){
                element.submit();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentForm])

    return (
        <div className="box">
            <div className="columns is-mobile">
                <Breadcrumbs
                    title={intl.formatMessage({id: "task.task_number"}) + ": " + temporalTask?.id}
                    destinationTitle={intl.formatMessage({id: "menu.tasks"})}
                    destination="/tasks"/>
            </div>

            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "general.general_data"})}
            </h1>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput label="task.task_number" fieldValue={temporalTask?.id}/>
                </div>
                <div className="column">
                    <OnlyReadInput label="task.name" fieldValue={temporalTask?.description ?? ""}/>
                </div>
            </div>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput label="general.creation_date"
                                   fieldValue={getFormattedDate()}/>
                </div>
                <div className="column">
                    <OnlyReadInput label="general.due_date"
                                   fieldValue={getDueDate()}/>
                </div>
            </div>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="task.comments"
                        fieldValue={temporalTask?.comments ?? ""}/>
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "language.languages"})}
            </h1>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="task.source_language"
                        fieldValue={temporalTask?.source_language?.iso_code}/>
                </div>
                <div className="column">
                    <OnlyReadInput
                        label="task.target_languages"
                        fieldValue={targetLanguageIds}
                    />
                </div>
            </div>
            <hr/>
            <h1 className="title is-5 has-text-primary">
                {intl.formatMessage({id: "general.payment_data"})}
            </h1>
            <div className="columns is-desktop">
                <div className="column">
                    <OnlyReadInput
                        label="invoice.amount_taxes_not_included"
                        fieldValue={temporalTask?.taxed_price ? "€ " + temporalTask?.taxed_price : ""}/>
                </div>
            </div>
            <div className="block">
                <div className="buttons">
                    <a className={"button is-primary is-link "} href="/#" onClick={() => payTask()}>
                        {intl.formatMessage({id: "task.pay_and_translate"})}
                    </a>
                    {paymentHtmlForm()}
                </div>
            </div>
        </div>
    );
}

export default GuestTask;
